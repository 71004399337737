import styled from 'styled-components';

const Overlay = styled.div`
    animation: fade 0.1s linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    background-color: transparent;
    height: 100%;
    left: 0;
    margin-left: 0 !important;
    margin-top: 0 !important;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    @keyframes fade {
        0% {
            background-color: transparent;
        }
        100% {
            background-color: rgba(43, 43, 43, 0.8);
        }
    }
`;

export default Overlay;
