import React, { useState } from 'react';
import styled from 'styled-components';
import { obscureName } from '../../../utils/grid';
import { cssResetButton } from '../../../theme/cssSnippets';
import { Icon } from '../Icons';

interface PrivateTextProps {
    text: string;
    fullyObscure?: boolean;
}

const StyledButton = styled.button`
    ${cssResetButton};
    align-items: center;
    display: flex;
    overflow: hidden;

    svg {
        flex-shrink: 0;
        margin-right: 0.5rem;
    }
`;

const PrivateText = ({ text, fullyObscure }: PrivateTextProps) => {
    const [show, setShow] = useState(false);

    return (
        <StyledButton onClick={() => setShow(!show)} type="button">
            <Icon icon={show ? 'hide' : 'show'} size="1.5rem" />
            {show ? text : obscureName(text, fullyObscure)}
        </StyledButton>
    );
};

export default PrivateText;
