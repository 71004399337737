import styled from 'styled-components';
import React, { ReactElement } from 'react';
import { shade700 } from '../../../theme/colours';

interface InitialsProps {
    initials: string;
}

const StyledInitials = styled.div`
    line-height: 2rem;
    padding: 0.5rem;
    > span {
        border: 0.01rem solid ${shade700};
        border-radius: 35%;
        padding: 0.3rem;

        text-align: center;
    }
`;

const Initials = ({ initials }: InitialsProps): ReactElement => {
    return (
        <StyledInitials>
            <span>{initials}</span>
        </StyledInitials>
    );
};

export default Initials;
