const letters = 'ABCDEFGHJKLMNPQRSTUVWXYZ'; // not using O and I to avoid confusion

export const calculateChecksum = (input: string): string => {
    const sum = input.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return letters[sum % letters.length];
};

export const validateChecksum = (input: string): boolean => {
    const pinWithoutChecksum = input.slice(0, 7);
    const checksum = input.slice(7, 8);
    return calculateChecksum(pinWithoutChecksum) === checksum;
};

export const validatePin = (pin: string): string => {
    let trimmedPin = pin.replace(/ /g, '').toUpperCase();

    if (trimmedPin.length === 7) {
        trimmedPin = `${trimmedPin.slice(0, 4)} ${trimmedPin.slice(4)}`;
    } else {
        return 'Pin must be 7 characters long';
    }

    const pinRegex = /^[A-Z]{2}\d{2} \d[A-Z]{2}$/;
    if (!pinRegex.test(trimmedPin)) {
        return 'Pin does not match the expected format';
    }

    return validateChecksum(trimmedPin) ? '' : 'Pin is not valid';
};
