import * as t from 'io-ts';
import optionalToUndefined from '../t/optionalToUndefined';

export const Address = t.type({
    town: t.string,
    postcode: t.string,
    houseNumber: optionalToUndefined(t.string),
    houseName: optionalToUndefined(t.string),
    streetName: optionalToUndefined(t.string),
    locality: optionalToUndefined(t.string),
    county: optionalToUndefined(t.string),
    administrativeArea: optionalToUndefined(t.string),
    uprn: optionalToUndefined(t.string),
    displayAddress: optionalToUndefined(t.string),
    classification: optionalToUndefined(t.string),
});

export type Address = t.TypeOf<typeof Address>;
