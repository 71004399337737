import React, { ReactElement, useContext, useState } from 'react';
import Modal from '../../bits/Modal/Modal';
import AssignProxyAddress from './AssignProxyAddress';
import ButtonsWrapper from '../../bits/DetailPage/ButtonsWrapper';
import Button, { LinkButton } from '../../bits/Buttons/Button';
import Form from '../../bits/Form/Form';
import { isEmpty } from 'ramda';
import { editApplicantProxyAddress } from '../../../graphql/applicant';
import GetProxyAddressByUuid from '../../hooks/GetProxyAddressByUuid';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import styled from 'styled-components';
import { destructive } from '../../../theme/colours';

interface AssignProxyAddressModalProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const RemoveButton = styled(LinkButton)`
    color: ${destructive};
`;

const AssignProxyAddressModal = ({ open, setOpen }: AssignProxyAddressModalProps): ReactElement => {
    const { currentApplicant } = useContext(ApplicantsContext);
    const [areYouSure, setAreYouSure] = useState(false);
    const [proxyAddressUuid, setProxyAddressUuid] = useState<string>(currentApplicant?.proxyAddressUuid || '');
    const [updateEditApplicantProxyAddressMutation, { loading, error: updateError }] = editApplicantProxyAddress.hook();
    const assignedProxyAddress = GetProxyAddressByUuid(proxyAddressUuid);

    const updateProxyAddress = async () => {
        if (currentApplicant && (assignedProxyAddress || !proxyAddressUuid)) {
            const updatedApplicant = await updateEditApplicantProxyAddressMutation({
                variables: {
                    councilAreaUuid: assignedProxyAddress ? assignedProxyAddress.councilAreaUuid : '',
                    orgUuid: currentApplicant.orgUuid,
                    applicantUuid: currentApplicant.applicantUuid,
                    proxyAddressUuid,
                },
                refetchQueries: ['listApplicantsByOrg'],
            });

            if (updatedApplicant) {
                setAreYouSure(false);
                setOpen(false);
            }
        }
    };
    const isRemoveProxyAddressSelected = !proxyAddressUuid || isEmpty(proxyAddressUuid);

    return (
        <>
            <Modal open={open} setOpen={setOpen} fullScreen>
                <Form
                    title="Update ProxyAddress"
                    description={`Use the form below to remove or change a ProxyAddress for ${currentApplicant?.name}.`}
                    body={
                        <>
                            <AssignProxyAddress
                                assignedProxyAddressUuid={proxyAddressUuid}
                                setAssignedProxyAddressUuid={setProxyAddressUuid}
                            />
                            <ButtonsWrapper>
                                <Button
                                    buttonStyle="primary"
                                    text="Save"
                                    type="submit"
                                    disabled={proxyAddressUuid === currentApplicant?.proxyAddressUuid}
                                />
                                <Button buttonStyle="secondary" text="Cancel" onClick={() => setOpen(false)} />
                                <RemoveButton
                                    onClick={() => {
                                        setProxyAddressUuid('');
                                        setAreYouSure(true);
                                    }}
                                >
                                    Remove
                                </RemoveButton>
                            </ButtonsWrapper>
                        </>
                    }
                    onSubmit={() =>
                        isRemoveProxyAddressSelected && !!currentApplicant?.proxyAddressUuid
                            ? setAreYouSure(true)
                            : updateProxyAddress()
                    }
                    loading={loading}
                    error={!!updateError}
                ></Form>
            </Modal>
            {areYouSure && (
                <Modal open={areYouSure} setOpen={setAreYouSure}>
                    <Form
                        title="Are you sure?"
                        description="This will remove the ProxyAddress from the applicant."
                        body={
                            <ButtonsWrapper>
                                <Button buttonStyle="primary" text="Remove" type="submit" />
                                <Button buttonStyle="secondary" text="Cancel" onClick={() => setAreYouSure(false)} />
                            </ButtonsWrapper>
                        }
                        onSubmit={updateProxyAddress}
                        loading={loading}
                        error={!!updateError}
                    />
                </Modal>
            )}
        </>
    );
};

export default AssignProxyAddressModal;
