import format from 'date-fns/format';
import { Applicant, ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { omit } from 'ramda';

interface FormatOrgApplicantProps {
    applicant: Applicant;
    staffUsers: Record<string, StaffUser>;
}

export const getStaffInitials = (name: string) => {
    const initials = name.split(' ').map((word) => {
        const maybeHyphenated = word.split('-');
        if (maybeHyphenated.length > 1) {
            const hyphenatedInitials = maybeHyphenated.map((hyphenatedWord) => {
                return hyphenatedWord[0];
            });
            return hyphenatedInitials.join('-').toUpperCase();
        } else return word[0];
    });
    return initials.join('').toUpperCase();
};

export const formatOrgApplicant = ({ applicant, staffUsers }: FormatOrgApplicantProps): ApplicantWithStaffDetails => {
    const { firstName, lastName, email } = applicant;
    const staffMember = staffUsers[applicant.staffMember];
    if (!firstName || !lastName) return { ...omit(['staffMember'], applicant), staffMember, name: email };
    else return { ...applicant, staffMember, name: `${firstName} ${lastName}` };
};

export const formatDetailsDate = (date: string | undefined) => {
    if (date) {
        const newDate = new Date(date);
        return format(newDate, 'd MMM y');
    } else return '';
};

export const getApplicantUuid = (path: string): string => {
    return path.split('/')[2];
};
