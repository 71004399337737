import React, { ReactElement, useState } from 'react';
import Button from './Button';
import styled from 'styled-components';
import { body2 } from '../../../theme/cssSnippets';
import { red400 } from '../../../theme/colours';
import Modal from '../Modal/Modal';
import { Title2 } from '../Titles/titles';
import ButtonsWrapper from '../DetailPage/ButtonsWrapper';
import { foxtrot } from '../../../theme/sizing';

const StyledButton = styled(Button)`
    ${body2}
    color: ${red400};
`;

const ModalInner = styled.div`
    > * + * {
        margin-top: ${foxtrot};
    }
`;

interface DeleteLinkButtonProps {
    label?: string;
    onClick: () => void;
    itemName?: string;
    loading?: boolean;
}

const DeleteLinkButton = ({ label = 'Delete', onClick, itemName, loading }: DeleteLinkButtonProps): ReactElement => {
    const [showAreYouSure, setShowAreYouSure] = useState(false);

    return (
        <>
            <StyledButton buttonStyle="link" onClick={() => setShowAreYouSure(true)} text={label} disabled={loading} />
            {showAreYouSure && (
                <Modal open={showAreYouSure} setOpen={setShowAreYouSure} clickOutsideToClose={true}>
                    <ModalInner>
                        <Title2>Are you sure?</Title2>
                        <p>Are you sure you want to delete{itemName ? ` ${itemName}.` : '.'}</p>
                        <ButtonsWrapper>
                            <Button
                                buttonStyle="primary"
                                text="Yes"
                                onClick={() => {
                                    onClick();
                                    setShowAreYouSure(false);
                                }}
                            />
                            <Button buttonStyle="secondary" text="No" onClick={() => setShowAreYouSure(false)} />
                        </ButtonsWrapper>
                    </ModalInner>
                </Modal>
            )}
        </>
    );
};

export default DeleteLinkButton;
