import { createContext } from 'react';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { Org } from 'proxyaddress-common/types/organisation';
import { ProxyAddress } from 'proxyaddress-common/types';
import { InputOptions } from '../bits/FormFields/SelectInput';

export interface StaffUserState {
    staffUser: StaffUser;
    setStaffUser: (staffUser: StaffUser) => void;
    staffUsers: Record<string, StaffUser>;
    staffOptions: InputOptions[];
    organisation: Org;
    proxyAddresses: Record<string, ProxyAddress>;
    currentProxyAddress: ProxyAddress | undefined;
    setCurrentProxyAddress: (proxyAddress: ProxyAddress | undefined) => void;
    redirectToProxyAddress: (proxyAddress: ProxyAddress, proxyAddressUuid: string) => void;
}

export const StaffUserContext = createContext<StaffUserState>({} as StaffUserState);
