import { Applicant } from 'proxyaddress-common/types';
import { Title2 } from '../../bits/Titles/titles';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import React from 'react';
import styled from 'styled-components';
import Button from '../../bits/Buttons/Button';
import ApplicantProxyAddressDetails from '../ApplicantDetails/ApplicantProxyAddressDetails';
import { echo } from '../../../theme/sizing';
import GetProxyAddressByUuid from '../../hooks/GetProxyAddressByUuid';

interface ApprovalCompleteProps {
    setOpen: (arg: boolean) => void;
    applicant: Applicant;
}

const Inner = styled.div`
    margin-bottom: ${echo};

    > * + * {
        margin-top: ${echo};
    }
`;

const ApprovalComplete = ({ setOpen, applicant }: ApprovalCompleteProps) => {
    const proxyAddress = GetProxyAddressByUuid(applicant?.proxyAddressUuid);

    return (
        <>
            <Inner>
                <Title2>Application approved</Title2>
                <BodyCopy>
                    <p>
                        {applicant?.firstName} {applicant?.lastName}'s application has been approved.
                    </p>
                </BodyCopy>
            </Inner>

            <ApplicantProxyAddressDetails address={proxyAddress} pin={applicant?.pin} hideTitle />

            <Button
                buttonStyle="primary"
                text="Close"
                onClick={() => {
                    setOpen(false);
                }}
            />
        </>
    );
};

export default ApprovalComplete;
