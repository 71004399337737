import { isRight } from 'fp-ts/lib/Either';
import * as c from 'proxyaddress-common/constants';
import { CouncilAreaInput, CouncilAreaOutput, Region, RegionOutput } from 'proxyaddress-common/types/councilAreas';
import { find, isEmpty, propEq } from 'ramda';
import * as constants from './constants';

export const checkCsvInput = (maybeCouncilAreas: any[]): CouncilAreaInput[] => {
    return maybeCouncilAreas.map((councilArea) => {
        const maybeCouncilArea = CouncilAreaInput.decode(councilArea);
        if (isRight(maybeCouncilArea)) {
            return maybeCouncilArea.right;
        }
        throw new Error('Council area was not the right shape');
    });
};

interface CheckMissingCouncilAreasProps {
    councilAreasInput: CouncilAreaInput[];
    returnedCouncilAreas: CouncilAreaOutput[];
}

export const checkMissingCouncilAreas = ({
    councilAreasInput,
    returnedCouncilAreas,
}: CheckMissingCouncilAreasProps): CouncilAreaInput[] => {
    const missingCouncilAreas: CouncilAreaInput[] = [];

    councilAreasInput.forEach((councilArea) => {
        const isPresent = find(propEq('name', councilArea.name))(returnedCouncilAreas);
        if (!isPresent) missingCouncilAreas.push(councilArea);
    });

    return missingCouncilAreas;
};

export const formatRegion = (region: Region): RegionOutput => {
    switch (region) {
        case c.ENGLAND_AND_WALES:
            return c.ENGLAND_AND_WALES_OUTPUT;
        case c.SCOTLAND:
            return c.SCOTLAND_OUTPUT;
        case c.NORTHERN_IRELAND:
            return c.NORTHERN_IRELAND_OUTPUT;
        default:
            throw new Error('Incorrect region type');
    }
};

export const formatRegionOutput = (region: RegionOutput): Region => {
    switch (region) {
        case c.ENGLAND_AND_WALES_OUTPUT:
            return c.ENGLAND_AND_WALES;
        case c.SCOTLAND_OUTPUT:
            return c.SCOTLAND;
        case c.NORTHERN_IRELAND_OUTPUT:
            return c.NORTHERN_IRELAND;
        default:
            throw new Error('Incorrect region type');
    }
};

export const councilAreaStatusOptions = [
    {
        label: 'Active',
        value: c.ACTIVE,
    },
    {
        label: 'Inactive',
        value: c.INACTIVE,
    },
];

export const councilAreaRegionOptions = [
    {
        label: 'England and Wales',
        value: c.ENGLAND_AND_WALES,
    },
    {
        label: 'Scotland',
        value: c.SCOTLAND,
    },
    {
        label: 'Northern Ireland',
        value: c.NORTHERN_IRELAND,
    },
];

export interface EditCouncilAreaDetailsErrors {
    nameError: string;
    regionError: string;
    statusError: string;
}

export const editCouncilAreaDetailsInitialErrors: EditCouncilAreaDetailsErrors = {
    nameError: '',
    regionError: '',
    statusError: '',
};

export const validateEditCouncilAreaDetails = (details: CouncilAreaOutput): EditCouncilAreaDetailsErrors => {
    return {
        nameError: isEmpty(details.name) ? constants.ERROR_NO_NAME : '',
        regionError: isEmpty(details.region) ? constants.ERROR_NO_REGION : '',
        statusError: isEmpty(details.councilStatus) ? constants.ERROR_STATUS : '',
    };
};
