import { isEmpty } from 'ramda';
import React, { ReactElement, ReactNode } from 'react';
import Caption from '../Caption/Caption';
import FormField from '../FormFields/FormField';
import FormInputField from '../FormFields/FormInputField';
import Label from '../Form/Label';

export interface TextInputProps {
    id: string;
    helperText?: string;
    label: string;
    placeholder?: string;
    type?: string;
    error?: string;
    value?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: () => void;
    defaultValue?: string;
    disabled?: boolean;
    hideLabel?: boolean;
    autofocus?: boolean;
    preText?: string;
    button?: ReactNode;
}

const TextInput = ({
    id,
    helperText,
    label,
    placeholder,
    type = 'text',
    error,
    value,
    onChange,
    onBlur,
    defaultValue,
    disabled,
    hideLabel,
    autofocus,
    preText,
    button,
    ...props
}: TextInputProps): ReactElement => (
    <FormField error={error} {...props}>
        <Label htmlFor={id} hidden={hideLabel}>
            {label}
        </Label>
        <FormInputField
            id={id}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            defaultValue={defaultValue}
            disabled={disabled}
            autofocus={autofocus}
            preText={preText}
            button={button}
            hasError={(error && !isEmpty(error)) || undefined}
            onBlur={onBlur}
        />
        {helperText && <Caption>{helperText}</Caption>}
    </FormField>
);

export default TextInput;
