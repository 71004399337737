import { PERSONAL_DETAILS } from 'proxyaddress-common/constants';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { green400, red400 } from '../../../theme/colours';
import { charlie, echo } from '../../../theme/sizing';
import { isApplicationUndecided } from '../../../utils/applicant';
import { GridWrapper as Wrapper } from '../../../utils/grid';
import DropdownButton from '../../bits/Buttons/DropdownButton';
import DropdownOptionButton from '../../bits/Buttons/DropdownOptionButton';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import ApproveApplicationModal from '../ApplicationDecision/ApproveApplicationModal';
import RejectApplicationModal from '../ApplicationDecision/RejectApplicationModal';
import ApplicantDetailsTabPages from './ApplicantDetailsTabPages';
import ApplicantHeading from './ApplicantHeading';
import AssignStaff from './AssignStaff';

const LayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: 30% auto;
    grid-gap: ${charlie};
    margin-bottom: ${echo};
    margin-top: ${echo};
`;

const ApplicantDetails = (): ReactElement => {
    const { currentApplicant, setCurrentApplicant, setCurrentApplicantTab } = useContext(ApplicantsContext);
    const [approveModalOpen, setApproveModalOpen] = useState(false);
    const [rejectModalOpen, setRejectModalOpen] = useState(false);
    const isComplete = currentApplicant?.application?.progress?.complete;
    const allowReview = currentApplicant && isComplete && isApplicationUndecided(currentApplicant.applicationStatus);

    useEffect(() => {
        return () => {
            setCurrentApplicant(undefined);
            setCurrentApplicantTab(PERSONAL_DETAILS);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reviewApplicationOptions = (
        <ul>
            <li>
                <DropdownOptionButton
                    icon="tick"
                    color={green400}
                    text="Approve application"
                    onClick={() => setApproveModalOpen(true)}
                />
            </li>
            <li>
                <DropdownOptionButton
                    icon="cross"
                    color={red400}
                    text="Reject application"
                    onClick={() => setRejectModalOpen(true)}
                />
            </li>
        </ul>
    );

    return (
        <Wrapper>
            {currentApplicant && (
                <>
                    {approveModalOpen && (
                        <ApproveApplicationModal
                            open={approveModalOpen}
                            setOpen={setApproveModalOpen}
                            applicant={currentApplicant}
                        />
                    )}
                    {rejectModalOpen && (
                        <RejectApplicationModal
                            open={rejectModalOpen}
                            setOpen={setRejectModalOpen}
                            applicant={currentApplicant}
                        />
                    )}
                    <ApplicantHeading name={currentApplicant.name} status={currentApplicant.applicationStatus} />
                    <LayoutWrapper>
                        <div>
                            {allowReview && (
                                <DropdownButton text="Review application" options={reviewApplicationOptions} />
                            )}
                            <AssignStaff />
                        </div>
                        <ApplicantDetailsTabPages />
                    </LayoutWrapper>
                </>
            )}
        </Wrapper>
    );
};

export default ApplicantDetails;
