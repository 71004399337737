import React, { ReactElement } from 'react';
import styled from 'styled-components';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import { Title2 } from '../bits/Titles/titles';
import { green400 } from '../../theme/colours';
import { charlie, echo, golf } from '../../theme/sizing';
import { body2 } from '../../theme/cssSnippets';
import { map } from 'ramda';
import { Icon, IconOptions } from '../bits/Icons';

export interface ConfirmationProps {
    title?: string;
    buttonTitle: string;
    leftIcon?: IconOptions;
    rightIcon?: IconOptions;
    text: string[];
    buttonAction: () => void;
}

const Container = styled.div`
    padding: 0 0 3.313rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const ConfirmationContainer = styled.div`
    padding: 0 0 3.313rem;
    justify-content: center;
    align-items: left;
    > * + * {
        margin-top: ${echo};
    }
`;

const StyledConfirmationBox = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border: solid 0.5rem ${green400};
    margin-bottom: ${golf};
    ${body2}
    svg {
        position: absolute;
        padding-top: 0.3rem;
        height: 1.5rem;
        width: 1.5rem;
    }
    h2 {
        display: inline;
        padding-left: 1.6rem;
    }
    p {
        margin-bottom: ${charlie};
    }
`;

const StyledTitleWrapper = styled.div`
    position: relative;
    margin-bottom: ${charlie};
`;

const Confirmation = ({
    title,
    buttonTitle,
    leftIcon,
    rightIcon,
    buttonAction,
    text,
    ...props
}: ConfirmationProps): ReactElement => {
    return (
        <Container>
            <ConfirmationContainer>
                <StyledConfirmationBox>
                    <StyledTitleWrapper>
                        <Icon icon="tick" fill={green400} />
                        <Title2>{title}</Title2>
                    </StyledTitleWrapper>
                    {map(
                        (line) => (
                            <BodyCopy key={line} textAlign="left">
                                {line}
                            </BodyCopy>
                        ),
                        text,
                    )}
                </StyledConfirmationBox>
                <Button
                    buttonStyle="primary"
                    leftIcon={leftIcon}
                    rightIcon={rightIcon}
                    text={buttonTitle}
                    type="button"
                    onClick={buttonAction}
                />
            </ConfirmationContainer>
        </Container>
    );
};

export default Confirmation;
