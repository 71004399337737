import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { IdStage } from '../../../utils/application';
import Intro from '../../bits/Intro/Intro';
import { IdStagesProps } from '../ApplicationIdVerification';
import Yoti from './Yoti';
import Modal from '../../bits/Modal/Modal';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';
import Button from '../../bits/Buttons/Button';
import { Title3 } from '../../bits/Titles/titles';

const IdPhoto = ({ setIdStage }: IdStagesProps): ReactElement => {
    const [checkComplete, setCheckComplete] = useState(false);
    const [checkError, setCheckError] = useState(false);

    const updateOnCompletedCheck = useCallback((completed: boolean) => {
        setCheckComplete(completed);
    }, []);

    useEffect(() => {
        if (checkComplete) {
            setIdStage(IdStage.ID_RESULT);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkComplete]);

    return (
        <>
            <Intro
                text={[]}
                title="Photo ID verification"
                onCancel={() => setIdStage(IdStage.ID_INTRO)}
                cancelTitle="Back to details"
                body={<Yoti onComplete={updateOnCompletedCheck} setCheckError={setCheckError} />}
            />
            {checkError && (
                <Modal open={checkError} setOpen={setCheckError} clickOutsideToClose={false}>
                    <Title3>Sorry</Title3>
                    <ErrorMessage>Something went wrong, please try again</ErrorMessage>
                    <Button
                        buttonStyle="secondary"
                        onClick={() => {
                            setCheckError(false);
                            setIdStage(IdStage.ID_INTRO);
                        }}
                        text="Retry"
                    />
                </Modal>
            )}
        </>
    );
};

export default IdPhoto;
