import { includes } from 'ramda';
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../patterns/Header';
import AuthLanding from './AuthLanding';
import ForgotPassword from './ForgotPassword';
import RequireNewPassword from './RequireNewPassword';
import PageWrapper from '../bits/Layout/PageWrapper';
import { PageLoading } from '../bits/Loading';

const getCustomComponent = (authState: string) => {
    switch (authState) {
        case 'loading':
            return PageLoading;
        case 'signIn':
            return AuthLanding;
        case 'forgotPassword':
            return ForgotPassword;
        case 'requireNewPassword':
            return RequireNewPassword;
        default:
            throw new Error(`Unknown authState: ${authState}`);
    }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
const CustomAmplify = (props: any): ReactElement => {
    const { pathname } = useLocation();
    const { authState, children } = props;
    const [isApplicant, setIsApplicant] = useState(false);

    useEffect(() => {
        if (includes('application', pathname)) {
            setIsApplicant(true);
        } else {
            setIsApplicant(false);
        }
    }, [pathname]);

    if (authState === 'signedIn') {
        return children;
    } else {
        const CustomComponent = getCustomComponent(authState);
        return (
            <>
                <Header negative />
                <PageWrapper>
                    <CustomComponent isApplicant={isApplicant} {...props} />
                </PageWrapper>
            </>
        );
    }
};

export default CustomAmplify;
