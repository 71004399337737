import React from 'react';
import Button from '../Buttons/Button';
import { Title2 } from '../Titles/titles';
import Notification from '../Notification/Notification';
import { destructive } from '../../../theme/colours';
import PageWrapper from '../Layout/PageWrapper';

interface ErrorPageProps {
    title?: string;
    message?: string;
    buttonLabel?: string;
    buttonAction?: () => void;
}

const ErrorPage = ({
    title = 'Something went wrong!',
    message = 'An error ocurred please refresh the page. If the problem continues please contact support',
    buttonLabel,
    buttonAction,
}: ErrorPageProps) => {
    return (
        <PageWrapper>
            <Notification borderColor={destructive}>
                <Title2>{title}</Title2>
                <p>{message}</p>
            </Notification>
            {buttonAction && (
                <Button buttonStyle="primary" onClick={buttonAction} text={buttonLabel} leftIcon="backArrow" />
            )}
        </PageWrapper>
    );
};

export default ErrorPage;
