import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { title2 } from '../../theme/cssSnippets';
import StatusBadge from '../bits/Grid/StatusBadge';

interface DashboardButtonSearchProps {
    body?: ReactElement;
    title?: string;
    navButton?: ReactElement;
    status?: string;
}

const Wrapper = styled.div`
    margin-bottom: 1.4rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    div {
        display: inline-flex;
        gap: 0.5rem;
    }
    button {
        line-height: normal;
        margin: 0;
    }
    button > svg {
        margin-top: 0.3rem;
    }
    h1 {
        align-items: center;
        display: inline-flex;
        gap: 0.5rem;
        ${title2}
    }
`;

const DashboardButtonSearch = ({
    body,
    navButton,
    title = 'Dashboard',
    status,
}: DashboardButtonSearchProps): ReactElement => (
    <Wrapper>
        <div>
            {navButton}
            <h1>
                {title} {status && <StatusBadge text={status} />}
            </h1>
        </div>
        <div>{body}</div>
    </Wrapper>
);

export default DashboardButtonSearch;
