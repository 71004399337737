import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { Address, ApplicantAddress, ProxyAddress } from 'proxyaddress-common/types';
import { prop, sortBy } from 'ramda';

export const addressInitialState = {
    houseName: '',
    houseNumber: '',
    streetName: '',
    town: '',
    county: '',
    country: 'GBR',
    locality: '',
    postcode: '',
    displayAddress: '',
    administrativeArea: '',
    uprn: '',
    classification: '',
};

export const addressInfoInitialState = {
    addressType: '',
    dateMovedIn: '',
    dateMovedOut: '',
};

export interface AddressInfo {
    addressType: string;
    dateMovedIn: string;
    dateMovedOut: string;
}

export const getDisplayAddress = (address: Address | ProxyAddress) => {
    const { houseName, houseNumber, streetName, town, county, locality, postcode } = address;

    //  If all fields of an address are empty, the display address is an empty string
    return `${houseName && houseName + ', '}${houseNumber && houseNumber + ' '}${streetName && streetName + ', '}${
        locality && locality + ','
    }${town && locality ? ' ' + town + ', ' : town && town + ', '}${county && county + ', '}${postcode}`;
};

export const formatApplicantAddress = (addressInfo: AddressInfo, address: Address): ApplicantAddress => {
    const displayAddress = getDisplayAddress(address);
    const addressToValidate = { ...address, ...addressInfo, displayAddress };

    const maybeAddress = ApplicantAddress.decode(addressToValidate);
    if (!isRight(maybeAddress)) {
        console.log(PathReporter.report(maybeAddress).join('\n\n'));
        throw new Error('Applicant address is wrong shape');
    }
    return maybeAddress.right;
};

export const sortAddressByDate = (addresses: ApplicantAddress[]): ApplicantAddress[] => {
    return sortBy(prop('dateMovedIn'))(addresses);
};

export interface AddressErrors {
    townError: string;
    postcodeError: string;
    stateError?: string;
}
