import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';

interface ClickOutsideContainerProps {
    children: ReactNode;
    open: boolean;
    setOpen: (arg: boolean) => void;
}

const ClickOutsideContainer = ({ children, open, setOpen, ...props }: ClickOutsideContainerProps): ReactElement => {
    const node = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleClickOutside = (e: MouseEvent) => {
        if (node && node.current && !node.current.contains(e.target as Node)) {
            setOpen(false);
        }
    };

    return (
        <div ref={node} {...props}>
            {children}
        </div>
    );
};

export default ClickOutsideContainer;
