import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PageWrapper from '../bits/Layout/PageWrapper';
import DetailPageTitle from '../bits/DetailPage/DetailPageTitle';
import CheckForm from '../patterns/Check/CheckForm';
import CheckResults from '../patterns/Check/CheckResults';
import TwoColumnLayout from '../bits/Layout/TwoColumns';
import FullLogo from '../patterns/FullLogo';
import { ApplicantByPin } from 'proxyaddress-common/types';
import { CheckResult, ERROR } from '../../utils/constants';
import { red600 } from '../../theme/colours';

interface SplitColoursProps {
    showResults: boolean;
    result?: CheckResult;
}

interface ActiveProps {
    active: boolean;
    result?: CheckResult;
}

const solidBg = 'rgb(40,54,100)';
const transparentBg = 'rgba(128,188,231, 0.3)';
const errorBg = red600;
const successBg = '#0c6f35';

const StyledPageWrapper = styled(PageWrapper)`
    padding: 0;
`;

const cssShowResults = css<{ result?: CheckResult }>`
    &:before {
        background-color: ${transparentBg};
    }
    &:after {
        background-color: ${(props) => (props.result === ERROR ? errorBg : successBg)};
    }
`;

const SplitColours = styled.div<SplitColoursProps>`
    position: relative;

    &:before,
    &:after {
        content: '';
        position: fixed;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: -1;
        transition: background-color 0.1s ease-in-out;
    }

    &:before {
        left: 0;
        background-color: ${solidBg};
    }

    &:after {
        right: 0;
        background-color: ${transparentBg};
    }

    ${(props) => (props.showResults ? cssShowResults : '')}

    @media screen and (max-width: 750px) {
        display: none;
    }
`;

const StyledColumns = styled(TwoColumnLayout)`
    align-items: center;
    gap: 0;

    @media screen and (min-width: 751px) {
        height: calc(100vh);
    }

    @media screen and (max-width: 750px) {
        flex-direction: column;
    }

    > div {
        border: 0;
    }
`;

const ActiveColumn = css<ActiveProps>`
    *:not(button),
    label {
        color: white;
    }

    input {
        border-color: white;
    }

    @media screen and (max-width: 750px) {
        background-color: ${(props) => (!props.result ? solidBg : props.result === ERROR ? errorBg : successBg)};
    }
`;

const InactiveColumn = css`
    @media screen and (max-width: 750px) {
        background-color: ${transparentBg};
    }
`;

const Column = styled.div<ActiveProps>`
    padding: 1rem;

    > * + * {
        margin-top: 1.5rem;
    }

    @media screen and (max-width: 750px) {
        padding-bottom: 5vh;
        padding-top: 5vh;
        width: calc(100% - 2rem);
    }

    ${(props) => (props.active ? ActiveColumn : InactiveColumn)}
`;

const CheckPage = () => {
    const [showResults, setShowResults] = useState(false);
    const [result, setResult] = useState<CheckResult | undefined>();
    const [applicant, setApplicant] = useState<ApplicantByPin | undefined>();
    const [dateOfBirth, setDateOfBirth] = useState<string>('');

    return (
        <>
            <StyledPageWrapper>
                <StyledColumns>
                    <Column active={!showResults}>
                        <FullLogo theme={showResults ? 'primary' : 'negative'} />
                        <DetailPageTitle title="Authorised Check" />
                        <CheckForm
                            showResults={showResults}
                            setShowResults={setShowResults}
                            applicant={applicant}
                            setApplicant={setApplicant}
                            setDateOfBirth={setDateOfBirth}
                        />
                    </Column>
                    <Column active={showResults} result={result}>
                        {showResults && (
                            <CheckResults
                                setShowResults={setShowResults}
                                applicant={applicant}
                                setApplicant={setApplicant}
                                result={result}
                                setResult={setResult}
                                dateOfBirth={dateOfBirth}
                            />
                        )}
                    </Column>
                </StyledColumns>
            </StyledPageWrapper>
            <SplitColours showResults={showResults} result={result} />
        </>
    );
};

export default CheckPage;
