import { defaultProgress, detailsStage, idStage } from 'proxyaddress-common/types';
import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useEffect, useState } from 'react';
import { updateApplicant, UpdateApplicantParams } from '../../graphql/applicant';
import {
    DetailsErrors,
    DetailsInitialErrors,
    getDayMonthYear,
    titles,
    validateDetailsInput,
    validateDate,
} from '../../utils/application';
import { ERROR_DOB_TYPE } from '../../utils/constants';
import {
    getApplicantAddressUpdateFields,
    getApplicantUpdateFields,
    getApplicationUpdateFields,
} from '../../utils/users';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import Button from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import Form from '../bits/Form/Form';
import Label from '../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../bits/FormFields/Dates';
import FormField from '../bits/FormFields/FormField';
import SelectInput from '../bits/FormFields/SelectInput';
import TextInput from '../bits/FormFields/TextInput';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import ApplicantAddressForm from './ApplicantAddressForm';
import styled from 'styled-components';
import { shade200 } from '../../theme/colours';
import { echo } from '../../theme/sizing';
import ErrorMessage from '../bits/ErrorMessage/ErrorMessage';

const MultiFormWrapper = styled.div`
    > * + * {
        border-top: solid 1px ${shade200};
        margin-top: ${echo};
        padding-top: ${echo};
    }
`;

const ApplicationDetails = (): ReactElement => {
    const { applicant, updateApplicationStage } = useContext(ApplicantContext);
    const [errors, setErrors] = useState<DetailsErrors>(DetailsInitialErrors);
    const [title, setTitle] = useState(applicant.title || '');
    const [firstName, setFirstName] = useState(applicant.firstName || '');
    const [middleNames, setMiddleNames] = useState(applicant.middleNames || '');
    const [lastName, setLastName] = useState(applicant.lastName || '');
    const [day, setDay] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState(applicant.dateOfBirth || '');
    const [phoneNumber, setPhoneNumber] = useState(applicant.phoneNumber);
    const [correspondenceEmail, setCorrespondenceEmail] = useState(applicant.email);
    const [updateApplicantMutation, { loading, error }] = updateApplicant.hook();
    const [shouldSaveForm, setShouldSaveForm] = useState(false);

    useEffect(() => {
        if (day && month && year) {
            const validatedDob = validateDate(day, month, year);
            if (validatedDob === applicant.dateOfBirth) {
                return;
            }
            if (validatedDob) {
                setErrors({ ...errors, dateTypeError: '' });
                setDateOfBirth(validatedDob);
                setShouldSaveForm(true);
            } else {
                setErrors({ ...errors, dateTypeError: ERROR_DOB_TYPE });
                setDateOfBirth('');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day, month, year]);

    useEffect(() => {
        const startingDate = getDayMonthYear(applicant.dateOfBirth);
        setDay(startingDate.day);
        setMonth(startingDate.month);
        setYear(startingDate.year);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (shouldSaveForm) {
            saveForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldSaveForm]);

    const saveForm = async (complete = false) => {
        if (applicant.application) {
            const variables: UpdateApplicantParams = {
                applicant: {
                    ...getApplicantUpdateFields(applicant),
                    application: {
                        ...getApplicationUpdateFields(applicant.application),
                        progress: complete ? idStage : detailsStage,
                    },
                    addressHistory: [
                        ...(applicant.addressHistory
                            ? applicant.addressHistory.map((a) => getApplicantAddressUpdateFields(a))
                            : []),
                    ],
                    title,
                    firstName,
                    lastName,
                    middleNames,
                    dateOfBirth,
                    phoneNumber,
                    correspondenceEmail,
                },
            };

            const validationResult = validateDetailsInput(variables);

            if (values(validationResult).every(isEmpty)) {
                updateApplicantMutation({ variables });
            }

            setErrors(validationResult);
        }
    };

    const saveAndUpdateStage = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        const validationResult = validateDetailsInput({
            applicant: { ...applicant, title, firstName, lastName, dateOfBirth },
        });

        if (values(validationResult).every(isEmpty)) {
            saveForm(true);
        } else {
            setErrors(validationResult);
        }
    };

    const contactsBody = (
        <>
            <TextInput
                label="Correspondence Email"
                id="email"
                value={correspondenceEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCorrespondenceEmail(e.target.value)}
                onBlur={() => saveForm()}
                error={errors.emailError}
            />
            <TextInput
                label="Mobile number (optional)"
                id="mobileNumber"
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
                onBlur={() => saveForm()}
                helperText="Including this enables us to get in contact quicker"
            />
            <BodyCopy>
                <p>
                    We will use these details to complete an identity report to help verify your information by
                    cross-checking with independent data sources such as the Electoral Roll, Home Office and DVLA
                    databases.
                </p>
                <p>This is required to process your ProxyAddress application.</p>
                <p>
                    This uses the services of TransUnion, a credit reference agency but will not affect your credit
                    rating and is not a credit search.
                </p>
            </BodyCopy>
            <br />
            <Button buttonStyle="primary" type="submit" text="Continue" rightIcon="rightArrow" />

            {!values(errors).every(isEmpty) && (
                <ErrorMessage>
                    There was an error with your submission, please check the form and try again.
                </ErrorMessage>
            )}
        </>
    );

    const formBody = (
        <>
            <SelectInput
                options={titles}
                label="Title (optional)"
                id="title"
                placeholder=""
                defaultValue={title}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTitle(e.target.value)}
                onBlur={() => saveForm()}
            />
            <TextInput
                label="First name"
                id="firstName"
                value={firstName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
                onBlur={() => saveForm()}
                error={errors.firstNameError}
            />
            <TextInput
                label="Middle name(s) (optional)"
                id="middleNames"
                value={middleNames}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMiddleNames(e.target.value)}
                onBlur={() => saveForm()}
            />
            <TextInput
                label="Last name"
                id="lastName"
                value={lastName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                onBlur={() => saveForm()}
                error={errors.lastNameError}
            />
            <FormField error={errors.dateOfBirthError || errors.dateTypeError}>
                <Label>Date of birth</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="Day"
                        type="text"
                        value={day}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDay(e.target.value)}
                        preText="Day"
                        hasError={(errors && !isEmpty(errors)) || undefined}
                        size="small"
                    />
                    <DateInputField
                        id="Month"
                        type="text"
                        value={month}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMonth(e.target.value)}
                        preText="Month"
                        hasError={(errors && !isEmpty(errors)) || undefined}
                        size="small"
                    />
                    <DateInputField
                        id="Year"
                        type="text"
                        value={year}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setYear(e.target.value)}
                        preText="Year"
                        hasError={(errors && !isEmpty(errors)) || undefined}
                    />
                </DateInputsWrapper>
                <Caption>For example, 25 11 1965</Caption>
            </FormField>
        </>
    );

    return (
        <MultiFormWrapper>
            <Form
                title="Your personal details"
                description={
                    <p>
                        Complete this information as it is shown on official documents such as a passport or driver’s
                        licence.
                    </p>
                }
                body={formBody}
                onCancel={() => updateApplicationStage(defaultProgress)}
                onSubmit={(e) => saveAndUpdateStage(e)}
                cancelTitle="Back to start page"
                loading={false}
                error={false}
            />
            <ApplicantAddressForm />
            <Form
                title="Contact details"
                body={contactsBody}
                onSubmit={(e) => saveAndUpdateStage(e)}
                loading={loading}
                error={!!error}
            />
        </MultiFormWrapper>
    );
};

export default ApplicationDetails;
