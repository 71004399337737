import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Caption from '../Caption/Caption';
import { shade600, shade700 } from '../../../theme/colours';
import { Icon, IconOptions } from '../Icons';

interface DetailWrapperProps {
    detail: string | undefined | ReactElement;
    caption: string;
    iconBelow?: {
        icon: IconOptions;
        size?: string;
        fill?: string;
    };
    inline?: boolean;
}

interface StyledDetailWrapperProps {
    inline?: boolean;
}

export const StyledDetailWrapper = styled.div<StyledDetailWrapperProps>`
    align-items: center;
    display: ${(props) => (props.inline ? 'flex' : 'block')};
    gap: 0.3rem;

    div {
        font-size: 0.8rem;
        color: ${shade700};
    }
`;

const Detail = ({ detail, caption, iconBelow, inline = false }: DetailWrapperProps): ReactElement => {
    return (
        <StyledDetailWrapper inline={inline}>
            <Caption color={shade600}>{caption}</Caption>
            <div>{detail ? detail : '-'}</div>
            {iconBelow && <Icon icon={iconBelow.icon} size={iconBelow.size} fill={iconBelow.fill} />}
        </StyledDetailWrapper>
    );
};

export default Detail;
