import React from 'react';
import { YotiBreakdownResponse } from 'proxyaddress-common/types';
import { getRecommendationColor, getRecommendationIcon } from '../../../utils/yoti';
import styled from 'styled-components';
import { shade200 } from '../../../theme/colours';
import { YotiCheckIcon } from './YotiCheck';
import { formatScreamingSnakeCase } from '../../../utils/helpers';

interface YotiBreakdownProps {
    breakdown: YotiBreakdownResponse;
}

const Breakdown = styled.div`
    border-bottom: solid 1px ${shade200};
    margin-bottom: 0.25rem;
    padding-bottom: 0.25rem;
`;

const BreakdownItem = styled.p`
    align-items: center;

    display: flex;
    font-size: 0.8rem;

    svg {
        height: 1rem;
    }
`;

const BreakdownDetail = styled.ul`
    columns: 2;
    font-size: 0.8rem;
    list-style: circle;
    list-style-position: inside;
    margin-left: 32px;
    margin-top: 0.25rem;
`;

const YotiBreakdown = ({ breakdown }: YotiBreakdownProps) => {
    return (
        <Breakdown>
            <BreakdownItem>
                {breakdown.result && (
                    <YotiCheckIcon
                        icon={getRecommendationIcon(breakdown.result)}
                        color={getRecommendationColor(breakdown.result)}
                    />
                )}
                {formatScreamingSnakeCase(breakdown.subCheck || '')}
            </BreakdownItem>
            {breakdown.details && breakdown.details.length > 0 && (
                <BreakdownDetail>
                    {breakdown.details.map((detail, index) => (
                        <li key={`${detail.name}_${index}`}>
                            {formatScreamingSnakeCase(detail.name)}: {formatScreamingSnakeCase(detail.value)}
                        </li>
                    ))}
                </BreakdownDetail>
            )}
        </Breakdown>
    );
};

export default YotiBreakdown;
