import * as t from 'io-ts';
import { createContext } from 'react';

export const Config = t.type({
    graphQlEndpoint: t.string,
    identityPoolId: t.string,
    userPoolClientId: t.string,
    userPoolId: t.string,
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Config = t.TypeOf<typeof Config>;

const defaultConfig: Config = {
    graphQlEndpoint: 'Warning: Missing graphQlEndpoint config',
    identityPoolId: 'Warning: Missing identityPoolId config',
    userPoolClientId: 'Warning: Missing userPoolClientId config',
    userPoolId: 'Warning: Missing userPoolId config',
};
export const ConfigContext = createContext<Config>(defaultConfig);
