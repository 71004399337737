import React, { ReactElement } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { golf } from '../../theme/sizing';
import Button from '../bits/Buttons/Button';
import { Icon } from '../bits/Icons';
import { Title2 } from '../bits/Titles/titles';
import Header from '../patterns/Header';
import PageWrapper from '../bits/Layout/PageWrapper';
import { ROUTE_SIGN_IN } from '../../utils/routes';

const Wrapper = styled(PageWrapper)`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${golf};
    justify-content: center;

    h2 {
        text-align: center;
    }
`;

const Logo = styled(Icon)`
    height: auto;
    max-width: 100%;
`;

const LandingPage = (): ReactElement => {
    const history = useHistory();
    return (
        <>
            <Header negative />
            <Wrapper>
                <Title2>Welcome to ProxyAddress</Title2>
                <a href={ROUTE_SIGN_IN}>
                    <Logo icon="logoPrimary" size="30rem" />
                </a>
                <Button
                    buttonStyle="primary"
                    text="Sign in"
                    rightIcon="rightArrow"
                    onClick={() => history.push(ROUTE_SIGN_IN)}
                />
            </Wrapper>
        </>
    );
};

export default LandingPage;
