import { GetQuickFilterTextParams, ValueGetterParams } from '@ag-grid-community/all-modules';
import { CouncilAreaOutput, Org } from 'proxyaddress-common/types';

export const defaultOptions = {
    sortable: true,
    flex: 1,
    filter: true,
    floatingFilter: true,
    autoHeight: true,
    resizable: true,
    minWidth: 120,
    getQuickFilterText: () => '',
};

const quickFilter = {
    getQuickFilterText: (params: GetQuickFilterTextParams) => {
        return params.value;
    },
};

const statusValueGetter = ({ data }: ValueGetterParams) => {
    return data.applicationStatus;
};

const staffValueGetter = ({ data }: ValueGetterParams) => {
    return data.staffMember?.name;
};

export const organisationColumns = (councilAreas?: Record<string, CouncilAreaOutput>) => [
    {
        headerName: 'Organisation',
        field: 'name',
        cellRenderer: 'orgLinkRenderer',
        sort: 'asc',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
    },
    {
        headerName: 'Council area',
        field: 'councilArea',
        cellRenderer: 'councilAreaRenderer',
        valueGetter: (params: any) =>
            councilAreas ? councilAreas[params.data.councilAreaUuids[0]]?.name : params.data.councilAreaUuids[0],
    },
    {
        headerName: 'Main contact',
        field: 'subscriptionEmail',
        cellRenderer: 'mainContactRenderer',
    },
];

const applicantsBaseColumns = (organisations?: Record<string, Org>) => [
    {
        headerName: 'Organisation',
        field: 'orgUuid',
        colId: 'organisation',
        cellRenderer: 'orgLinkRenderer',
        hide: !organisations,
        valueGetter: (params: any) => (organisations ? organisations[params.data.orgUuid].name : params.data.orgUuid),
    },
    {
        headerName: 'PIN',
        field: 'pin',
        cellRenderer: 'pinRenderer',
        ...quickFilter,
    },
    {
        headerName: 'Last updated',
        field: 'modifiedAt',
        cellRenderer: 'lastUpdatedRenderer',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
        valueGetter: statusValueGetter,
    },
    {
        headerName: 'Staff',
        field: 'staffMember',
        cellRenderer: 'staffRenderer',
        valueGetter: staffValueGetter,
    },
];

export const applicantsObscuredColumns = (organisations?: Record<string, Org>) => [
    {
        headerName: 'Name',
        field: 'name',
        colId: 'obscureNames',
        cellRenderer: 'obscureNamesRenderer',
        ...quickFilter,
    },
    ...applicantsBaseColumns(organisations),
];

export const applicantsVisibleColumns = (organisations?: Record<string, Org>) => [
    {
        headerName: 'Name',
        field: 'name',
        cellRenderer: 'applicantLinkRenderer',
        ...quickFilter,
    },
    ...applicantsBaseColumns(organisations),
];

export const allStaffUsersAdminColumns = [
    {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
        ...quickFilter,
    },
    {
        headerName: 'Organisation',
        field: 'organisation',
        cellRenderer: 'orgLinkRenderer',
    },
    {
        headerName: 'Status',
        field: 'isActive',
        cellRenderer: 'isActiveRenderer',
    },
    {
        headerName: 'Email',
        field: 'email',
        ...quickFilter,
    },
    {
        headerName: 'Created at',
        field: 'createdAt',
        cellRenderer: 'createdAtRenderer',
    },
];

export const staffUsersColumns = [
    {
        headerName: 'Name',
        field: 'name',
        sort: 'asc',
    },
    { headerName: 'Email', field: 'email' },
    { headerName: 'Status', field: 'status', cellRenderer: 'statusRenderer' },
    {
        headerName: 'Created at',
        field: 'createdAt',
        cellRenderer: 'createdAtRenderer',
    },
];

export const councilAreasColumns = [
    {
        headerName: 'Name',
        field: 'name',
        cellRenderer: 'councilAreaLinkRenderer',
    },
    {
        headerName: 'Region',
        field: 'region',
    },
    {
        headerName: 'Status',
        field: 'councilStatus',
        cellRenderer: 'councilStatusRenderer',
    },
];

export const checksColumns = [
    {
        headerName: 'Check',
        field: 'check',
    },
    { headerName: 'Date begun', field: 'startedAt', cellRenderer: 'dateBegunRenderer' },
    {
        headerName: 'Date issued',
        field: 'completedAt',
        cellRenderer: 'dateIssuedRenderer',
    },
    {
        headerName: 'Status',
        field: 'status',
        cellRenderer: 'statusRenderer',
    },
];

export const proxyAddressesColumns = (councilAreas?: Record<string, CouncilAreaOutput>) => [
    {
        headerName: 'Address',
        cellRenderer: 'displayAddressRenderer',
        valueGetter: (params: any) => params.data.displayAddress,
    },
    {
        headerName: 'Expires',
        field: 'expiryDate',
        cellRenderer: 'expiryDateRenderer',
    },
    {
        headerName: 'Status',
        field: 'isActive',
        cellRenderer: 'statusRenderer',
    },
    {
        headerName: 'Council area',
        field: 'councilAreaUuid',
        cellRenderer: 'councilAreaRenderer',
        valueGetter: (params: any) =>
            councilAreas ? councilAreas[params.data.councilAreaUuid].name : params.data.councilAreaUuid,
    },
    {
        headerName: 'Source',
        field: 'source',
        cellRenderer: 'sourceRenderer',
    },
    {
        headerName: 'Activated',
        field: 'activationDate',
        cellRenderer: 'activationDateRenderer',
    },
];

export const getProxyAddressColumns = (isAdmin: boolean, councilAreas?: Record<string, CouncilAreaOutput>) => {
    return isAdmin
        ? proxyAddressesColumns(councilAreas)
        : proxyAddressesColumns(councilAreas).filter((column) => column.headerName !== 'Council area');
};
