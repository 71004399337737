import gql from 'graphql-tag';
import wrapQuery from './wrapQuery';
import { Org, OrgType, Status } from 'proxyaddress-common/types/organisation';
import wrapMutation from './wrapMutation';

interface ListOrganisationData {
    listOrganisations: Org[];
}

export const OrganisationType = `{
    orgUuid
    name
    councilAreaUuids
    createdAt
    createdBy
    status
    orgType
    applicationCredits
    serviceCredits
    subscriptionEmail
    subscriptionExpiry
    modifiedAt
    partners
}`;

export const listOrganisationQuery = gql`
    query listOrganisations {
        listOrganisations ${OrganisationType}
    }
`;

export const listOrganisations = wrapQuery<void, ListOrganisationData>(listOrganisationQuery);

export interface IOrganisationInput {
    name: string;
    councilAreaUuids: string[];
    createdBy: string;
    status: Status;
    orgType: OrgType;
    subscriptionEmail: string;
    partners: string[];
}

export interface CreateOrgParams {
    organisation: IOrganisationInput;
}

interface CreateOrganisationData {
    createOrganisation: Org;
}

export const OrganisationInputType = `{
    name
    councilAreaUuids
    createdBy
    status
    orgType
    subscriptionEmail
    partners
}`;

const createMutation = gql`
    mutation createOrganisation($organisation: NewOrganisationInput!) {
        createOrganisation(organisation: $organisation) ${OrganisationType}
    }
`;

export const createOrganisation = wrapMutation<CreateOrgParams, CreateOrganisationData>(createMutation);

interface EditOrganisationParams {
    organisation: Org;
}

interface EditOrganisationData {
    editOrganisation: Org;
}

const editMutation = gql`
    mutation editOrganisation($organisation: OrganisationInput!) {
        editOrganisation(organisation: $organisation) ${OrganisationType}
    }
`;

export const editOrganisation = wrapMutation<EditOrganisationParams, EditOrganisationData>(editMutation);

export interface GetOrganisationParams {
    orgUuid: string;
}

export interface GetOrganisationData {
    getOrganisation: Org;
}

export const getOrganisationQuery = gql`
    query getOrganisation($orgUuid: String!) {
        getOrganisation(orgUuid: $orgUuid) ${OrganisationType}
    }
`;

export const getOrganisation = wrapQuery<GetOrganisationParams, GetOrganisationData>(getOrganisationQuery);
