import * as t from 'io-ts';
import * as c from '../constants';
import { omit } from 'ramda';
import optionalToUndefined from '../t/optionalToUndefined';

export const StaffUser = t.type({
    staffUuid: t.string,
    orgUuid: t.string,
    name: t.string,
    createdAt: t.string,
    createdBy: t.string,
    isAdmin: t.boolean,
    isActive: t.boolean,
    email: t.string,
});

export type StaffUser = t.TypeOf<typeof StaffUser>;

export const CreateStaffUserArgs = t.type(omit(['createdAt', 'staffUuid'], StaffUser.props));

export type CreateStaffUserArgs = t.TypeOf<typeof CreateStaffUserArgs>;

export const ApplicantTab = t.union([t.literal(c.PERSONAL_DETAILS), t.literal(c.CHECKS), t.literal(c.ACTIVITY)]);

export type ApplicantTab = t.TypeOf<typeof ApplicantTab>;

export const CheckDetails = t.type({
    check: t.string,
    startedAt: optionalToUndefined(t.string),
    completedAt: optionalToUndefined(t.string),
    status: optionalToUndefined(t.string),
});

export type CheckDetails = t.TypeOf<typeof CheckDetails>;
