import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { ACTION_REQUIRED, APPLICATION_STARTED, INTRO, PENDING, STARTED } from 'proxyaddress-common/constants';
import {
    Applicant,
    ApplicationStages,
    defaultProgress,
    Progress,
    ApplicationStatus,
    EditApplicationArgs,
    ActivityLogInput,
} from 'proxyaddress-common/types';
import { equals } from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';

import { getApplicant } from '../../graphql/applicant';
import { updateApplication } from '../../graphql/application';
import { checkHasNotStartedApplication } from '../../utils/activityLog';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import { getCurrentStage } from '../../utils/application';
import { getApplicationUpdateFields, getCurrentUserUuids, removeTypeFields } from '../../utils/users';
import { PageLoading } from '../bits/Loading';
import AuthContext from '../WithAuth/AuthContext';
import { ApplicantContext } from './applicantContext';

export const WithApplicant: FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
    const { currentAuthenticatedUser: user } = useContext(AuthContext);
    if (!user) {
        throw new Error('User not permitted');
    }
    const { orgUuid, userUuid: applicantUuid } = getCurrentUserUuids(user);
    const [applicant, setApplicant] = useState<Applicant>();
    const [hasCurrentApplication, setHasCurrentApplication] = useState(false);
    const [currentStage, setCurrentStage] = useState<ApplicationStages>(
        getCurrentStage(applicant?.application?.progress || defaultProgress),
    );

    const { data: GetApplicantData } = getApplicant.hook({ orgUuid, applicantUuid });
    const [updateApplicationMutation] = updateApplication.hook();

    const updateApplicationStage = useCallback(
        async (stage: Progress, status?: ApplicationStatus) => {
            if (applicant?.application) {
                const activityLogEntry: ActivityLogInput = {
                    applicantUuid,
                    orgUuid,
                    logType: APPLICATION_STARTED,
                    createdBy: applicant.staffMember,
                    details: {
                        ...defaultActivityLogDetails,
                        applicationUuid: applicant.application.applicationUuid,
                    },
                };

                const application = {
                    ...getApplicationUpdateFields(applicant.application),
                    progress: stage,
                    ...(status ? { applicationStatus: status } : {}),
                };

                // Checks applicant has not already started the application once but then navigated backwards through the application process
                // Avoids duplicating APPLICATION_STARTED activity log entries
                const variables: EditApplicationArgs = {
                    application,
                    activityLogEntry:
                        status === STARTED && checkHasNotStartedApplication(applicant) ? activityLogEntry : undefined,
                    sendRetryEmail: false,
                };

                await updateApplicationMutation({
                    variables,
                    refetchQueries: ['getApplicant'],
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [applicant, updateApplicationMutation],
    );

    useEffect(() => {
        if (GetApplicantData?.getApplicant) {
            const formattedApplicant = removeTypeFields(GetApplicantData?.getApplicant);
            const maybeApplicant = Applicant.decode(formattedApplicant);
            if (!isRight(maybeApplicant)) {
                console.log(PathReporter.report(maybeApplicant).join('\n \n'));
                throw new Error('Applicant was not the right shape');
            }
            setApplicant(maybeApplicant.right);
        } else {
            setApplicant(undefined);
        }
    }, [GetApplicantData]);

    useEffect(() => {
        if (applicant) {
            if (
                equals(applicant.applicationStatus, PENDING) ||
                equals(applicant.applicationStatus, STARTED) ||
                equals(applicant.applicationStatus, ACTION_REQUIRED)
            )
                setHasCurrentApplication(true);
        } else {
            setHasCurrentApplication(false);
        }
        if (applicant?.application?.progress) {
            const stage = getCurrentStage(applicant?.application?.progress);
            setCurrentStage(stage);
        } else {
            setCurrentStage(INTRO);
        }
    }, [applicant]);

    if (!applicant) {
        return <PageLoading />;
    }

    return (
        <ApplicantContext.Provider
            value={{
                applicant,
                setApplicant,
                hasCurrentApplication,
                currentStage,
                updateApplicationStage,
            }}
        >
            {children}
        </ApplicantContext.Provider>
    );
};
