import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TertiaryButton } from '../Buttons/Button';
import { bodyBackground, shade100, shade500 } from '../../../theme/colours';
import { bravo, echo } from '../../../theme/sizing';

interface ContentTabsProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    tabs: string[];
    children: ReactNode;
}

interface TabProps {
    isActive: boolean;
}

const Wrapper = styled.div``;

const StyledTabs = styled.div`
    display: flex;
    gap: ${bravo};
`;

const StyledTab = styled(TertiaryButton)<TabProps>`
    background: ${(props) => (props.isActive ? bodyBackground : shade100)};
    border: 1px solid ${(props) => (props.isActive ? shade500 : shade100)};
    border-bottom: 1px solid ${(props) => (props.isActive ? shade100 : 'none')};
    height: 2.58rem;
    position: relative;
    z-index: ${(props) => (props.isActive ? '1' : '0')};

    &:hover {
        border-bottom: 0.1rem solid ${bodyBackground};
    }
`;

const StyledContent = styled.div`
    border: 1px solid ${shade500};
    position: relative;
    background: ${bodyBackground};
    margin-bottom: ${echo};
    margin-top: -1px;
    padding: 1rem;
`;

export const ContentTabHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

const ContentTabs = ({ tabs, activeTab, setActiveTab, children }: ContentTabsProps) => (
    <Wrapper>
        <StyledTabs>
            {tabs.map((tab) => (
                <StyledTab key={tab} onClick={() => setActiveTab(tab)} isActive={tab === activeTab}>
                    {tab}
                </StyledTab>
            ))}
        </StyledTabs>
        <StyledContent>{children}</StyledContent>
    </Wrapper>
);

export default ContentTabs;
