import styled from 'styled-components';
import { echo } from '../../../theme/sizing';

const MidColumn = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 74ch;

    > * + * {
        margin-top: ${echo};
    }
`;

export default MidColumn;
