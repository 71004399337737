import React from 'react';
import styled, { css } from 'styled-components';
import BodyCopy from '../BodyCopy/BodyCopy';
import { cssResetButton } from '../../../theme/cssSnippets';
import FormField from './FormField';

interface CheckboxContainerProps {
    disabled: boolean;
}

const CheckboxContainer = styled.div<CheckboxContainerProps>`
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const cssSelectedStyle = css`
    background-color: black;
    background-image: url('data:image/svg+xml;base64,CjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiNmZmZmZmYiIHZpZXdCb3g9IjAgMCAzMiAzMiI+Cgo8cGF0aCBkPSJNMjcgNGwtMTUgMTUtNy03LTUgNSAxMiAxMiAyMC0yMHoiPjwvcGF0aD4KPC9zdmc+Cg==');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 60%;
`;

const InputContainer = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 12px;

    input:checked {
        ${cssSelectedStyle};
    }
`;

interface StyledInputProps {
    disabled: boolean;
}

const cssFocus = css`
    &:active,
    &:focus-within {
        border-color: rgb(0, 95, 204);
        border-radius: 4px;
    }
`;

const StyledInput = styled.input<StyledInputProps>`
    ${cssResetButton};
    ${(props) => !props.disabled && cssFocus}

    background-color: white;
    border: 2px solid black;
    border-radius: 0px;
    height: 40px;
    margin-left: 0;
    width: 40px;

    &:disabled {
        background-color: grey;
        cursor: not-allowed;
    }
`;

export const Label = styled.label`
    color: black;
    flex-basis: 0;
    flex-grow: 1;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 0px;
`;

const HelpText = styled(BodyCopy)`
    font-weight: normal;
    margin-left: 60px;
    margin-top: -6px;
`;

interface CheckBoxProps {
    id: string;
    label: string;
    name?: string;
    helpText?: string;
    disabled?: boolean;
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked?: boolean;
}

const CheckboxField = ({
    id,
    label,
    name,
    helpText,
    disabled = false,
    value,
    onChange,
    checked,
    ...props
}: CheckBoxProps) => {
    return (
        <FormField>
            <CheckboxContainer disabled={disabled}>
                <InputContainer {...props}>
                    <StyledInput
                        checked={checked}
                        type="checkbox"
                        id={id}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        name={name}
                    ></StyledInput>
                    <Label htmlFor={id}>{label}</Label>
                </InputContainer>
                {helpText && <HelpText color="subheading">{helpText}</HelpText>}
            </CheckboxContainer>
        </FormField>
    );
};

export default CheckboxField;
