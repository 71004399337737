import { ACTIVE, INACTIVE } from 'proxyaddress-common/constants';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { constructive, destructive, shade700 } from '../../theme/colours';
import { formatProxyAddressSource, getProxyAddressDetailsTitle } from '../../utils/proxyAddress';
import { formatDetailsDate } from '../../utils/staffDashboard';
import Button from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import Detail from '../bits/Details/Detail';
import StatusBadge from '../bits/Grid/StatusBadge';
import { IconOptions } from '../bits/Icons';
import { AdminStateContext } from '../WithAdminState/adminState';
import EditProxyAddressDetails from './EditProxyAddressDetails';
import DetailPageTitle from '../bits/DetailPage/DetailPageTitle';
import DetailsWrapper from '../bits/DetailPage/DetailsWrapper';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import AuthContext from '../WithAuth/AuthContext';
import { ROUTE_ADDRESSES } from '../../utils/routes';
import { OrgDetailTabs } from '../templates/OrganisationDetails';

const EditButtonWrapper = styled.div`
    display: block;
    margin: 1rem auto;
`;

const ProxyAddressDetails = (): ReactElement => {
    const history = useHistory();
    const { proxyAddressUuid }: { proxyAddressUuid: string } = useParams();
    const { isAdmin } = useContext(AuthContext);
    const {
        currentProxyAddress: adminCurrentProxyAddress,
        councilAreas,
        proxyAddresses: adminProxyAddresses,
        setCurrentProxyAddress: adminSetCurrentProxyAddress,
    } = useContext(AdminStateContext);
    const {
        currentProxyAddress: staffCurrentProxyAddress,
        setCurrentProxyAddress: staffSetCurrentProxyAddress,
        proxyAddresses: staffProxyAddresses,
    } = useContext(StaffUserContext);
    const [editProxyAddressDetails, setEditProxyAddressDetails] = useState(false);
    const currentProxyAddress = isAdmin ? adminCurrentProxyAddress : staffCurrentProxyAddress;
    const setCurrentProxyAddress = isAdmin ? adminSetCurrentProxyAddress : staffSetCurrentProxyAddress;
    const proxyAddresses = isAdmin ? adminProxyAddresses : staffProxyAddresses;

    useEffect(() => {
        // sets proxy address on refresh
        if (!currentProxyAddress) setCurrentProxyAddress(proxyAddresses[proxyAddressUuid]);

        return () => {
            setCurrentProxyAddress(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const redirectToProxyAddressAdmin = () => {
        setCurrentProxyAddress(undefined);
        if (isAdmin) {
            const { state } = history.location.state as { state: { referrer?: string } };
            if (state.referrer) {
                history.push(`${state.referrer}?tab=${OrgDetailTabs.addresses}`);
            } else {
                history.goBack();
            }
        } else {
            history.push(ROUTE_ADDRESSES);
        }
    };

    return (
        <>
            {currentProxyAddress && (
                <>
                    <DetailPageTitle
                        title={getProxyAddressDetailsTitle(currentProxyAddress)}
                        onBackButtonClick={redirectToProxyAddressAdmin}
                        backButtonLabel="Back to addresses"
                    />
                    <div>
                        {!editProxyAddressDetails && (
                            <div>
                                <DetailsWrapper>
                                    <div>
                                        <Caption>Status</Caption>
                                        <StatusBadge text={currentProxyAddress?.isActive ? ACTIVE : INACTIVE} />
                                    </div>
                                    <Detail caption="Address" detail={currentProxyAddress.displayAddress} />
                                    {isAdmin && (
                                        <Detail
                                            caption="Council area"
                                            detail={councilAreas[currentProxyAddress?.councilAreaUuid].name}
                                        />
                                    )}
                                    <Detail
                                        caption="Source"
                                        detail={formatProxyAddressSource(currentProxyAddress?.source)}
                                        iconBelow={{
                                            fill: shade700,
                                            icon: currentProxyAddress?.source.toLowerCase() as IconOptions,
                                        }}
                                    />
                                    <Detail
                                        caption="Logical status code"
                                        detail={currentProxyAddress?.logicalStatusCode}
                                    />
                                    <Detail
                                        caption="Postal address code"
                                        detail={currentProxyAddress?.postalAddressCode}
                                    />
                                    <Detail caption="Classification" detail={currentProxyAddress?.classificationCode} />
                                    <Detail caption="BLPU state code" detail={currentProxyAddress?.blpuStateCode} />
                                    <Detail caption="Last update date" detail={currentProxyAddress?.lastUpdateDate} />
                                    <Detail
                                        caption="Local custodian code"
                                        detail={currentProxyAddress?.localCustodianCode}
                                    />
                                    <Detail
                                        caption="Is multiple residence"
                                        detail={' '}
                                        iconBelow={{
                                            fill: currentProxyAddress?.isMultipleResidence ? constructive : destructive,
                                            icon: currentProxyAddress?.isMultipleResidence ? 'tick' : 'cross',
                                        }}
                                    />
                                    <Detail
                                        caption="Is not yet built"
                                        detail={' '}
                                        iconBelow={{
                                            fill: currentProxyAddress?.isNotYetBuilt ? constructive : destructive,
                                            icon: currentProxyAddress?.isNotYetBuilt ? 'tick' : 'cross',
                                        }}
                                    />
                                    {currentProxyAddress?.activationDate && (
                                        <Detail
                                            caption="Activated"
                                            detail={formatDetailsDate(currentProxyAddress.activationDate)}
                                        />
                                    )}
                                    <Detail
                                        caption="Expires"
                                        detail={formatDetailsDate(currentProxyAddress?.expiryDate)}
                                    />
                                </DetailsWrapper>
                                {isAdmin && (
                                    <EditButtonWrapper>
                                        <Button
                                            buttonStyle="primary"
                                            text="Edit ProxyAddress"
                                            onClick={() => setEditProxyAddressDetails(true)}
                                        />
                                    </EditButtonWrapper>
                                )}
                            </div>
                        )}

                        {editProxyAddressDetails && (
                            <EditProxyAddressDetails
                                proxyAddress={currentProxyAddress}
                                setEditing={setEditProxyAddressDetails}
                            />
                        )}
                    </div>
                </>
            )}
        </>
    );
};

export default ProxyAddressDetails;
