import { ActivityLogEntryType, LogType } from 'proxyaddress-common/types';
import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';
import { shade200, shade600 } from '../../../theme/colours';
import { charlie } from '../../../theme/sizing';
import { label2 } from '../../../theme/cssSnippets';
import Caption from '../../bits/Caption/Caption';
import { getLogIcon, getLogIconSize, getLogTitle } from '../../../utils/activityLog';
import { formatDetailsDate } from '../../../utils/staffDashboard';
import { APPLICATION_STARTED, STAFF_ASSIGNED, UPLOAD } from 'proxyaddress-common/constants';
import { Icon } from '../../bits/Icons';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import AuthContext from '../../WithAuth/AuthContext';
import { LinkButton } from '../../bits/Buttons/Button';
import { downloadFile, getApplicantUploadPath } from '../../../utils/helpers';
import { useApolloClient } from '@apollo/client';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import { ERROR } from '../../../utils/constants';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';

interface ActivityProps {
    activity: ActivityLogEntryType;
}

const LogDisplay = styled.div`
    display: flex;
    border-top: 0.06rem solid ${shade200};
    border-bottom: 0.06rem solid ${shade200};
    padding: ${charlie} 0;
`;

const LogInfo = styled.div`
    margin-left: 0.4rem;
`;

const LogTitle = styled.p`
    ${label2}
    font-weight: bold;
`;

const LogDate = styled(Caption)`
    font-weight: normal;
    color: ${shade600};
`;

const LogNote = styled.p`
    margin: 0.2rem auto;
    font-size: 0.8rem;

    button {
        font-size: 0.8rem;
    }
`;

interface IconContainerProps {
    logType: LogType;
}

// different icons appear to be differnt sizes, no scope to standardise
const outsizedIconTypes = [STAFF_ASSIGNED, APPLICATION_STARTED, UPLOAD];

const IconContainer = styled.div<IconContainerProps>`
    ${(props) => (outsizedIconTypes.includes(props.logType) ? ' margin: 0.5rem;' : '')}
`;

const Activity = ({ activity }: ActivityProps): ReactElement => {
    const client = useApolloClient();
    const { allStaffUsers } = useContext(AdminStateContext);
    const { staffUsers: orgStaffUsers } = useContext(StaffUserContext);
    const { currentApplicant } = useContext(ApplicantsContext);
    const { isAdmin } = useContext(AuthContext);
    const staffUsers = isAdmin ? allStaffUsers : orgStaffUsers;
    const [loading, setLoading] = useState(false);
    const [downloadError, setDownloadError] = useState('');

    const handleDownload = async () => {
        setLoading(true);
        if (currentApplicant) {
            const keyPrefix = getApplicantUploadPath(currentApplicant);
            const key = `uploads/${keyPrefix}/${activity.details.fileUuid}`;

            const downloadStatus = await downloadFile({ client, key });

            if (downloadStatus === ERROR) {
                setDownloadError('There was an error downloading the file. Please try again.');
                setLoading(false);
            } else {
                const link = document.createElement('a');
                link.href = downloadStatus;
                link.target = '_blank'; // Open in a new window
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setLoading(false);
            }
        }
    };

    return (
        <LogDisplay>
            <IconContainer logType={activity.logType}>
                <Icon icon={getLogIcon(activity)} fill={shade600} size={getLogIconSize(activity.logType)} />
            </IconContainer>
            <LogInfo>
                <LogTitle>{getLogTitle(activity, staffUsers)}</LogTitle>
                {activity.details.note && <LogNote>{activity.details.note}</LogNote>}
                {activity.details.checkId && <LogNote>{`Check ID: ${activity.details.checkId}`}</LogNote>}
                {activity.details.fileUuid && (
                    <LogNote>
                        <LinkButton onClick={() => handleDownload()} disabled={loading}>
                            Download file
                        </LinkButton>
                        {downloadError && <ErrorMessage>{downloadError}</ErrorMessage>}
                    </LogNote>
                )}
                <LogDate>{formatDetailsDate(activity.createdAt)}</LogDate>
            </LogInfo>
        </LogDisplay>
    );
};

export default Activity;
