import { GridApi } from '@ag-grid-community/core';
import { values } from 'ramda';
import React, { ReactElement, useContext, useState } from 'react';
import { getStaffDashboardCellRenderers, GridWrapper } from '../../utils/grid';
import Button from '../bits/Buttons/Button';
import SearchBar from '../bits/FormFields/SearchBar';
import Grid from '../bits/Grid/Grid';
import { applicantsObscuredColumns, applicantsVisibleColumns } from '../bits/Grid/gridOptions';
import DashboardButtonSearch from '../patterns/DashboardButtonSearch';
import Obscure from '../bits/Obscure';
import IconButton from '../bits/Buttons/IconButton';
import { useHistory, useParams } from 'react-router-dom';
import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import { ApplicantsContext } from '../WithApplicants/ApplicantsContext';
import { AdminStateContext } from '../WithAdminState/adminState';
import { getRouteApplicantDetail } from '../../utils/routes';
import AuthContext from '../WithAuth/AuthContext';

const Applicants = (): ReactElement => {
    const history = useHistory();
    const { orgUuid }: { orgUuid: string } = useParams();
    const { isAdmin } = useContext(AuthContext);
    const { organisations, redirectToOrg } = useContext(AdminStateContext);
    const { applicants, setNewApplication, setCurrentApplicant } = useContext(ApplicantsContext);
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [showNames, setShowNames] = useState(false);
    const orgsForColumns = isAdmin && !orgUuid ? organisations : undefined;

    const body = (
        <>
            {(!isAdmin || !!orgUuid) && (
                <Button buttonStyle="primary" text="+ New Applicant" onClick={() => setNewApplication(true)} />
            )}
            <SearchBar
                placeholder="Name or PIN"
                onChange={({ target }) => gridApi?.setQuickFilter(target.value)}
                disabled={!gridApi}
            />
        </>
    );

    const obscureButton = (
        <IconButton
            icon={showNames ? 'switchUnselectedEnabled' : 'switchSelectedEnabled'}
            width="3rem"
            height="3rem"
            onClick={() => setShowNames(!showNames)}
        />
    );

    const redirectToApplicant = (applicant: ApplicantWithStaffDetails, applicantUuid: string) => {
        if (applicant && applicantUuid) {
            setCurrentApplicant(applicant);
            history.push(getRouteApplicantDetail(applicantUuid));
        }
    };

    return (
        <GridWrapper>
            <DashboardButtonSearch title={orgUuid ? 'Applicants' : 'Dashboard'} body={body} />
            <Obscure button={obscureButton} />
            <Grid
                gridApi={gridApi}
                setGridApi={setGridApi}
                columns={
                    showNames ? applicantsVisibleColumns(orgsForColumns) : applicantsObscuredColumns(orgsForColumns)
                }
                rowData={values(applicants)}
                frameworkComponent={{
                    ...getStaffDashboardCellRenderers({
                        applicantData: applicants,
                        redirectToApplicant,
                        orgsData: organisations,
                        redirectToOrg,
                    }),
                }}
            />
        </GridWrapper>
    );
};

export default Applicants;
