import React from 'react';
import { getYotiSession } from '../../../graphql/apis';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';
import Loading from '../../bits/Loading';
import YotiSessionResults from './YotiSessionResults';

interface YotiSessionProps {
    sessionId: string;
}

const YotiSession = ({ sessionId }: YotiSessionProps) => {
    const { data, error, loading } = getYotiSession.hook({ sessionId }, { skip: !sessionId, fetchPolicy: 'no-cache' });
    const yotiSession = data?.getYotiSession;

    if (loading) return <Loading />;
    if (error) return <ErrorMessage>{error.message}</ErrorMessage>;
    if (!yotiSession) return <ErrorMessage>No Yoti session found</ErrorMessage>;
    return <YotiSessionResults yotiSession={yotiSession} />;
};

export default YotiSession;
