import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

export interface ListProps {
    children: ReactNode;
}

export const StyledList = styled.ul`
    list-style-type: circle;
    list-style-position: inside;
`;

const List = ({ children, ...props }: ListProps): ReactElement => <StyledList {...props}>{children}</StyledList>;

export default List;
