import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { red400, white } from '../../theme/colours';
import { bravo, charlie, echo } from '../../theme/sizing';
import { Title3 } from '../bits/Titles/titles';

interface NotificationProps {
    body: ReactNode;
    heading: string;
}

const Wrapper = styled.div`
    border: ${charlie} solid ${red400};
    margin: ${echo} auto;
    p {
        margin: ${charlie};
    }
`;

const Heading = styled(Title3)`
    color: ${white};
    background: ${red400};
    padding-bottom: ${charlie};
    padding-left: ${bravo};
`;

const Notification = ({ body, heading }: NotificationProps): ReactElement => {
    return (
        <Wrapper>
            <Heading>{heading}</Heading>
            {body}
        </Wrapper>
    );
};

export default Notification;
