import React from 'react';
import { Title2 } from '../../bits/Titles/titles';
import styled from 'styled-components';
import StatusBadge from '../../bits/Grid/StatusBadge';
import { getApplicationStatusText } from '../../../utils/application';
import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import Button from '../../bits/Buttons/Button';
import { shade500 } from '../../../theme/colours';
import { charlie, echo, bravo, delta, foxtrot } from '../../../theme/sizing';
import ButtonsWrapper from '../../bits/DetailPage/ButtonsWrapper';
import AssignProxyAddress from '../AssignProxyAddress/AssignProxyAddress';

interface ApprovalSubmitProps {
    applicant: ApplicantWithStaffDetails;
    setOpen: (arg: boolean) => void;
    approveApplication: () => void;
    assignedProxyAddressUuid?: string;
    setAssignedProxyAddressUuid: (arg: string) => void;
}

const ApplicationStatusWrapper = styled.div`
    margin-top: ${charlie};
    margin-bottom: ${echo};
`;

const InfoWrapper = styled.div`
    border-left: ${bravo} solid ${shade500};
    padding-left: ${delta};
    margin-top: ${foxtrot};
    margin-bottom: ${echo};
    p {
        font-size: 0.875rem;
        b {
            font-size: 0.875rem;
        }
    }
`;

const ApprovalSubmit = ({
    applicant,
    setOpen,
    approveApplication,
    assignedProxyAddressUuid,
    setAssignedProxyAddressUuid,
}: ApprovalSubmitProps) => (
    <>
        <Title2>Approve {applicant.name}'s application?</Title2>
        {applicant.applicationStatus && (
            <ApplicationStatusWrapper>
                <StatusBadge text={getApplicationStatusText(applicant.applicationStatus)} />
            </ApplicationStatusWrapper>
        )}

        <AssignProxyAddress
            assignedProxyAddressUuid={assignedProxyAddressUuid}
            setAssignedProxyAddressUuid={setAssignedProxyAddressUuid}
        />

        <InfoWrapper>
            <p>
                An email with the details of {applicant.name}'s new ProxyAddress and Proxy Identification Number (PIN)
                will be sent to <b>{applicant.email}</b>
            </p>
        </InfoWrapper>

        <ButtonsWrapper>
            <Button
                buttonStyle="tertiary"
                text="Cancel"
                onClick={() => {
                    setOpen(false);
                }}
            />
            <Button
                buttonStyle="primary"
                text="Approve application"
                onClick={() => approveApplication()}
                disabled={!assignedProxyAddressUuid}
            />
        </ButtonsWrapper>
    </>
);

export default ApprovalSubmit;
