import gql from 'graphql-tag';
import {
    ApplicationType,
    UpdateApplicationInput,
    Applicant,
    ApplicationDecision,
    ActivityLogInput,
} from 'proxyaddress-common/types';
import wrapMutation from './wrapMutation';
import { ApplicantType, Application } from './applicant';

export interface UpdateApplicationParams {
    application: UpdateApplicationInput;
    activityLogEntry?: ActivityLogInput;
}

export interface UpdateApplicationData {
    application: ApplicationType;
}

export const applicationMutation = gql`
    mutation editApplication($application: UpdateApplicationInput!, $activityLogEntry: ActivityLogInput, $sendRetryEmail: Boolean) {
        editApplication(application: $application, activityLogEntry: $activityLogEntry, sendRetryEmail: $sendRetryEmail) ${Application}
    }
`;

export const updateApplication = wrapMutation<UpdateApplicationParams, UpdateApplicationData>(applicationMutation);

export interface SubmitApplicationDecisionParams {
    closingDecision: ApplicationDecision;
    proxyAddressUuid?: string;
    activityLogEntry: ActivityLogInput;
}

interface SubmitApplicationDecisionData {
    submitApplicationDecision: Applicant;
}

export const submitApplicationDecisionMutation = gql`
    mutation submitApplicationDecision($closingDecision: ApplicationDecision!, $proxyAddressUuid: String, $councilAreaUuid: String , $activityLogEntry: ActivityLogInput!) {
        submitApplicationDecision(closingDecision: $closingDecision, proxyAddressUuid: $proxyAddressUuid, councilAreaUuid: $councilAreaUuid ,activityLogEntry: $activityLogEntry) ${ApplicantType}
    }    
`;

export const submitApplicationDecision = wrapMutation<SubmitApplicationDecisionParams, SubmitApplicationDecisionData>(
    submitApplicationDecisionMutation,
);
