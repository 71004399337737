import React from 'react';
import { YotiResult } from 'proxyaddress-common/types';
import { YotiState, YotiStateType } from '../../../utils/constants';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';
import Notification from '../../bits/Notification/Notification';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import YotiCheck from './YotiCheck';

interface YotiSessionResultsProps {
    yotiSession: YotiResult;
}

const YotiSessionResults = ({ yotiSession }: YotiSessionResultsProps) => {
    const sessionState: YotiStateType = YotiState[yotiSession.state as keyof typeof YotiState];

    if (!sessionState)
        return (
            <ErrorMessage>
                Unknown Yoti state, please contact ProxyAddress quoting session id: {yotiSession.sessionId}
            </ErrorMessage>
        );

    if (sessionState === YotiState.COMPLETED) {
        const orderedChecks = yotiSession.checks.sort((a, b) => {
            return new Date(b.created).getTime() - new Date(a.created).getTime();
        });

        return (
            <>
                {orderedChecks.map((check) => (
                    <YotiCheck key={check.id} check={check} session={yotiSession} />
                ))}
            </>
        );
    } else {
        return (
            <Notification borderColor={sessionState.color}>
                <BodyCopy>
                    <h3>{sessionState.title}</h3>
                    <p>{sessionState.description}</p>
                </BodyCopy>
            </Notification>
        );
    }
};

export default YotiSessionResults;
