import gql from 'graphql-tag';
import wrapQuery from './wrapQuery';
import wrapMutation from './wrapMutation';
import { CouncilArea, CouncilAreaInput, UpdateCouncilAreaArgs } from 'proxyaddress-common/types/councilAreas';

interface ListCouncilAreasData {
    listCouncilAreas: CouncilArea[];
}

interface ListCounciLAreasParams {
    isActive: boolean;
}

export const CouncilAreaType = `{
    name
    region
    councilStatus
    councilAreaUuid
}`;

export const listCouncilAreasQuery = gql`
    query listCouncilAreas($isActive: Boolean) {
       listCouncilAreas(isActive: $isActive) ${CouncilAreaType}
    }
`;

export const listCouncilAreas = wrapQuery<ListCounciLAreasParams, ListCouncilAreasData>(listCouncilAreasQuery);

export interface CreateCouncilAreasParams {
    councilAreaInput: CouncilAreaInput[];
}

export const createCouncilAreasMutation = gql`
    mutation createCouncilAreas($councilAreaInput: [CouncilAreaInput!]!) {
        createCouncilAreas(councilAreas: $councilAreaInput)
    }
`;

export const createCouncilAreas = wrapMutation<CreateCouncilAreasParams, void>(createCouncilAreasMutation);

export interface UpdateCouncilAreaParams {
    councilArea: UpdateCouncilAreaArgs;
}

interface UpdateCouncilAreaData {
    updateCouncilArea: CouncilArea;
}

export const updateCouncilAreaMutation = gql`
    mutation editCouncilArea($councilArea: CouncilAreaInput!) {
        editCouncilArea(councilArea: $councilArea) ${CouncilAreaType}
}`;

export const updateCouncilArea = wrapMutation<UpdateCouncilAreaParams, UpdateCouncilAreaData>(
    updateCouncilAreaMutation,
);

export const deleteCouncilAreaMutation = gql`
    mutation deleteCouncilArea($councilAreaUuid: String!) {
        deleteCouncilArea(councilAreaUuid: $councilAreaUuid)
    }
`;

export const deleteCouncilArea = wrapMutation<{ councilAreaUuid: string }, string>(deleteCouncilAreaMutation);
