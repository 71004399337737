import { createGlobalStyle } from 'styled-components';
import { bodyBackground, shade600 } from './colours';
import RalewayBold from './fonts/Raleway-Bold.ttf';
import RalewaySemiBold from './fonts/Raleway-SemiBold.ttf';
import OpenSansRegular from './fonts/OpenSans-Regular.ttf';
import OpenSansSemiBold from './fonts/OpenSans-SemiBold.ttf';

export const GlobalStyle = createGlobalStyle`
@font-face {
    font-family: 'Raleway';
    font-display: swap;
    src: url(${RalewayBold}) format("truetype");
    font-weight: bold;
    font-display: fallback;
    letter-spacing: -1.44px;
}

@font-face {
    font-family: 'Raleway';
    font-display: swap;
    src: url(${RalewaySemiBold}) format("truetype");
    font-weight: 600;
    font-display: fallback;
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    src: url(${OpenSansRegular}) format("truetype");
    font-weight: 400;
    font-display: fallback;
}

@font-face {
    font-family: 'Open Sans';
    font-display: swap;
    src: url(${OpenSansSemiBold}) format("truetype");
    font-weight: 600;
    font-display: fallback;
}

a, abbr, acronym, address, applet, article, aside, audio, big, blockquote, body, button, canvas, caption, center,
cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form,
h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, input, ins, kbd, label, legend, li, mark, menu, nav,
object, ol, option, output, p, pre, q, ruby, s, samp, section, select, small, span, strike, strong, sub, summary, sup, table,
tbody, td, textarea, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 100%;
    font-weight: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

button {
    cursor: pointer;
}

caption {
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.033rem;
}
html {
    font-size: 100%;
    scroll-behavior: smooth;
    background-color: ${bodyBackground};
    color: ${shade600};
}

ol, ul {
    list-style: none;
}
`;

export default GlobalStyle;
