export const ROUTE_CHECK = '/check';
export const ROUTE_APPLICATION = '/application';
export const ROUTE_APPLICATION_NEW = '/application/new/:orgUuid';
export const ROUTE_APPLICANTS = '/applicants';
export const ROUTE_APPLICANT_DETAIL = '/applicants/:applicantUuid';
export const ROUTE_ADMIN = '/admin';
export const ROUTE_SIGN_IN = '/signIn';
export const ROUTE_ROOT = '/';
export const ROUTE_RESET_PASSWORD = '/resetPassword';
export const ROUTE_ADDRESSES = '/addresses';
export const ROUTE_ADDRESSES_NEW = '/addresses/new/:councilAreaUuid/:orgUuid';
export const ROUTE_ADDRESS_DETAIL = '/addresses/:proxyAddressUuid';
export const ROUTE_ORGANISATIONS = '/organisations';
export const ROUTE_ORGANISATIONS_NEW = '/organisations/new';
export const ROUTE_ORGANISATION_DETAIL = '/organisations/:orgUuid';
export const ROUTE_ORGANISATION_EDIT = '/organisations/:orgUuid/edit';
export const ROUTE_COUNCIL_AREAS = '/councilAreas';
export const ROUTE_COUNCIL_AREAS_UPLOAD = '/councilAreas/upload';
export const ROUTE_COUNCIL_AREA_DETAIL = '/councilAreas/:councilAreaUuid';
export const ROUTE_STAFF = '/staff';
export const ROUTE_STAFF_NEW = '/staff/new/:orgUuid';

export const ROUTE_STAFF_ROOT = ROUTE_APPLICANTS;
export const ROUTE_ADMIN_ROOT = ROUTE_ADMIN;
export const ROUTE_APPLICANT_ROOT = ROUTE_APPLICATION;

export const getRouteApplicantDetail = (applicantUuid: string): string =>
    `${ROUTE_APPLICANT_DETAIL.replace(':applicantUuid', applicantUuid)}`;
export const getRouteProxyAddressDetail = (proxyAddressUuid: string): string =>
    `${ROUTE_ADDRESS_DETAIL.replace(':proxyAddressUuid', proxyAddressUuid)}`;
export const getRouteOrganisationDetail = (orgUuid: string): string =>
    `${ROUTE_ORGANISATION_DETAIL.replace(':orgUuid', orgUuid)}`;
export const getRouteOrganisationEdit = (orgUuid: string): string =>
    `${ROUTE_ORGANISATION_EDIT.replace(':orgUuid', orgUuid)}`;
export const getRouteStaffNew = (orgUuid: string): string => `${ROUTE_STAFF_NEW.replace(':orgUuid', orgUuid)}`;
export const getRouteCouncilAreaDetail = (councilAreaUuid: string): string =>
    `${ROUTE_COUNCIL_AREA_DETAIL.replace(':councilAreaUuid', councilAreaUuid)}`;
export const getRouteApplicationNew = (orgUuid: string): string =>
    `${ROUTE_APPLICATION_NEW.replace(':orgUuid', orgUuid)}`;
export const getRouteProxyAddressNew = (councilAreaUuid: string, orgUuid: string) =>
    `${ROUTE_ADDRESSES_NEW.replace(':councilAreaUuid', councilAreaUuid)}`.replace(':orgUuid', orgUuid);
