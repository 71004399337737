import React, { createRef, useEffect, useState } from 'react';
import { PostcodeLookup } from '@ideal-postcodes/postcode-lookup-bundled';
import { Address, ProxyAddressOptionsInput } from 'proxyaddress-common/types';
import { cssSecondaryButton, cssUnstyledButton, LinkButton } from '../Buttons/Button';
import styled from 'styled-components';
import { cssResetButton } from '../../../theme/cssSnippets';
import { isEmpty } from 'ramda';
import Label from './Label';
import FormField from '../FormFields/FormField';
import { Input } from '../FormFields/FormInputField';
import { hotel } from '../../../theme/sizing';
import { IconDropdownArrow } from '../Icons/iconMap';
import { POSTCODE_API_KEY } from '../../../utils/constants';
import { format } from 'date-fns';

interface AddressSearchProps {
    address?: Address;
    setAddress: (address: Address) => void;
    proxyAddressDetails?: ProxyAddressOptionsInput;
    setProxyAddressDetails?: (args: ProxyAddressOptionsInput) => void;
    setManualEnter: (manualEnter: boolean) => void;
    disableManualInput?: boolean;
}

interface AddressAdditional {
    classification_code: string;
    last_update_date: string;
}

interface AddressSearchAddress {
    post_town: string;
    postcode: string;
    building_number: string;
    building_name: string;
    thoroughfare: string;
    county: string;
    district: string;
    administrative_county: string;
    uprn: string;
    premise: string;
    native?: AddressAdditional;
}

const Wrapper = styled.div`
    > * + * {
        margin-top: 1.5rem;
    }

    input,
    select {
        height: ${hotel};
        max-width: 20rem;
    }

    select {
        background-image: url(${IconDropdownArrow});
        background-position: right 0.5rem center;
        background-repeat: no-repeat;
        background-size: 7px;
        padding-left: 0.2rem;
        padding-right: 1rem;
    }
`;

const Button = styled.button`
    ${cssResetButton}
    ${cssUnstyledButton}
    ${cssSecondaryButton}
`;

const ButtonWrapper = styled.div`
    button {
        margin-right: 0.5rem;
    }
`;

const AddressSearch = ({
    address,
    setAddress,
    proxyAddressDetails,
    setProxyAddressDetails,
    setManualEnter,
    disableManualInput,
}: AddressSearchProps) => {
    const context = createRef<HTMLDivElement>();
    const inputContext = createRef<HTMLInputElement>();
    const buttonContext = createRef<HTMLButtonElement>();
    const selectContext = createRef<HTMLDivElement>();
    const [postcode, setPostcode] = useState('');

    const transformAddress = (searchAddress: AddressSearchAddress) => {
        const line1 = `${searchAddress.building_name || searchAddress.premise} ${searchAddress.thoroughfare}`;
        const displayAddress = `${line1}, ${searchAddress.post_town}, ${searchAddress.postcode}`;

        const transformedAddress: Address = {
            town: searchAddress.post_town,
            postcode: searchAddress.postcode,
            streetName: searchAddress.thoroughfare,
            locality: searchAddress.district,
            county: searchAddress.county,
            administrativeArea: searchAddress.administrative_county,
            uprn: searchAddress.uprn,
            displayAddress,
            houseNumber: searchAddress.premise || searchAddress.building_number,
            houseName: searchAddress.building_name,
            classification: '',
        };

        if (proxyAddressDetails && setProxyAddressDetails) {
            const lastUpdateDate = searchAddress.native?.last_update_date
                ? format(new Date(searchAddress.native.last_update_date), 'dd/MM/yyyy')
                : undefined;

            setProxyAddressDetails({
                ...proxyAddressDetails,
                classificationCode: searchAddress.native?.classification_code || '',
                lastUpdateDate,
            });
        }

        return setAddress(transformedAddress);
    };

    useEffect(() => {
        PostcodeLookup.setup({
            apiKey: POSTCODE_API_KEY,
            context: context.current,
            onAddressSelected: (address: AddressSearchAddress) => transformAddress(address),
            input: inputContext.current,
            button: buttonContext.current,
            selectContainer: selectContext.current,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <FormField>
                <Label htmlFor="postcode">Postcode</Label>
                <Input
                    id="postcode"
                    ref={inputContext}
                    type="text"
                    placeholder="Enter your postcode"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                />
            </FormField>
            <ButtonWrapper>
                <Button ref={buttonContext} disabled={postcode.length < 5}>
                    Search
                </Button>
                {(!disableManualInput || address?.postcode) && (
                    <LinkButton onClick={() => setManualEnter(true)} type="button">
                        {!isEmpty(address?.postcode) ? 'Edit address' : 'Enter address manually'}
                    </LinkButton>
                )}
            </ButtonWrapper>
            <FormField>
                <div ref={selectContext}></div>
            </FormField>

            <div ref={context}></div>
        </Wrapper>
    );
};

export default AddressSearch;
