import styled from 'styled-components/macro';
import { contentPrimary } from '../../../theme/colours';
import { label1, title2 } from '../../../theme/cssSnippets';
import { bravo, charlie } from '../../../theme/sizing';

export const Title2 = styled.h2`
    ${title2}
    padding-bottom: ${charlie}
    color: ${contentPrimary};
    font-weight: 600;
`;

export const Title3 = styled.h2`
    ${label1}
    padding-bottom: ${bravo};
    color: ${contentPrimary};
    font-weight: 600;
`;
