import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Address, ApplicantAddress } from 'proxyaddress-common/types';
import { StyledDetailWrapper } from './Detail';
import Caption from '../Caption/Caption';
import { shade600 } from '../../../theme/colours';
import BodyCopy from '../BodyCopy/BodyCopy';
import { isNil, isEmpty } from 'ramda';
import { charlie } from '../../../theme/sizing';

interface AddressProps {
    address: Address | ApplicantAddress[] | undefined;
    caption: string;
}

const AddressDetailWrapper = styled(StyledDetailWrapper)`
    p + p {
        margin-top: ${charlie};
    }
`;

const AddressDetail = ({ address, caption }: AddressProps): ReactElement => (
    <AddressDetailWrapper>
        <Caption color={shade600}>{caption}</Caption>
        {address && Array.isArray(address) && isEmpty(address) && <p>-</p>}
        {address &&
            Array.isArray(address) &&
            address.map((applicantAddress) => (
                <BodyCopy key={applicantAddress.applicantAddressUuid}>
                    <p>{applicantAddress.displayAddress}</p>
                </BodyCopy>
            ))}

        {/* If the display address is an empty string, it means all fields of an address are empty */}
        {!Array.isArray(address) && (
            <BodyCopy>
                <p>{isEmpty(address?.displayAddress) ? '-' : address?.displayAddress}</p>
            </BodyCopy>
        )}
        {isNil(address) && <p>-</p>}
    </AddressDetailWrapper>
);

export default AddressDetail;
