import React, { useState } from 'react';
import styled from 'styled-components';
import Form from '../../bits/Form/Form';
import Button from '../../bits/Buttons/Button';
import TextInput from '../../bits/FormFields/TextInput';
import { isEmpty } from 'ramda';
import { validatePin } from '../../../utils/pin';
import FormField from '../../bits/FormFields/FormField';
import Label from '../../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../../bits/FormFields/Dates';
import Caption from '../../bits/Caption/Caption';
import { validateDate } from '../../../utils/application';
import { getApplicantByPin } from '../../../graphql/applicant';
import { ApplicantByPin } from 'proxyaddress-common/types';
import Loading from '../../bits/Loading';
import { SUPPORT_EMAIL } from '../../../utils/constants';

interface CheckFormProps {
    showResults: boolean;
    setShowResults: (showResults: boolean) => void;
    applicant?: ApplicantByPin;
    setApplicant: (applicant: ApplicantByPin) => void;
    setDateOfBirth: (dob: string) => void;
}

interface CheckFormErrors {
    pin: string;
    dobDay: string;
    dobMonth: string;
    dobYear: string;
    dob: string;
}

const checkFormInitialErrors = {
    pin: '',
    dobDay: '',
    dobMonth: '',
    dobYear: '',
    dob: '',
};

interface Date {
    day: string;
    month: string;
    year: string;
}

const initialDate = {
    day: '',
    month: '',
    year: '',
};

const Footer = styled(Caption)`
    margin-top: 2rem;
`;

const CheckForm = ({ showResults, setShowResults, setApplicant, setDateOfBirth, applicant }: CheckFormProps) => {
    const [pin, setPin] = useState<string>('');
    const [dob, setDob] = useState<Date>(initialDate);
    const [error, setError] = useState<CheckFormErrors>(checkFormInitialErrors);
    const [getApplicantByPinQuery, { loading, error: queryError }] = getApplicantByPin.lazyHook(
        {},
        {
            onCompleted: (data) => {
                setResultState(data.getApplicantByPin);
            },
        },
    );

    const setResultState = (applicant: ApplicantByPin) => {
        setApplicant(applicant);
        setDateOfBirth(`${dob.year}-${dob.month}-${dob.day}`);
        setShowResults(true);
    };

    const formFields = (
        <>
            <TextInput
                id="pin"
                label="PIN"
                helperText="e.g. EA39 9ES"
                value={pin}
                onChange={(e) => setPin(e.target.value.toUpperCase())}
                error={error.pin}
                disabled={showResults}
            />
            <FormField error={error.dob}>
                <Label>Date of birth</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="Day"
                        type="text"
                        value={dob.day}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDob({ ...dob, day: e.target.value })}
                        preText="Day"
                        hasError={!isEmpty(error.dobDay) || undefined}
                        size="small"
                        disabled={showResults}
                    />
                    <DateInputField
                        id="Month"
                        type="text"
                        value={dob.month}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDob({ ...dob, month: e.target.value })}
                        preText="Month"
                        hasError={!isEmpty(error.dobMonth) || undefined}
                        size="small"
                        disabled={showResults}
                    />
                    <DateInputField
                        id="Year"
                        type="text"
                        value={dob.year}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDob({ ...dob, year: e.target.value })}
                        preText="Year"
                        hasError={!isEmpty(error.dobYear) || undefined}
                        disabled={showResults}
                    />
                </DateInputsWrapper>
                <Caption>e.g. 25 11 1965</Caption>
            </FormField>

            {!showResults && !loading && (
                <Button
                    buttonStyle="secondary"
                    text="Submit"
                    type="submit"
                    disabled={!pin || !dob.day || !dob.month || !dob.year}
                />
            )}

            {loading && <Loading color="white" />}

            <Footer>
                If you would like to report any issues, including suspected safeguarding issues, then please contact{' '}
                <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
            </Footer>
        </>
    );

    const onSubmit = () => {
        setError(checkFormInitialErrors);

        if (isEmpty(pin) || isEmpty(dob.day) || isEmpty(dob.month) || isEmpty(dob.year)) {
            setError({
                pin: isEmpty(pin) ? 'Please enter a PIN' : '',
                dobDay: isEmpty(dob.day) ? 'Please enter a day' : '',
                dobMonth: isEmpty(dob.month) ? 'Please enter a month' : '',
                dobYear: isEmpty(dob.year) ? 'Please enter a year' : '',
                dob: '',
            });
            return;
        }

        const pinValidationResult = validatePin(pin);
        if (!isEmpty(pinValidationResult)) {
            setError({ ...error, pin: pinValidationResult });
            return;
        }

        const validatedDob = validateDate(dob.day, dob.month, dob.year);
        if (isEmpty(validatedDob)) {
            setError({ ...error, dob: 'Invalid date of birth' });
            return;
        }

        if (!applicant || applicant.pin !== pin) {
            getApplicantByPinQuery({ variables: { pin } });
        } else {
            setResultState(applicant);
        }
    };

    return (
        <Form
            description={
                <p>
                    You can use this form to validate a ProxyAddress. You will need the ProxyAddress user's PIN and
                    their date of birth.
                </p>
            }
            body={formFields}
            onSubmit={onSubmit}
            loading={false}
            error={!!queryError}
        />
    );
};

export default CheckForm;
