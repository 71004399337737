import { isRight } from 'fp-ts/lib/Either';
import { CouncilArea, CouncilAreaOutput, ProxyAddress, StaffUser } from 'proxyaddress-common/types';
import { Org } from 'proxyaddress-common/types/organisation';
import { prop, sortBy } from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { listCouncilAreas } from '../../graphql/councilArea';
import { listOrganisations } from '../../graphql/organisation';
import { listProxyAddresses } from '../../graphql/proxyAddress';
import { listAllStaffUsers } from '../../graphql/staffUser';
import { formatRegion } from '../../utils/councilAreas';
import { PageLoading } from '../bits/Loading';
import { useDataFormatter } from '../hooks/hooks';
import { AdminStateContext } from './adminState';
import { getRouteOrganisationDetail, getRouteProxyAddressDetail } from '../../utils/routes';

export const WithAdminState: FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
    const history = useHistory();

    const [currentOrganisation, setCurrentOrganisation] = useState<Org | undefined>();
    const [councilAreas, setCouncilAreas] = useState<Record<string, CouncilAreaOutput>>();
    const [currentProxyAddress, setCurrentProxyAddress] = useState<ProxyAddress | undefined>();
    const { data: ListAllStaffUsersData } = listAllStaffUsers.hook();
    const { data: ListOrganisationsData, refetch: refetchOrganisations } = listOrganisations.hook();
    const { data: ListCouncilAreasData, refetch: refetchCouncilAreas } = listCouncilAreas.hook({ isActive: false });
    const { data: ListProxyAddressesData } = listProxyAddresses.hook();

    const organisations = useDataFormatter({
        data: ListOrganisationsData?.listOrganisations,
        Type: Org,
        targetUuid: 'orgUuid',
    }) as Record<string, Org>;

    const allStaffUsers = useDataFormatter({
        data: ListAllStaffUsersData?.listAllStaffUsers,
        Type: StaffUser,
        targetUuid: 'staffUuid',
    }) as Record<string, StaffUser>;

    const proxyAddresses = useDataFormatter({
        data: ListProxyAddressesData?.listProxyAddresses,
        Type: ProxyAddress,
        targetUuid: 'proxyAddressUuid',
    }) as Record<string, ProxyAddress>;

    useEffect(() => {
        if (ListCouncilAreasData?.listCouncilAreas) {
            const sortByCouncilName = sortBy(prop('name'));
            const sortedCouncilAreas = sortByCouncilName(ListCouncilAreasData.listCouncilAreas);

            const formattedCouncilAreas = sortedCouncilAreas.reduce(
                (acc: Record<string, CouncilAreaOutput>, area: CouncilArea) => {
                    const maybeCouncilArea = CouncilArea.decode(area);
                    if (isRight(maybeCouncilArea)) {
                        const formattedArea = {
                            ...maybeCouncilArea.right,
                            region: formatRegion(maybeCouncilArea.right.region),
                        };
                        const councilAreaUuid = maybeCouncilArea.right.councilAreaUuid;
                        return { ...acc, [councilAreaUuid]: formattedArea };
                    }
                    throw new Error('Council area was not the right shape');
                },
                {},
            );

            setCouncilAreas(formattedCouncilAreas);
        } else {
            setCouncilAreas(undefined);
        }
    }, [ListCouncilAreasData]);

    const redirectToOrg = (org: Org | undefined, orgUuid: string) => {
        if (org && orgUuid) {
            setCurrentOrganisation(org);
            history.push(getRouteOrganisationDetail(orgUuid));
        }
    };

    const redirectToProxyAddress = (proxyAddress: ProxyAddress | undefined, proxyAddressUuid: string) => {
        if (proxyAddress && proxyAddressUuid) {
            setCurrentProxyAddress(proxyAddress);
            history.push(getRouteProxyAddressDetail(proxyAddressUuid), {
                state: { referrer: history.location.pathname },
            });
        }
    };

    if (!organisations || !allStaffUsers || !councilAreas || !proxyAddresses) {
        return <PageLoading />;
    }

    return (
        <AdminStateContext.Provider
            value={{
                organisations,
                currentOrganisation,
                setCurrentOrganisation,
                allStaffUsers,
                refetchData: refetchOrganisations,
                councilAreas,
                refetchCouncilAreas,
                redirectToOrg,
                proxyAddresses,
                currentProxyAddress,
                setCurrentProxyAddress,
                redirectToProxyAddress,
            }}
        >
            {children}
        </AdminStateContext.Provider>
    );
};
