import gql from 'graphql-tag';
import { ActivityLogEntryType, ActivityLogInput } from 'proxyaddress-common/types';
import wrapMutation from './wrapMutation';
import { ActivityLogEntry } from './applicant';

export interface CreateActivityLogEntryParams {
    activityLogEntry: ActivityLogInput;
}
export interface CreateActivityLogEntryData {
    createActivityLogEntry: ActivityLogEntryType;
}

export const createActivityLogEntryMutation = gql`
    mutation createActivityLogEntry($activityLogEntry: ActivityLogInput!) {
        createActivityLogEntry(activityLogEntry: $activityLogEntry) ${ActivityLogEntry}
    }
`;

export const createActivityLogEntry = wrapMutation<CreateActivityLogEntryParams, CreateActivityLogEntryData>(
    createActivityLogEntryMutation,
);
