import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { bravo, foxtrot, hotel } from '../../../theme/sizing';
import Caption from '../Caption/Caption';
import FormField from './FormField';
import Label from '../Form/Label';
import { InputWrapper } from './InputWrapper';
import { Icon } from '../Icons';

export interface InputOptions {
    value: string;
    label: string;
}

export interface SelectInputProps {
    id: string;
    helperText?: string | ReactElement;
    label: string;
    placeholder?: string;
    options?: InputOptions[];
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    onBlur?: () => void;
    value?: string;
    error?: string;
    defaultValue?: string;
    width?: string;
    hideLabel?: boolean;
    children?: ReactElement;
    disabled?: boolean;
}

const dropdownIconSize = foxtrot;

const IconContainer = styled.div`
    margin-top: auto;
    margin-left: -1.5rem;
    padding-bottom: 0.1rem;
    z-index: 1;
`;

const StyledSelectInput = styled.select`
    align-self: flex-start;
    border: none;
    height: ${hotel};
    width: 100%;
    padding-right: ${dropdownIconSize};
    padding-left: ${bravo};
    background: none;
    z-index: 2;
`;

const SelectInput = ({
    id,
    helperText,
    label,
    options,
    onChange,
    onBlur,
    value,
    error,
    placeholder,
    defaultValue,
    width,
    hideLabel,
    children,
    disabled,
    ...props
}: SelectInputProps): ReactElement => (
    <FormField error={error} {...props}>
        <Label hidden={hideLabel} htmlFor={id}>
            {label}
        </Label>
        <InputWrapper width={width} {...props}>
            <StyledSelectInput
                id={id}
                onChange={onChange}
                defaultValue={value ? undefined : defaultValue || (placeholder && '-')}
                value={value}
                onBlur={onBlur}
                disabled={disabled}
            >
                {placeholder && (
                    <option disabled value="-">
                        {placeholder}
                    </option>
                )}
                {options?.map((option: InputOptions) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                {children}
            </StyledSelectInput>
            <IconContainer>
                <Icon icon="dropdown" size={dropdownIconSize} />
            </IconContainer>
        </InputWrapper>
        {helperText && <Caption>{helperText}</Caption>}
    </FormField>
);

export default SelectInput;
