import { ProxyAddress, ProxyAddressSource, UpdateProxyAddressArgs } from 'proxyaddress-common/types';
import { isEmpty, values } from 'ramda';
import React, { ReactElement, useContext, useRef, useState } from 'react';
import {
    EditProxyAddressDetailsErrors,
    editProxyAddressDetailsInitialErrors,
    formatEditedProxyAddress,
    getEditProxyAddressDetails,
    proxyAddressActiveStatusOptions,
    ProxyAddressEditDetails,
    proxyAddressSourceOptions,
    validateEditProxyAddressDetailsInput,
} from '../../utils/proxyAddress';
import Button, { LinkButton } from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import Form from '../bits/Form/Form';
import Label from '../bits/Form/Label';
import DateInputField, { DateInputsWrapper } from '../bits/FormFields/Dates';
import FormField from '../bits/FormFields/FormField';
import SelectInput from '../bits/FormFields/SelectInput';
import { updateProxyAddress } from '../../graphql/proxyAddress';
import { AdminStateContext } from '../WithAdminState/adminState';
import ButtonsWrapper from '../bits/DetailPage/ButtonsWrapper';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import Fieldset from '../bits/Form/Fieldset';
import Legend from '../bits/Form/Legend';
import TextInput from '../bits/FormFields/TextInput';
import CheckboxField from '../bits/FormFields/CheckboxField';

interface EditProxyAddressDetailsProps {
    proxyAddress: ProxyAddress;
    setEditing: (arg: boolean) => void;
}

const EditProxyAddressDetails = ({ proxyAddress, setEditing }: EditProxyAddressDetailsProps): ReactElement => {
    const [updateProxyAddressMutation, { loading, error: updateError }] = updateProxyAddress.hook();
    const { setCurrentProxyAddress: adminSetCurrentProxyAddress } = useContext(AdminStateContext);
    const { setCurrentProxyAddress: staffSetCurrentProxyAddress } = useContext(StaffUserContext);
    const setCurrentProxyAddress = adminSetCurrentProxyAddress || staffSetCurrentProxyAddress;
    const [errors, setErrors] = useState<EditProxyAddressDetailsErrors>(editProxyAddressDetailsInitialErrors);
    const [details, setDetails] = useState<ProxyAddressEditDetails>(getEditProxyAddressDetails(proxyAddress));

    const isActiveInitial = useRef(proxyAddress.isActive);

    const submitUpdates = async () => {
        const validationResult = validateEditProxyAddressDetailsInput(details.expiryDate);

        if (values(validationResult).every(isEmpty)) {
            const proxyAddressUpdates: UpdateProxyAddressArgs = formatEditedProxyAddress({
                proxyAddress,
                details,
                isActiveInitial: isActiveInitial.current,
            });

            const updatedProxyAddress = await updateProxyAddressMutation({
                variables: { proxyAddressUpdates },
            });

            if (updatedProxyAddress.data) {
                setCurrentProxyAddress(updatedProxyAddress.data.editProxyAddress);
                setEditing(false);
            }
        } else {
            setErrors(validationResult);
        }
    };

    const formBody = (
        <>
            {/* TODO set status to disabled if are applicants assigned when PXA-79 is finished */}
            <SelectInput
                label="Status"
                id="isActive"
                width="15rem"
                defaultValue={proxyAddress?.isActive ? 'Active' : 'Inactive'}
                options={proxyAddressActiveStatusOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setDetails({
                        ...details,
                        isActive: e.target.value === 'Active' ? true : false,
                    })
                }
            />
            <SelectInput
                label="Address source"
                id="source"
                width="15rem"
                defaultValue={proxyAddress.source}
                options={proxyAddressSourceOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setDetails({ ...details, source: e.target.value as ProxyAddressSource })
                }
            />
            {/* TODO: PXA-410 */}
            <Fieldset>
                <Legend>Additional fields</Legend>
                <TextInput
                    label="Logical status code"
                    id="logicalStatusCode"
                    value={details.logicalStatusCode}
                    onChange={(e) => setDetails({ ...details, logicalStatusCode: e.target.value })}
                    helperText="Verified: Yes or No"
                />
                <TextInput
                    label="Postal address code"
                    id="postalAddressCode"
                    value={details.postalAddressCode}
                    onChange={(e) => setDetails({ ...details, postalAddressCode: e.target.value })}
                    helperText="Yes or No. Conclude from NYB/MR query."
                />
                <TextInput
                    label="Classification code"
                    id="classificationCode"
                    value={details.classificationCode}
                    onChange={(e) => setDetails({ ...details, classificationCode: e.target.value })}
                    helperText="Type: Residential or HMO."
                />
                <TextInput
                    label="BLPU state code"
                    id="blpuStateCode"
                    value={details.blpuStateCode}
                    onChange={(e) => setDetails({ ...details, blpuStateCode: e.target.value })}
                    helperText="Status: In Use, In Construction, Vacant etc. Refer to NYB query."
                />
                <TextInput
                    label="Last update date"
                    id="lastUpdateDate"
                    value={details.lastUpdateDate}
                    onChange={(e) => setDetails({ ...details, lastUpdateDate: e.target.value })}
                />
                <TextInput
                    label="Local custodian code"
                    id="localCustodianCode"
                    value={details.localCustodianCode}
                    onChange={(e) => setDetails({ ...details, localCustodianCode: e.target.value })}
                />
                <CheckboxField
                    label="Multiple residence?"
                    id="isMultipleResidence"
                    checked={details.isMultipleResidence || false}
                    onChange={(e) => setDetails({ ...details, isMultipleResidence: e.target.checked })}
                />
                <CheckboxField
                    label="Not yet built?"
                    id="isNotYetBuilt"
                    checked={details.isNotYetBuilt || false}
                    onChange={(e) => setDetails({ ...details, isNotYetBuilt: e.target.checked })}
                />
            </Fieldset>
            <FormField error={errors.expiryDateError}>
                <Label>Expiry date</Label>
                <DateInputsWrapper>
                    <DateInputField
                        id="expiryDay"
                        type="text"
                        value={details.expiryDate.expiryDay}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setDetails({
                                ...details,
                                expiryDate: {
                                    ...details.expiryDate,
                                    expiryDay: e.target.value,
                                },
                            })
                        }
                        preText="Day"
                        size="small"
                    />

                    <DateInputField
                        id="expiryMonth"
                        type="text"
                        value={details.expiryDate.expiryMonth}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setDetails({
                                ...details,
                                expiryDate: {
                                    ...details.expiryDate,
                                    expiryMonth: e.target.value,
                                },
                            })
                        }
                        preText="Month"
                        size="small"
                    />

                    <DateInputField
                        id="expiryYears"
                        type="text"
                        value={details.expiryDate.expiryYear}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setDetails({
                                ...details,
                                expiryDate: {
                                    ...details.expiryDate,
                                    expiryYear: e.target.value,
                                },
                            })
                        }
                        preText="Year"
                    />
                </DateInputsWrapper>
                <Caption>For example, 3 11 2024</Caption>
            </FormField>
            <ButtonsWrapper>
                <Button buttonStyle="primary" text="Save changes" type="submit" />
                <LinkButton onClick={() => setEditing(false)}>Cancel and discard changes</LinkButton>
            </ButtonsWrapper>
        </>
    );

    return (
        <Form
            cancelTitle="Cancel editing"
            onCancel={() => setEditing(false)}
            body={formBody}
            loading={loading}
            error={!!updateError}
            onSubmit={submitUpdates}
        />
    );
};

export default EditProxyAddressDetails;
