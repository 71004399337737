import { map } from 'ramda';
import React, { ReactElement, ReactNode } from 'react';
import BodyCopy from '../BodyCopy/BodyCopy';
import Button from '../Buttons/Button';
import MidColumn from '../Layout/MidColumn';
import DetailPageTitle from '../DetailPage/DetailPageTitle';

interface IntroProps {
    body?: ReactNode;
    text: string[];
    title: string;
    cancelTitle?: string;
    submitTitle?: string;
    onCancel?: () => void;
    onSubmit?: () => void;
}

const Intro = ({ body, text, title, onCancel, onSubmit, cancelTitle, submitTitle }: IntroProps): ReactElement => (
    <MidColumn>
        <DetailPageTitle title={title} onBackButtonClick={onCancel} backButtonLabel={cancelTitle} />
        {map(
            (line) => (
                <BodyCopy key={line} textAlign="left">
                    {line}
                </BodyCopy>
            ),
            text,
        )}
        {body}
        {onSubmit && <Button buttonStyle="primary" text={submitTitle} onClick={onSubmit} rightIcon="rightArrow" />}
    </MidColumn>
);

export default Intro;
