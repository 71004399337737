import { ProxyAddress } from 'proxyaddress-common/types';
import { useContext } from 'react';
import AuthContext from '../WithAuth/AuthContext';
import { AdminStateContext } from '../WithAdminState/adminState';
import { StaffUserContext } from '../WithStaffUser/staffContext';

const GetProxyAddressByUuid = (proxyAddressUuid?: string): ProxyAddress | undefined => {
    const { isAdmin } = useContext(AuthContext);
    const { proxyAddresses: adminProxyAddresses } = useContext(AdminStateContext);
    const { proxyAddresses: staffProxyAddresses } = useContext(StaffUserContext);
    const proxyAddresses = isAdmin ? adminProxyAddresses : staffProxyAddresses;

    return proxyAddressUuid ? proxyAddresses[proxyAddressUuid] : undefined;
};

export default GetProxyAddressByUuid;
