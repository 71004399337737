import * as t from 'io-ts';
import { omit } from 'ramda';

export const AdminUser = t.type({
    adminUuid: t.string,
    name: t.string,
    createdAt: t.string,
    createdBy: t.string,
    email: t.string,
});

export type AdminUser = t.TypeOf<typeof AdminUser>;

export const CreateAdminUserArgs = t.type(omit(['createdAt', 'adminUuid'], AdminUser.props));

export type CreateAdminUserArgs = t.TypeOf<typeof CreateAdminUserArgs>;
