import styled from 'styled-components';
import { echo } from '../../../theme/sizing';
import React, { ReactElement, ReactNode } from 'react';
import { title2 } from '../../../theme/cssSnippets';

interface TabHeadingProps {
    title: string;
    children?: ReactNode;
}

const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${echo};

    h3 {
        ${title2}
    }
`;

const TabHeading = ({ title, children }: TabHeadingProps): ReactElement => (
    <Container>
        <h3>{title}</h3>
        {children}
    </Container>
);

export default TabHeading;
