import { COMPLETE, DETAILS, ID_VERIFICATION, INTRO } from 'proxyaddress-common/constants';
import React, { ReactElement, useContext } from 'react';
import ApplicationComplete from '../patterns/ApplicationComplete';
import ApplicationDetails from '../patterns/ApplicationDetails';
import ApplicationIdVerification from '../patterns/ApplicationIdVerification';
import ApplicationIntro from '../patterns/ApplicationIntro';
import ApplicationProgressIndicator from '../patterns/ApplicationProgressIndicator';
import Header from '../patterns/Header';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import PageWrapper from '../bits/Layout/PageWrapper';
import MidColumn from '../bits/Layout/MidColumn';

const Application = (): ReactElement => {
    const { currentStage } = useContext(ApplicantContext);

    const showStage = () => {
        switch (currentStage) {
            case DETAILS:
                return <ApplicationDetails />;
            case ID_VERIFICATION:
                return <ApplicationIdVerification />;
            case COMPLETE:
                return <ApplicationComplete />;
            case INTRO:
            default:
                return <ApplicationIntro />;
        }
    };

    return (
        <>
            <Header negative />
            <PageWrapper>
                <ApplicationProgressIndicator currentStage={currentStage} />
                <MidColumn>{showStage()}</MidColumn>
            </PageWrapper>
        </>
    );
};

export default Application;
