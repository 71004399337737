import styled from 'styled-components/macro';

export interface IconSvgProps {
    fill?: string;
    width?: string;
    height?: string;
    size?: string;
}
export const IconSvg = styled.svg.attrs<IconSvgProps>(({ width, height, size }) => ({
    width: size || width || 24,
    height: size || height || 24,
}))`
    fill: ${({ fill }) => (fill ? fill : 'currentColor')};
`;
