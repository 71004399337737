import { createContext } from 'react';
import { ApplicantWithStaffDetails, ApplicantTab } from 'proxyaddress-common/types';

export interface ApplicantsUserState {
    setNewApplication: (newApplication: boolean) => void;
    newApplication: boolean;
    applicants: Record<string, ApplicantWithStaffDetails>;
    currentApplicant: ApplicantWithStaffDetails | undefined;
    setCurrentApplicant: (applicant: ApplicantWithStaffDetails | undefined) => void;
    currentApplicantTab: ApplicantTab;
    setCurrentApplicantTab: (tabWindow: ApplicantTab) => void;
}

export const ApplicantsContext = createContext<ApplicantsUserState>({} as ApplicantsUserState);
