import styled from 'styled-components';
import { maxWidth } from '../../../theme/sizing';

const PageWrapper = styled.div`
    margin: 0 auto 1rem auto;
    max-width: ${maxWidth};
    padding: 0 1rem;
`;

export default PageWrapper;
