import React, { ReactElement } from 'react';
import SignIn from './SignIn';
import NarrowColumn from '../bits/Layout/NarrowColumn';
import { SmallNotification } from '../bits/Notification/Notification';
import { warning } from '../../theme/colours';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import { SUPPORT_EMAIL } from '../../utils/constants';

interface AuthLandingProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange: (authState: string, data?: any) => void;
    isApplicant: boolean;
}

const AuthLanding = ({ isApplicant, ...props }: AuthLandingProps): ReactElement => {
    return (
        <NarrowColumn>
            {isApplicant ? (
                <SignIn isApplicant={isApplicant} {...props} />
            ) : (
                <>
                    <SignIn isApplicant={isApplicant} {...props} />
                    <SmallNotification borderColor={warning}>
                        <BodyCopy>
                            <p>
                                <b>Don't have an account?</b>{' '}
                                <a href={`mailto:${SUPPORT_EMAIL}`}>Contact ProxyAddress support</a> to check
                                availability in your area.
                            </p>
                        </BodyCopy>
                    </SmallNotification>
                </>
            )}
        </NarrowColumn>
    );
};

export default AuthLanding;
