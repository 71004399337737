import styled from 'styled-components';
import { charlie, echo, golf } from '../../../theme/sizing';
import { brandA } from '../../../theme/colours';
import { body2 } from '../../../theme/cssSnippets';

interface NotificationProps {
    borderColor?: string;
}

const Notification = styled.div<NotificationProps>`
    border: solid ${charlie} ${(props) => props.borderColor || brandA};
    margin-bottom: ${golf};
    padding: ${echo};

    > * + * {
        margin-top: ${charlie};
    }

    * {
        max-width: 100%;
    }

    p,
    a {
        ${body2}
    }
`;

export const SmallNotification = styled(Notification)`
    border-width: 2px;
    margin-top: 2rem;
    padding: 1rem;

    p,
    a,
    b,
    button {
        font-size: 0.8rem;
    }

    button {
        margin-top: 1rem;
    }
`;

export default Notification;
