import styled from 'styled-components';
import { foxtrot, golf } from '../../../theme/sizing';

const DetailsWrapper = styled.div`
    margin-bottom: ${golf};

    > * + * {
        margin-top: ${foxtrot};
    }
`;

export default DetailsWrapper;
