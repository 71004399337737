import React, { ReactElement, useCallback, useState } from 'react';
import { IdStage } from '../../utils/application';
import IdVerificationIntro from './IdVerification/IdIntro';
import IdPhoto from './IdVerification/IdPhoto';
import IdResult from './IdVerification/IdResult';

export interface IdStagesProps {
    setIdStage: (stage: IdStage) => void;
}

const ApplicationIdVerification = (): ReactElement => {
    const [idStage, setIdStage] = useState<IdStage>(IdStage.ID_INTRO);

    const updateIdStage = useCallback((stage: IdStage) => setIdStage(stage), []);

    const showStage = () => {
        switch (idStage) {
            case 'ID_PHOTO':
                return <IdPhoto setIdStage={updateIdStage} />;
            case 'ID_RESULT':
                return <IdResult />;
            case 'ID_INTRO':
            default:
                return <IdVerificationIntro setIdStage={updateIdStage} />;
        }
    };

    return showStage();
};

export default ApplicationIdVerification;
