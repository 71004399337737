import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ApplicantContext } from '../../WithApplicant/applicantContext';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';
import { createYotiSession } from '../../../graphql/apis';
import { updateApplication } from '../../../graphql/application';
import { ActivityLogInput, EditApplicationArgs, idStage, YotiSession } from 'proxyaddress-common/types';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import { CHECK_STARTED } from 'proxyaddress-common/constants';
import { getApplicationUpdateFields } from '../../../utils/users';
import { omit } from 'ramda';
import Loading from '../../bits/Loading';

interface YotiProps {
    onComplete: (completed: boolean) => void;
    setCheckError: (error: boolean) => void;
}

const YotiWrapper = styled.div`
    height: 605px;
    max-width: 100%;
    position: relative;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
`;

const Yoti = ({ onComplete, setCheckError }: YotiProps) => {
    const { applicant } = useContext(ApplicantContext);
    const applicationUuid = applicant.application?.applicationUuid || '';
    const [currentYotiSession, setCurrentYotiSession] = useState<YotiSession | undefined>(undefined);

    const { data, error, loading } = createYotiSession.hook(
        { applicationUuid },
        { skip: !applicationUuid, fetchPolicy: 'no-cache' },
    );
    const yotiSession = data?.createYotiSession;

    useEffect(() => {
        if (yotiSession) {
            setCurrentYotiSession(yotiSession);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yotiSession]);

    const [updateApplicationMutation] = updateApplication.hook();

    const updateApplicationWithYotiSession = async () => {
        if (applicant.application && currentYotiSession) {
            const existingSessions = applicant.application?.yotiSessions;
            const yotiSessions = existingSessions ? [...existingSessions, currentYotiSession] : [currentYotiSession];
            const yotiSessionsInput = yotiSessions.map((session) => omit(['__typename'], session));

            const activityLogEntry: ActivityLogInput = {
                applicantUuid: applicant.applicantUuid,
                orgUuid: applicant.orgUuid,
                logType: CHECK_STARTED,
                createdBy: applicant.staffMember,
                details: {
                    ...defaultActivityLogDetails,
                    checkId: currentYotiSession?.sessionId,
                },
            };

            const variables: EditApplicationArgs = {
                application: {
                    ...getApplicationUpdateFields(applicant.application),
                    progress: idStage,
                    yotiSessions: yotiSessionsInput,
                },
                activityLogEntry,
                sendRetryEmail: false,
            };

            const application = await updateApplicationMutation({
                variables,
            });

            if (application) {
                onComplete(true);
            } else {
                setCheckError(true);
            }
        } else {
            setCheckError(true);
        }
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data.eventType === 'SUCCESS') {
                updateApplicationWithYotiSession();
            } else if (event.data.eventType === 'ERROR') {
                setCheckError(true);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentYotiSession]);

    if (!applicationUuid) {
        return (
            <ErrorMessage>
                <p>Application UUID not found</p>
            </ErrorMessage>
        );
    }

    if (loading) return <Loading />;
    if (error) return <ErrorMessage>{error.message}</ErrorMessage>;

    return (
        <YotiWrapper>
            <iframe
                title="Yoti"
                src={`https://api.yoti.com/idverify/v1/web/index.html?sessionID=${yotiSession?.sessionId}&sessionToken=${yotiSession?.clientSessionToken}`}
                style={{ border: 'none' }}
                allow="camera"
            ></iframe>
        </YotiWrapper>
    );
};

export default Yoti;
