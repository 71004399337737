import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { shade100, brandA, brandB } from '../../theme/colours';
import Button from '../bits/Buttons/Button';
import FullLogo from './FullLogo';
import AuthContext from '../WithAuth/AuthContext';
import { echo, maxWidth } from '../../theme/sizing';

export interface HeaderProps {
    menu?: ReactElement;
    userName?: string;
    negative?: boolean;
    showSignOut?: boolean;
}

interface WrapperProps {
    negative: boolean;
}

const Wrapper = styled.div<WrapperProps>`
    background-color: ${(props) => (props.negative ? brandB : shade100)};
    margin-bottom: 1.25rem;
`;

const Inner = styled.div`
    align-items: center;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    max-width: ${maxWidth};
    padding: 0 1.5rem;

    @media screen and (max-width: 750px) {
        padding: 0 ${echo};
    }
`;

const LogoWrapper = styled.div`
    margin-bottom: 0.5rem;
    margin-right: 1rem;
    margin-top: 0.5rem;
`;

const Menu = styled.div`
    align-self: flex-end;
    display: flex;
`;

interface UserInfoProps {
    negative: boolean;
}

const UserInfo = styled.div<UserInfoProps>`
    margin-left: auto;

    button {
        color: ${(props) => (props.negative ? 'white' : brandA)};
    }
`;

const Header = ({ menu, negative = false, showSignOut = true, ...props }: HeaderProps): ReactElement => {
    const { currentAuthenticatedUser, signOut } = useContext(AuthContext);
    return (
        <Wrapper negative={negative}>
            <Inner>
                <LogoWrapper>
                    <FullLogo theme={negative ? 'negative' : 'primary'} />
                </LogoWrapper>
                {menu && <Menu>{menu}</Menu>}

                {currentAuthenticatedUser && (
                    <UserInfo negative={negative}>
                        <Button buttonStyle="link" text="Sign Out" onClick={() => signOut()} />
                    </UserInfo>
                )}
            </Inner>
        </Wrapper>
    );
};

export default Header;
