import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { blue600, brandA, contentTertiary } from '../../../theme/colours';
import { charlie, echo } from '../../../theme/sizing';
import { title2, title3 } from '../../../theme/cssSnippets';

export interface BodyCopyProps {
    color?: string;
    children: ReactNode;
    textAlign?: 'left' | 'center' | 'right';
}

interface StyledBodyCopyProps {
    color?: string;
    textAlign?: 'left' | 'center' | 'right';
}

export const StyledBodyCopy = styled.div<StyledBodyCopyProps>`
    * {
        color: ${(props) => props.color || contentTertiary};
        font-size: ${echo};
        max-width: 70ch;
        text-align: ${(props) => props.textAlign || 'left'};
    }
    * + * {
        margin-top: ${charlie};
    }
    ol,
    ul {
        margin-left: 1.4em;
        li + li {
            margin-top: ${charlie};
        }
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    a {
        color: ${brandA};
        text-decoration: underline;
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }
    b,
    strong {
        font-weight: bold;
        color: ${blue600};
    }

    h2,
    h3 {
        font-weight: bold;
    }

    h2 {
        font-size: ${title2};
    }

    h3 {
        font-size: ${title3};
    }
`;

const BodyCopy = ({ children, textAlign, ...props }: BodyCopyProps): ReactElement => (
    <StyledBodyCopy textAlign={textAlign} {...props}>
        {children}
    </StyledBodyCopy>
);

export default BodyCopy;
