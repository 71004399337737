import { Address, ApplicantAddress } from 'proxyaddress-common/types';
import React, { ReactElement, useState } from 'react';
import AddressDetail from '../../bits/Details/AddressDetail';
import Detail from '../../bits/Details/Detail';
import DetailsWrapper from '../../bits/DetailPage/DetailsWrapper';
import Button from '../../bits/Buttons/Button';
import TabHeading from '../../bits/TabDetail/TabHeading';
import AssignProxyAddressModal from '../AssignProxyAddress/AssignProxyAddressModal';

interface ApplicantProxyAddressDetailsProps {
    address?: Address | ApplicantAddress[];
    pin?: string;
    hideTitle?: boolean;
}

const ApplicantProxyAddressDetails = ({
    address,
    pin,
    hideTitle = false,
}: ApplicantProxyAddressDetailsProps): ReactElement => {
    const [showEditModal, setShowEditModal] = useState(false);

    return (
        <>
            {!hideTitle && (
                <TabHeading title="ProxyAddress Details">
                    {!showEditModal && (
                        <Button buttonStyle="secondary" text="Edit" onClick={() => setShowEditModal(true)} />
                    )}
                </TabHeading>
            )}
            <DetailsWrapper>
                <AddressDetail address={address} caption="Assigned ProxyAddress" />
                <Detail detail={pin} caption="ProxyAddress Identification Number (PIN)" />
            </DetailsWrapper>

            {showEditModal && <AssignProxyAddressModal open={showEditModal} setOpen={setShowEditModal} />}
        </>
    );
};

export default ApplicantProxyAddressDetails;
