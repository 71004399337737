import React, { useContext } from 'react';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import { filterActiveProxyAddresses } from '../../../utils/proxyAddress';
import { AdminStateContext } from '../../WithAdminState/adminState';
import ProxyAddresses from '../ProxyAddresses/ProxyAddresses';

interface AssignProxyAddressProps {
    assignedProxyAddressUuid?: string;
    setAssignedProxyAddressUuid: (arg: string) => void;
}

const AssignProxyAddress = ({ assignedProxyAddressUuid, setAssignedProxyAddressUuid }: AssignProxyAddressProps) => {
    const { proxyAddresses: adminProxyAddresses } = useContext(AdminStateContext);
    const { proxyAddresses: staffProxyAddresses } = useContext(StaffUserContext);
    const filteredAddresses = filterActiveProxyAddresses(adminProxyAddresses || staffProxyAddresses);

    return (
        <ProxyAddresses
            addresses={filteredAddresses}
            assignedProxyAddressUuid={assignedProxyAddressUuid}
            setAssignedProxyAddressUuid={setAssignedProxyAddressUuid}
        />
    );
};

export default AssignProxyAddress;
