import React from 'react';
import styled from 'styled-components';
import { blue600 } from '../../theme/colours';

const StyledSpinner = styled.svg`
    height: 50px;
    width: 50px;
`;

interface SpinnerProps {
    color?: string;
}

const Loading = ({ color = blue600, ...props }: SpinnerProps) => (
    <StyledSpinner viewBox="0 0 58 58" {...props}>
        <g fill="none" fillRule="evenodd">
            <g transform="translate(2 1)" stroke={color} strokeWidth="1.5">
                <circle cx="42.601" cy="11.462" r="5" fillOpacity="1" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="1;0;0;0;0;0;0;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="49.063" cy="27.063" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;1;0;0;0;0;0;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="42.601" cy="42.663" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;0;1;0;0;0;0;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="27" cy="49.125" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;0;0;1;0;0;0;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="11.399" cy="42.663" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;0;0;0;1;0;0;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="4.938" cy="27.063" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;0;0;0;0;1;0;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="11.399" cy="11.462" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;0;0;0;0;0;1;0"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
                <circle cx="27" cy="5" r="5" fillOpacity="0" fill={color}>
                    <animate
                        attributeName="fill-opacity"
                        begin="0s"
                        dur="1.3s"
                        values="0;0;0;0;0;0;0;1"
                        calcMode="linear"
                        repeatCount="indefinite"
                    />
                </circle>
            </g>
        </g>
    </StyledSpinner>
);

const FullPageWrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;

    svg {
        width: 4rem;
        height: 4rem;
    }
`;

export const PageLoading = () => (
    <FullPageWrapper>
        <Loading />
    </FullPageWrapper>
);

export default Loading;
