import React, { ReactElement, useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminNavigation from '../patterns/AdminNavigation';
import CreateOrganisation from '../patterns/CreateOrganisation';
import CreateProxyAddress from '../patterns/CreateProxyAddress';
import CreateStaffUser from '../patterns/CreateStaffUser';
import OrganisationAdmin from '../patterns/OrganisationAdmin';
import OrganisationDetails from './OrganisationDetails';
import StaffAdmin from '../patterns/StaffAdmin';
import CouncilAreas from '../patterns/CouncilAreas';
import CouncilAreaDetails from '../patterns/CouncilAreaDetails';
import UploadCouncilAreas from '../patterns/UploadCouncilAreas';
import ProxyAddressAdmin from '../patterns/ProxyAddressAdmin';
import ProxyAddressDetails from '../patterns/ProxyAddressDetails';
import AuthContext from '../WithAuth/AuthContext';
import ErrorMessage from '../bits/ErrorMessage/ErrorMessage';
import EditOrganisationDetails from '../patterns/EditOrganisationDetails';
import Applicants from './Applicants';
import { WithApplicantsContext } from '../WithApplicants/WithApplicantsContext';
import {
    ROUTE_ADDRESS_DETAIL,
    ROUTE_ADDRESSES,
    ROUTE_ADDRESSES_NEW,
    ROUTE_ADMIN,
    ROUTE_APPLICANT_DETAIL,
    ROUTE_APPLICANTS,
    ROUTE_APPLICATION_NEW,
    ROUTE_COUNCIL_AREA_DETAIL,
    ROUTE_COUNCIL_AREAS,
    ROUTE_COUNCIL_AREAS_UPLOAD,
    ROUTE_ORGANISATION_DETAIL,
    ROUTE_ORGANISATION_EDIT,
    ROUTE_ORGANISATIONS,
    ROUTE_ORGANISATIONS_NEW,
    ROUTE_STAFF,
    ROUTE_STAFF_NEW,
} from '../../utils/routes';
import ApplicantDetails from '../patterns/ApplicantDetails/ApplicantDetails';
import CreateApplicant from '../patterns/CreateApplicant';

const Admin = (): ReactElement => {
    const { isAdmin } = useContext(AuthContext);

    if (!isAdmin) {
        return <ErrorMessage>Permission denied</ErrorMessage>;
    }

    return (
        <AdminNavigation>
            <Switch>
                <Route exact path={ROUTE_ADMIN}>
                    <Redirect to={ROUTE_ORGANISATIONS} />
                </Route>

                <Route exact path={ROUTE_ORGANISATIONS} component={OrganisationAdmin} />

                <Route path={ROUTE_ORGANISATIONS_NEW} component={CreateOrganisation} />

                <Route path={ROUTE_ORGANISATION_EDIT} component={EditOrganisationDetails} />
                <Route path={ROUTE_ORGANISATION_DETAIL} component={OrganisationDetails} />

                <Route path={ROUTE_STAFF_NEW} component={CreateStaffUser} />

                <Route path={ROUTE_STAFF} component={StaffAdmin} />

                <Route exact path={ROUTE_COUNCIL_AREAS} component={CouncilAreas} />
                <Route path={ROUTE_COUNCIL_AREAS_UPLOAD} component={UploadCouncilAreas} />
                <Route path={ROUTE_COUNCIL_AREA_DETAIL} component={CouncilAreaDetails} />

                <Route exact path={ROUTE_ADDRESSES} component={ProxyAddressAdmin} />

                <Route path={ROUTE_ADDRESSES_NEW} component={CreateProxyAddress} />

                <Route path={ROUTE_ADDRESS_DETAIL} component={ProxyAddressDetails} />

                <WithApplicantsContext>
                    <Route path={ROUTE_APPLICATION_NEW} component={CreateApplicant} />
                    <Route path={ROUTE_APPLICANT_DETAIL} component={ApplicantDetails} />
                    <Route exact path={ROUTE_APPLICANTS} component={Applicants} />
                </WithApplicantsContext>
            </Switch>
        </AdminNavigation>
    );
};

export default Admin;
