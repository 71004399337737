import { values } from 'ramda';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { GridWrapper } from '../../utils/grid';
import Button from '../bits/Buttons/Button';
import { AdminStateContext } from '../WithAdminState/adminState';
import DashboardButtonSearch from './DashboardButtonSearch';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import AuthContext from '../WithAuth/AuthContext';
import { getRouteProxyAddressNew } from '../../utils/routes';
import { useParams } from 'react-router-dom';
import ProxyAddresses from './ProxyAddresses/ProxyAddresses';

interface ProxyAddressAdminProps {
    councilAreaUuid?: string;
}

const ProxyAddressAdmin = ({ councilAreaUuid: councilAreaUuidParam }: ProxyAddressAdminProps) => {
    const history = useHistory();
    const { isAdmin } = useContext(AuthContext);
    const { proxyAddresses: adminProxyAddresses } = useContext(AdminStateContext);
    const { orgUuid }: { orgUuid: string } = useParams();
    const { proxyAddresses: staffProxyAddresses, organisation } = useContext(StaffUserContext);
    const proxyAddressesRecord = isAdmin ? adminProxyAddresses : staffProxyAddresses;

    const councilAreaUuid = councilAreaUuidParam || organisation?.councilAreaUuids[0];

    const proxyAddresses = values(proxyAddressesRecord);
    const filteredProxyAddresses = councilAreaUuid
        ? proxyAddresses.filter((proxyAddress) => proxyAddress.councilAreaUuid === councilAreaUuid)
        : proxyAddresses;

    const createButton = (
        <Button
            buttonStyle="primary"
            text="+ New ProxyAddress"
            onClick={() => {
                history.push(getRouteProxyAddressNew(councilAreaUuid || '', orgUuid || organisation?.orgUuid || ''));
            }}
        />
    );

    return (
        <GridWrapper>
            <DashboardButtonSearch
                title="ProxyAddresses"
                body={!!councilAreaUuid && isAdmin ? createButton : undefined}
            />
            <ProxyAddresses addresses={filteredProxyAddresses} />
        </GridWrapper>
    );
};

export default ProxyAddressAdmin;
