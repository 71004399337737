import { ADMIN_USER_GROUP, APPLICANT_USER_GROUP, STAFF_USER_GROUP } from 'proxyaddress-common/constants/users';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import requireAuthentication from './components/authentication/requireAuthentication';
import ResetPassword from './components/authentication/ResetPassword';
import Admin from './components/templates/Admin';
import ApplicantPage from './components/templates/ApplicantPage';
import CheckPage from './components/templates/CheckPage';
import LandingPage from './components/templates/LandingPage';
import Staff from './components/templates/Staff';
import { WithAdminState } from './components/WithAdminState/WithAdminState';
import { WithApplicant } from './components/WithApplicant';
import AuthContext from './components/WithAuth/AuthContext';
import { WithStaffContext } from './components/WithStaffUser/WithStaffContext';
import { ROUTE_CHECK, ROUTE_RESET_PASSWORD, ROUTE_ROOT } from './utils/routes';

export const App = (): React.ReactElement => {
    const { currentAuthenticatedUser, userType } = useContext(AuthContext);

    let userRoutes;

    switch (userType) {
        case ADMIN_USER_GROUP:
            userRoutes = (
                <WithAdminState>
                    <Route>{requireAuthentication(Admin)}</Route>
                </WithAdminState>
            );
            break;
        case STAFF_USER_GROUP:
            userRoutes = (
                <WithStaffContext>
                    <Route>{requireAuthentication(Staff)}</Route>
                </WithStaffContext>
            );
            break;
        case APPLICANT_USER_GROUP:
            userRoutes = (
                <WithApplicant>
                    <Route>{requireAuthentication(ApplicantPage)}</Route>
                </WithApplicant>
            );
            break;
        default:
            userRoutes = <Route>{requireAuthentication(LandingPage)}</Route>;
    }

    return (
        <Switch>
            <Route exact path={ROUTE_ROOT} component={LandingPage} />
            <Route exact path={ROUTE_RESET_PASSWORD} component={ResetPassword} />
            <Route exact path={ROUTE_CHECK} component={CheckPage} />
            {currentAuthenticatedUser && userType ? userRoutes : <Route>{requireAuthentication(LandingPage)}</Route>}
        </Switch>
    );
};
