import React, { FormEvent, useContext, useState } from 'react';
import { golf } from '../../theme/sizing';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import IconButton from '../bits/Buttons/IconButton';
import TextInput from '../bits/FormFields/TextInput';
import AuthContext from '../WithAuth/AuthContext';
import AmplifyBase from './AmplifyBase';

interface RequireNewPasswordProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onStateChange: (authState: string, data?: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authData: any; // as aws docs: https://aws-amplify.github.io/amplify-js/api/interfaces/iauthenticatorprops.html#authdata
}

const RequireNewPassword = ({ onStateChange, authData: user }: RequireNewPasswordProps) => {
    const { completeNewPassword } = useContext(AuthContext);
    const [error, setError] = useState<{ message: string } | undefined>();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const changePassword = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (password.length === 0) return setError({ message: 'Password is required' });

        completeNewPassword(user, password, setError);
    };

    const showHideButton = (
        <IconButton
            icon={showPassword ? 'hide' : 'show'}
            size={golf}
            offset
            onClick={() => setShowPassword(!showPassword)}
        />
    );

    const body = (
        <>
            <BodyCopy>
                <p>Please enter a new password to sign in</p>
            </BodyCopy>
            <TextInput
                label="Password"
                id="password"
                placeholder="New Password"
                onChange={({ target }) => setPassword(target.value)}
                value={password}
                helperText={'Use 8 or more characters with a mix of letters and numbers'}
                type={showPassword ? 'text' : 'password'}
                button={showHideButton}
            />
        </>
    );

    return (
        <AmplifyBase
            heading="Complete Registration"
            body={body}
            submitLabel="Sign In"
            onSubmit={(e) => changePassword(e)}
            cancelLabel="Cancel"
            onCancel={() => onStateChange('signIn')}
            error={error}
        />
    );
};

export default RequireNewPassword;
