import { completeStage } from 'proxyaddress-common/types';
import React, { ReactElement, useContext } from 'react';
import { ApplicantContext } from '../../WithApplicant/applicantContext';
import Confirmation from '../Confirmation';

const IdResult = (): ReactElement => {
    const { updateApplicationStage } = useContext(ApplicantContext);
    const introText = [
        'You will be notified when these have been verified or if there are any issues.',
        'You may continue with the ProxyAddress registration process',
    ];

    return (
        <Confirmation
            text={introText}
            title="Documents successfully uploaded"
            buttonAction={() => {
                updateApplicationStage(completeStage);
            }}
            buttonTitle="Continue >"
        />
    );
};

export default IdResult;
