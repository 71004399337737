import { gql } from '@apollo/client';
import wrapQuery from './wrapQuery';
import { YotiSession, YotiResult, YotiImage } from 'proxyaddress-common/types/yoti';

// Create the session
interface CreateYotiSessionData {
    createYotiSession: YotiSession;
}

export const YotiSessionType = `{
    clientSessionToken
    sessionId
}`;

export interface CreateYotiSessionParams {
    applicationUuid: string;
}

export const createYotiSessionQuery = gql`
    query createYotiSession($applicationUuid: String!) {
        createYotiSession(applicationUuid: $applicationUuid) ${YotiSessionType}
    }
`;

export const createYotiSession = wrapQuery<CreateYotiSessionParams, CreateYotiSessionData>(createYotiSessionQuery);

// Get the results
interface GetYotiSessionData {
    getYotiSession: YotiResult;
}

const YotiDetailsResponseType = `{
    name
    value
}`;

const YotiRecommendationResponseType = `{
    value
    reason
    recoverySuggestion
}`;

const YotiBreakdownResponseType = `{
    subCheck
    result
    details ${YotiDetailsResponseType}
}`;

const YotiReportResponseType = `{
    recommendation ${YotiRecommendationResponseType}
    breakdown ${YotiBreakdownResponseType}
}`;

const YotiCheckType = `{
    id
    type
    state
    resourcesUsed
    report ${YotiReportResponseType}
    created
    lastUpdated
}`;

const YotiIdDocumentsResourceResponseType = `{
    id
    documentType
    issuingCountry
}`;

const YotiTextExtractionType = `{
    documentId
    documentText
}`;

const YotiImageType = `{
    content
    mimeType
}`;

const YotiResourceContainerType = `{
    idDocuments ${YotiIdDocumentsResourceResponseType}
    textExtractions ${YotiTextExtractionType}
}`;

export const YotiSessionResultType = `{
    sessionId
    userTrackingId
    state
    checks ${YotiCheckType}
    resources ${YotiResourceContainerType}
}`;

export interface GetYotiSessionParams {
    sessionId: string;
}

export const getYotiSessionQuery = gql`
    query getYotiSession($sessionId: String!) {
        getYotiSession(sessionId: $sessionId) ${YotiSessionResultType}
    }
`;

export const getYotiSession = wrapQuery<GetYotiSessionParams, GetYotiSessionData>(getYotiSessionQuery);

export interface GetYotiImagesParams {
    sessionId: string;
    documentId: string;
}

interface GetYotiImagesData {
    getYotiImages: YotiImage[];
}

export const getYotiImagesQuery = gql`
    query getYotiImages($sessionId: String!, $documentId: String!) {
        getYotiImages(sessionId: $sessionId, documentId: $documentId) ${YotiImageType}
    }
`;

export const getYotiImages = wrapQuery<GetYotiImagesParams, GetYotiImagesData>(getYotiImagesQuery);
