import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { contentSecondary } from '../../theme/colours';
import { foxtrot, golf } from '../../theme/sizing';
import { getRouteOrganisationEdit, ROUTE_ORGANISATIONS } from '../../utils/routes';
import Button from '../bits/Buttons/Button';
import IconButton from '../bits/Buttons/IconButton';
import ContentTabs from '../bits/ContentTabs/ContentTabs';
import Detail from '../bits/Details/Detail';
import DashboardButtonSearch from '../patterns/DashboardButtonSearch';
import { AdminStateContext } from '../WithAdminState/adminState';
import ProxyAddressAdmin from '../patterns/ProxyAddressAdmin';
import StaffAdmin from '../patterns/StaffAdmin';
import Applicants from './Applicants';
import { WithApplicantsContext } from '../WithApplicants/WithApplicantsContext';

const DetailsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${golf};
    margin-bottom: ${golf};
    width: 100%;
`;

export enum OrgDetailTabs {
    applicants = 'Applicants',
    addresses = 'Addresses',
    staff = 'Staff',
}

const OrganisationDetails = (): ReactElement => {
    const history = useHistory();
    const { organisations, currentOrganisation, setCurrentOrganisation, councilAreas } = useContext(AdminStateContext);
    const { orgUuid }: { orgUuid: string } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab') as OrgDetailTabs;
    const [activeTab, setActiveTab] = useState<OrgDetailTabs>(tab || OrgDetailTabs.applicants);

    useEffect(() => {
        // sets org on refresh
        setCurrentOrganisation(organisations[orgUuid]);

        return () => {
            setCurrentOrganisation(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisations]);

    const body = (
        <Button buttonStyle="secondary" text="Edit" onClick={() => history.push(getRouteOrganisationEdit(orgUuid))} />
    );

    const redirectToOrgAdmin = () => {
        setCurrentOrganisation(undefined);
        history.push(ROUTE_ORGANISATIONS);
    };

    const navButton = (
        <IconButton icon="backArrow" size={foxtrot} fill={contentSecondary} onClick={redirectToOrgAdmin} />
    );

    const councilArea = councilAreas[currentOrganisation?.councilAreaUuids[0] || ''];

    return (
        <div>
            <DashboardButtonSearch
                title={currentOrganisation?.name}
                status={currentOrganisation?.status || ''}
                body={body}
                navButton={navButton}
            />

            <DetailsWrapper>
                {councilArea && <Detail caption="Council area" detail={councilArea.name} />}
                <Detail caption="Main contact" detail={currentOrganisation?.subscriptionEmail || '-'} />
                <Detail caption="Partners" detail={currentOrganisation?.partners?.join(', ')} />
            </DetailsWrapper>

            <ContentTabs
                activeTab={activeTab}
                setActiveTab={setActiveTab as (arg: string) => void}
                tabs={Object.values(OrgDetailTabs)}
            >
                {activeTab === OrgDetailTabs.applicants && (
                    <WithApplicantsContext orgUuid={orgUuid}>
                        <Applicants />
                    </WithApplicantsContext>
                )}
                {activeTab === OrgDetailTabs.staff && <StaffAdmin />}
                {activeTab === OrgDetailTabs.addresses && (
                    <ProxyAddressAdmin councilAreaUuid={currentOrganisation?.councilAreaUuids[0]} />
                )}
            </ContentTabs>
        </div>
    );
};

export default OrganisationDetails;
