import React, { ReactElement, useState } from 'react';
import { CouncilAreaOutput, Region } from 'proxyaddress-common/types';
import TextInput from '../bits/FormFields/TextInput';
import SelectInput from '../bits/FormFields/SelectInput';
import {
    councilAreaRegionOptions,
    councilAreaStatusOptions,
    EditCouncilAreaDetailsErrors,
    editCouncilAreaDetailsInitialErrors,
    formatRegion,
    formatRegionOutput,
    validateEditCouncilAreaDetails,
} from '../../utils/councilAreas';
import { Status } from 'proxyaddress-common/constants';
import DetailsWrapper from '../bits/DetailPage/DetailsWrapper';
import Button from '../bits/Buttons/Button';
import { updateCouncilArea } from '../../graphql/councilArea';
import Form from '../bits/Form/Form';
import { isEmpty, values } from 'ramda';

interface EditCouncilAreaDetailsProps {
    councilArea: CouncilAreaOutput;
    setEditMode: (arg: boolean) => void;
}

const EditCouncilAreaDetails = ({ councilArea, setEditMode }: EditCouncilAreaDetailsProps): ReactElement => {
    const [updateCouncilAreaMutation, { loading, error: updateError }] = updateCouncilArea.hook();
    const [details, setDetails] = useState<CouncilAreaOutput>(councilArea);
    const [errors, setErrors] = useState<EditCouncilAreaDetailsErrors>(editCouncilAreaDetailsInitialErrors);

    const submitUpdates = async () => {
        const validationResult = validateEditCouncilAreaDetails(details);

        if (values(validationResult).every(isEmpty)) {
            const updatedProxyAddress = await updateCouncilAreaMutation({
                variables: {
                    councilArea: {
                        councilAreaUuid: councilArea.councilAreaUuid,
                        name: details.name,
                        region: formatRegionOutput(details.region),
                        councilStatus: details.councilStatus,
                    },
                },
                refetchQueries: ['listCouncilAreas'],
            });

            if (updatedProxyAddress.data) {
                setEditMode(false);
            }
        } else {
            setErrors(validationResult);
        }
    };

    const formBody = (
        <DetailsWrapper>
            <TextInput
                id="name"
                label="Name"
                defaultValue={details.name}
                onChange={(e) => setDetails({ ...details, name: e.target.value })}
                error={errors.nameError}
            />
            <SelectInput
                id="region"
                label="Region"
                defaultValue={formatRegionOutput(details.region)}
                options={councilAreaRegionOptions}
                onChange={(e) => setDetails({ ...details, region: formatRegion(e.target.value as Region) })}
                error={errors.regionError}
            />
            <SelectInput
                label="Status"
                id="status"
                width="15rem"
                defaultValue={councilArea.councilStatus}
                options={councilAreaStatusOptions}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setDetails({
                        ...details,
                        councilStatus: e.target.value as Status,
                    })
                }
                error={errors.statusError}
            />
            <Button buttonStyle="primary" text="Save changes" type="submit" />
        </DetailsWrapper>
    );

    return (
        <Form
            onCancel={() => setEditMode(false)}
            body={formBody}
            loading={loading}
            error={!!updateError}
            onSubmit={submitUpdates}
        />
    );
};

export default EditCouncilAreaDetails;
