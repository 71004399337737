import * as t from 'io-ts';
import { omit } from 'ramda';
import optionalToUndefined from '../t/optionalToUndefined';
import * as c from '../constants';

export const Status = t.union([t.literal(c.ACTIVE), t.literal(c.INACTIVE)]);

export type Status = t.TypeOf<typeof Status>;

export const OrgType = t.union([t.literal(c.COUNCIL), t.literal(c.CHARITY), t.literal(c.OTHER)]);

export type OrgType = t.TypeOf<typeof OrgType>;

export const Org = t.type({
    orgUuid: t.string,
    name: t.string,
    councilAreaUuids: t.array(t.string),
    createdAt: t.string,
    createdBy: t.string,
    status: Status,
    orgType: OrgType,
    applicationCredits: optionalToUndefined(t.number),
    serviceCredits: optionalToUndefined(t.number),
    subscriptionEmail: optionalToUndefined(t.string),
    subscriptionExpiry: optionalToUndefined(t.string),
    modifiedAt: optionalToUndefined(t.string),
    partners: optionalToUndefined(t.array(t.string)),
});

export type Org = t.TypeOf<typeof Org>;

export const CreateOrgArguments = t.type(
    omit(
        ['applicationCredits', 'serviceCredits', 'subscriptionExpiry', 'createdAt', 'orgUuid', 'modifiedAt'],
        Org.props,
    ),
);
export type CreateOrgArguments = t.TypeOf<typeof CreateOrgArguments>;
