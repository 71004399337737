import React, { ReactElement } from 'react';
import styled from 'styled-components';
import BackButton from '../Buttons/BackButton';
import { Title2 } from '../Titles/titles';
import { charlie, echo } from '../../../theme/sizing';
import { Icon, IconOptions } from '../Icons';

export const DetailPageTitleWrapper = styled.div`
    margin-bottom: ${echo};

    > * {
        margin-bottom: ${charlie};
    }
`;

const Header = styled.div`
    align-items: center;
    display: flex;
    gap: 0.5rem;
`;

interface DetailPageTitleProps {
    icon?: IconOptions;
    title: string;
    onBackButtonClick?: () => void;
    backButtonLabel?: string;
}

const DetailPageTitle = ({
    icon,
    title,
    onBackButtonClick,
    backButtonLabel,
    ...props
}: DetailPageTitleProps): ReactElement => (
    <DetailPageTitleWrapper {...props}>
        <Header>
            {icon && <Icon icon={icon} size="2rem" />}
            <Title2 as="h1">{title}</Title2>
        </Header>
        {onBackButtonClick && <BackButton label={backButtonLabel} onClick={onBackButtonClick} />}
    </DetailPageTitleWrapper>
);

export default DetailPageTitle;
