import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../bits/Buttons/Button';
import SearchSelectInput, { InputOption } from '../../bits/FormFields/SearchSelectInput';
import SelectInput, { InputOptions } from '../../bits/FormFields/SelectInput';
import TextInput from '../../bits/FormFields/TextInput';
import { values } from 'ramda';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { OrganisationErrors, statusOptions } from '../../../utils/organisation';
import { IOrganisationInput } from '../../../graphql/organisation';
import { Status } from 'proxyaddress-common/types';
import Fieldset from '../../bits/Form/Fieldset';
import Legend from '../../bits/Form/Legend';
import IconButton from '../../bits/Buttons/IconButton';
import { constructive, destructive } from '../../../theme/colours';

interface OrganisationDetailsFormProps {
    errors: OrganisationErrors;
    newOrgInput: IOrganisationInput;
    setNewOrgInput: (newOrgInput: IOrganisationInput) => void;
}

const PartnerRow = styled.div`
    align-items: flex-end;
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;

    > div {
        flex-grow: 1;
        max-width: 20rem;
    }

    button {
        flex-basis: 30px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-bottom: 0.4rem;
        margin-top: 0;
        right: 0;
    }
`;

const OrganisationDetailsForm = ({ errors, newOrgInput, setNewOrgInput }: OrganisationDetailsFormProps) => {
    const { councilAreas } = useContext(AdminStateContext);
    const [councilAreaOptions, setCouncilAreaOptions] = useState<InputOptions[]>([]);
    const [newPartner, setNewPartner] = useState<string>('');

    useEffect(() => {
        const councilAreaOptionsMap = values(councilAreas).map((area) => {
            return { value: area.councilAreaUuid, label: area.name };
        });

        setCouncilAreaOptions(councilAreaOptionsMap);
    }, [councilAreas]);

    const addPartner = () => {
        if (newPartner) {
            setNewOrgInput({
                ...newOrgInput,
                partners: newOrgInput.partners ? [...newOrgInput.partners, newPartner] : [newPartner],
            });
            setNewPartner('');
        }
    };

    const editPartner = (partner: string, index: number) => {
        setNewOrgInput({ ...newOrgInput, partners: newOrgInput.partners.map((p, i) => (i === index ? partner : p)) });
    };

    const removePartner = (partner: string) => {
        setNewOrgInput({ ...newOrgInput, partners: newOrgInput.partners.filter((p) => p !== partner) });
    };

    return (
        <>
            <TextInput
                label="Organisation Name"
                id="name"
                value={newOrgInput?.name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewOrgInput({ ...newOrgInput, name: e.target.value })
                }
                error={errors?.nameError}
            />
            <SelectInput
                options={statusOptions}
                label="Status"
                id="status"
                value={newOrgInput?.status}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setNewOrgInput({ ...newOrgInput, status: e.target.value as Status })
                }
                error={errors?.statusError}
            />
            <SearchSelectInput
                options={councilAreaOptions}
                label="Council area"
                id="councilArea"
                value={councilAreaOptions.find((area) => newOrgInput?.councilAreaUuids?.includes(area.value))}
                onChange={(options) => {
                    if (Array.isArray(options)) {
                        setNewOrgInput({ ...newOrgInput, councilAreaUuids: options.map((option) => option.value) });
                    } else {
                        setNewOrgInput({ ...newOrgInput, councilAreaUuids: [(options as InputOption)?.value] });
                    }
                }}
                error={errors?.councilError}
                isMulti={false}
                isClearable={false}
            />
            <TextInput
                label="Main contact email address (optional)"
                id="contactEmail"
                value={newOrgInput?.subscriptionEmail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setNewOrgInput({ ...newOrgInput, subscriptionEmail: e.target.value.trim() })
                }
            />

            <Fieldset>
                <Legend>Partners</Legend>
                {/* Accepting that indexes aren't necessarily the best option but for this proof of concept happy to go with the trade-off - 
                ideally Partners would be its own db object, eliminating the need for this array anyway */}
                {newOrgInput?.partners?.map((partner, index) => (
                    <PartnerRow key={index}>
                        <TextInput
                            label={`Partner ${index + 1}`}
                            id={`partner-${index}`}
                            value={partner}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => editPartner(e.target.value, index)}
                            hideLabel
                        />
                        <IconButton fill={destructive} icon="cross" onClick={() => removePartner(partner)} />
                    </PartnerRow>
                ))}
                <PartnerRow>
                    <TextInput
                        label="Add Partner"
                        id="newPartner"
                        value={newPartner}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPartner(e.target.value)}
                    />
                    <IconButton fill={constructive} icon="addCircle" onClick={() => addPartner()} />
                </PartnerRow>
            </Fieldset>
            <Button buttonStyle="primary" text="Save" type="submit" />
        </>
    );
};

export default OrganisationDetailsForm;
