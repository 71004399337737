import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Button from '../Buttons/Button';
import { shade600 } from '../../../theme/colours';

interface FileInputProps {
    id: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    disabled?: boolean;
}

const FileLabel = styled.p`
    color: ${shade600};
    font-size: 0.8rem;
`;

const Hidden = styled.div`
    display: none;
`;

const FileInput = ({ id, onChange, error, disabled }: FileInputProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setFileName(file.name);
        }
        onChange(event);
    };

    return (
        <div>
            <Button
                buttonStyle="secondary"
                text="Upload file"
                onClick={() => fileInputRef.current?.click()}
                disabled={disabled}
            />
            {fileName && <FileLabel>{fileName}</FileLabel>}

            <Hidden>
                <label htmlFor={id}>Upload file</label>
                <input ref={fileInputRef} id={id} multiple={false} type="file" onChange={(e) => handleChange(e)} />
            </Hidden>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </div>
    );
};

export default FileInput;
