import styled from 'styled-components';
import { foxtrot } from '../../../theme/sizing';
import { shade600 } from '../../../theme/colours';

const TwoColumnLayout = styled.div`
    display: flex;
    justify-content: center;
    gap: ${foxtrot};

    > * {
        flex-grow: 0;
        flex-shrink: 1;
    }

    @media screen and (min-width: 751px) {
        align-items: flex-start;

        > * {
            flex-basis: 50%;
        }

        > div:first-child > * {
            margin-right: 0;
        }

        > div:last-child > * {
            margin-left: 0;
        }

        > * + * {
            border-left: 1px solid ${shade600};
            padding-left: ${foxtrot};
        }
    }

    @media screen and (max-width: 750px) {
        align-items: center;
        flex-direction: column-reverse;

        > * {
            flex-basis: 100%;
            width: 100%;
        }

        > * + * {
            border-bottom: 1px solid ${shade600};
            padding-bottom: ${foxtrot};
        }
    }
`;

export default TwoColumnLayout;
