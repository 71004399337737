export const alpha = '0.125rem';
export const bravo = '0.25rem';
export const charlie = '0.5rem';
export const delta = '0.75rem';
export const echo = '1rem';
export const foxtrot = '1.5rem';
export const golf = '2rem';
export const hotel = '2.5rem';
export const india = '3rem';
export const juliet = '4rem';
export const kilo = '8rem';

export const maxWidth = '1200px';
