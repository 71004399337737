import { ACTIVE, COUNCIL } from 'proxyaddress-common/constants';
import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { createOrganisation, IOrganisationInput } from '../../graphql/organisation';
import { OrganisationErrors, OrganisationInitialErrors, validateOrganisationInput } from '../../utils/organisation';
import { getCurrentUserId } from '../../utils/users';
import Form from '../bits/Form/Form';
import { AdminStateContext } from '../WithAdminState/adminState';
import AuthContext from '../WithAuth/AuthContext';
import OrganisationDetailsForm from './Organisation/OrganisationDetailsForm';
import { getRouteOrganisationDetail, ROUTE_ORGANISATIONS } from '../../utils/routes';

const initialOrganisationInput: IOrganisationInput = {
    name: '',
    councilAreaUuids: [''],
    createdBy: '',
    status: ACTIVE,
    orgType: COUNCIL,
    subscriptionEmail: '',
    partners: [],
};

const CreateOrganisation = (): ReactElement => {
    const history = useHistory();
    const { currentAuthenticatedUser: user } = useContext(AuthContext);
    const { refetchData } = useContext(AdminStateContext);
    const [errors, setErrors] = useState<OrganisationErrors>(OrganisationInitialErrors);
    const [createOrganisationMutation, { loading, error: CreateOrganisationError }] = createOrganisation.hook();
    const [newOrgInput, setNewOrgInput] = useState<IOrganisationInput>(initialOrganisationInput);

    const validateAndCreateOrganisation = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const variables = {
            organisation: {
                ...newOrgInput,
                createdBy: getCurrentUserId(user),
            },
        };
        const validationResult = validateOrganisationInput(variables);

        if (values(validationResult).every(isEmpty)) {
            const organisation = await createOrganisationMutation({
                variables,
                refetchQueries: ['listOrganisations'],
            });

            // PXA-377 issue with onCompleted not firing
            if (organisation.data?.createOrganisation) {
                // issue with cache updating but not rerendering https://github.com/apollographql/apollo-client/issues/5963
                refetchData();
                history.push(getRouteOrganisationDetail(organisation.data.createOrganisation.orgUuid));
            }
        } else {
            setErrors(validationResult);
        }
    };

    return (
        <Form
            title="New organisation"
            description="Use this form to add a new organisation"
            body={<OrganisationDetailsForm errors={errors} newOrgInput={newOrgInput} setNewOrgInput={setNewOrgInput} />}
            onCancel={() => history.push(ROUTE_ORGANISATIONS)}
            onSubmit={validateAndCreateOrganisation}
            cancelTitle="Cancel and discard changes"
            loading={loading}
            error={!!CreateOrganisationError}
        />
    );
};

export default CreateOrganisation;
