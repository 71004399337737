import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { editOrganisation, IOrganisationInput } from '../../graphql/organisation';
import { OrganisationErrors, OrganisationInitialErrors, validateOrganisationInput } from '../../utils/organisation';
import Form from '../bits/Form/Form';
import { AdminStateContext } from '../WithAdminState/adminState';
import OrganisationDetailsForm from './Organisation/OrganisationDetailsForm';
import { isEmpty, omit, values } from 'ramda';
import { getRouteOrganisationDetail } from '../../utils/routes';

const EditOrganisationDetails = (): ReactElement => {
    const history = useHistory();
    const { orgUuid }: { orgUuid: string } = useParams();
    const { refetchData, organisations } = useContext(AdminStateContext);
    const [errors, setErrors] = useState<OrganisationErrors>(OrganisationInitialErrors);
    const [editOrganisationMutation, { loading, error: editOrgError }] = editOrganisation.hook();
    const [newOrgInput, setNewOrgInput] = useState<IOrganisationInput>(organisations[orgUuid] as IOrganisationInput);

    const organisation = organisations[orgUuid];

    const validateAndUpdateOrganisation = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        // validate newOrgInput
        const removedCreatedBy = omit(['createdBy'], newOrgInput);
        const updatedOrg = { ...organisation, ...removedCreatedBy };

        const validationResult = validateOrganisationInput({ organisation: newOrgInput });

        if (values(validationResult).every(isEmpty)) {
            const organisation = await editOrganisationMutation({
                variables: {
                    organisation: omit(['__typename'], updatedOrg),
                },
                refetchQueries: ['listOrganisations'],
            });

            // PXA-377 issue with onCompleted not firing
            if (organisation.data?.editOrganisation) {
                // issue with cache updating but not rerendering https://github.com/apollographql/apollo-client/issues/5963
                refetchData();
                history.push(getRouteOrganisationDetail(orgUuid));
            }
        } else {
            setErrors(validationResult);
        }
    };

    return (
        <Form
            title="Edit organisation"
            description="Use this form to edit this organisation"
            body={<OrganisationDetailsForm errors={errors} newOrgInput={newOrgInput} setNewOrgInput={setNewOrgInput} />}
            onCancel={() => history.push(getRouteOrganisationDetail(orgUuid))}
            onSubmit={validateAndUpdateOrganisation}
            cancelTitle="Cancel and discard changes"
            loading={loading}
            error={!!editOrgError}
            errorMessage={editOrgError?.message}
        />
    );
};

export default EditOrganisationDetails;
