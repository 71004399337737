import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Icon } from '../bits/Icons';
import { ReactComponent as ProxyAddressNegative } from '../bits/Icons/Logo/ProxyAddressNegative.svg';
import { ReactComponent as ProxyAddressPositive } from '../bits/Icons/Logo/ProxyAddressPositive.svg';

interface LogoWrapperProps {
    theme: 'primary' | 'negative';
}

const LogoWrapper = styled.div`
    align-items: center;
    display: flex;
    width: 200px;

    svg + svg {
        margin-left: 0.5rem;
    }
`;

const FullLogo = ({ theme }: LogoWrapperProps): ReactElement => {
    return (
        <LogoWrapper>
            <Icon icon={theme === 'negative' ? 'logoNegative' : 'logoPrimary'} size="2.2rem" />
            {theme === 'negative' ? <ProxyAddressNegative /> : <ProxyAddressPositive />}
        </LogoWrapper>
    );
};

export default FullLogo;
