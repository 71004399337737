import React, { ReactElement, useContext } from 'react';
import { ApplicantContext } from '../WithApplicant/applicantContext';

const ApplicationComplete = (): ReactElement => {
    const { applicant } = useContext(ApplicantContext);
    console.log({ applicant });

    return <div>Application Complete</div>;
};

export default ApplicationComplete;
