import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { parse, ParseError } from 'papaparse';
import Button from '../bits/Buttons/Button';
import ErrorMessage from '../bits/ErrorMessage/ErrorMessage';
import { checkCsvInput, checkMissingCouncilAreas } from '../../utils/councilAreas';
import { isNil, values } from 'ramda';
import { CouncilAreaInput, DroppedLocalFile, FileError } from 'proxyaddress-common/types';
import { createCouncilAreas } from '../../graphql/councilArea';
import { AdminStateContext } from '../WithAdminState/adminState';
import Loading from '../bits/Loading';

const UploadCouncilAreas = () => {
    const { councilAreas, refetchCouncilAreas } = useContext(AdminStateContext);
    const [csvFile, setCsvFile] = useState<DroppedLocalFile>();
    const [councilAreaInput, setCouncilAreaInput] = useState<CouncilAreaInput[]>([]);
    const [errors, setErrors] = useState<ParseError[] | FileError[]>([]);
    const [success, setSuccess] = useState<boolean>();
    const [createCouncilAreasMutation, { loading: createLoading, error: createError }] = createCouncilAreas.hook();

    useEffect(() => {
        if (createError) {
            setErrors([{ message: createError.message }]);
        }
    }, [createError]);

    useEffect(() => {
        if (councilAreaInput.length > 0 && councilAreas) {
            const missingCouncilAreas = checkMissingCouncilAreas({
                councilAreasInput: councilAreaInput,
                returnedCouncilAreas: values(councilAreas),
            });
            if (missingCouncilAreas.length > 0) {
                setSuccess(false);
                setErrors([
                    { message: `The following council areas failed to upload: ${missingCouncilAreas.join(', ')}` },
                ]);
            } else {
                setSuccess(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [councilAreas]);

    const onDrop = useCallback((acceptedFiles) => {
        parse(acceptedFiles[0], {
            header: true,
            dynamicTyping: true,
            complete: function (results) {
                if (results.errors) setErrors(results.errors);
                if (results.data) {
                    try {
                        const checkedCouncilAreaInput = checkCsvInput(results.data);
                        setCouncilAreaInput(checkedCouncilAreaInput);
                    } catch (error) {
                        setErrors([{ message: 'File incorrectly formatted' }]);
                    }
                }
            },
        });
        setCsvFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const uploadFiles = async () => {
        await createCouncilAreasMutation({ variables: { councilAreaInput: councilAreaInput } });
        refetchCouncilAreas();
    };

    return (
        <>
            <p>Select a file or drag and drop files here</p>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? <p>Drop the files here ...</p> : <button>Choose file</button>}
            </div>

            {csvFile && (
                <>
                    <p>{csvFile?.name}</p>
                    <Button
                        buttonStyle="primary"
                        text="Upload file"
                        disabled={errors?.length > 0 || isNil(councilAreas) || createLoading}
                        onClick={uploadFiles}
                    />
                    {createLoading && <Loading />}
                    {success && <p>All council areas successfully uploaded</p>}
                    {errors &&
                        errors.map((error: ParseError | FileError, index: number) => {
                            return <ErrorMessage key={index}>{error.message}</ErrorMessage>;
                        })}
                </>
            )}
        </>
    );
};

export default UploadCouncilAreas;
