import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { AmplifyConfig, ICredentials } from '@aws-amplify/core/lib-esm/types';
import { ADMIN_USER_GROUP, APPLICANT_USER_GROUP, STAFF_USER_GROUP } from 'proxyaddress-common/constants/users';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getCurrentUserOrganisation, getCurrentUserType } from '../../utils/users';
import { PageLoading } from '../bits/Loading';
import { ConfigContext } from '../WithConfig/config';
import AuthContext, { AppUser, AuthContextValue, CognitoError } from './AuthContext';
import { ROUTE_ADMIN_ROOT, ROUTE_APPLICANT_ROOT, ROUTE_SIGN_IN, ROUTE_STAFF_ROOT } from '../../utils/routes';

export const WithAuth = (props: { children?: React.ReactNode }): ReactElement => {
    const config = useContext(ConfigContext);
    const history = useHistory();
    const [currentCredentials, setCurrentCredentials] = useState<ICredentials>();
    const [currentAuthenticatedUser, setCurrentAuthenticatedUser] = useState<AppUser>();
    const [userType, setUserType] = useState<string>();
    const [organisation, setOrganisation] = useState<string>();
    let location = useLocation();

    const updateCurrentUserDetails = async () => {
        try {
            const user: AppUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
            const newUserType = getCurrentUserType(user);
            setCurrentAuthenticatedUser(user);
            const { pathname } = location;

            if (pathname === ROUTE_SIGN_IN) {
                switch (newUserType) {
                    case APPLICANT_USER_GROUP:
                        history.push(ROUTE_APPLICANT_ROOT);
                        break;
                    case STAFF_USER_GROUP:
                        history.push(ROUTE_STAFF_ROOT);
                        break;
                    case ADMIN_USER_GROUP:
                        history.push(ROUTE_ADMIN_ROOT);
                        break;
                    default:
                        history.push(pathname);
                }
            }
        } catch (error) {
            if (error === 'The user is not authenticated') {
                setCurrentAuthenticatedUser(undefined);
            } else {
                throw error;
            }
        }

        const credentials = await Auth.currentUserCredentials();
        setCurrentCredentials(credentials);
    };

    useEffect(() => {
        const amplifyConfig: AmplifyConfig = {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Auth: {
                region: 'eu-west-2',
                identityPoolId: config.identityPoolId,
                userPoolWebClientId: config.userPoolClientId,
                userPoolId: config.userPoolId,
            },
        };
        Amplify.configure(amplifyConfig);

        updateCurrentUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentAuthenticatedUser) {
            setUserType(getCurrentUserType(currentAuthenticatedUser));
            setOrganisation(getCurrentUserOrganisation(currentAuthenticatedUser));
        } else {
            setUserType('');
            setOrganisation('');
        }
    }, [currentAuthenticatedUser]);

    const signIn = async (email: string, password: string): Promise<AppUser> => {
        const user = await Auth.signIn(email, password);
        await updateCurrentUserDetails();
        return user;
    };

    const signOut = async () => {
        await Auth.signOut();
        setCurrentAuthenticatedUser(undefined);
        history.push(ROUTE_SIGN_IN);
    };

    const completeNewPassword = async (
        user: AppUser,
        newPassword: string,
        setError: (error: { message: string }) => void,
    ): Promise<void> => {
        try {
            await Auth.completeNewPassword(user, newPassword);
            await updateCurrentUserDetails();
        } catch (err) {
            const error = err as CognitoError;
            setError({ message: error.message });
        }
    };

    const forgotPassword = async (email: string) => {
        await Auth.forgotPassword(email);
    };

    const changePassword = async (email: string, code: string, newPassword: string): Promise<void> => {
        await Auth.forgotPasswordSubmit(email, code, newPassword);
    };

    if (!currentCredentials) {
        return <PageLoading />;
    }

    const providerValue: AuthContextValue = {
        signOut,
        signIn,
        completeNewPassword,
        forgotPassword,
        changePassword,
        currentCredentials,
        currentAuthenticatedUser,
        userType,
        organisation,
        isAdmin: userType === ADMIN_USER_GROUP,
    };

    return <AuthContext.Provider value={providerValue}>{props.children}</AuthContext.Provider>;
};
