import { completeStage, detailsStage, EditApplicationArgs } from 'proxyaddress-common/types';
import React, { ReactElement, useContext } from 'react';
import { IdStage } from '../../../utils/application';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import Intro from '../../bits/Intro/Intro';
import List from '../../bits/List/List';
import { Title2, Title3 } from '../../bits/Titles/titles';
import { ApplicantContext } from '../../WithApplicant/applicantContext';
import { IdStagesProps } from '../ApplicationIdVerification';
import Button from '../../bits/Buttons/Button';
import Notification from '../../bits/Notification/Notification';
import { warning } from '../../../theme/colours';
import { getApplicationUpdateFields } from '../../../utils/users';
import { updateApplication } from '../../../graphql/application';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';

const IdVerificationIntro = ({ setIdStage }: IdStagesProps): ReactElement => {
    const { updateApplicationStage, applicant } = useContext(ApplicantContext);
    const [updateApplicationMutation, { loading }] = updateApplication.hook();
    const [updateError, setUpdateError] = React.useState<string | undefined>();

    const skipDocumentCheck = async () => {
        if (applicant.application) {
            const variables: EditApplicationArgs = {
                application: {
                    ...getApplicationUpdateFields(applicant.application),
                    progress: completeStage,
                    skippedIdCheck: true,
                },
                activityLogEntry: undefined,
                sendRetryEmail: false,
            };

            const application = await updateApplicationMutation({
                variables,
            });

            if (!application?.data) {
                setUpdateError('There was a problem updating your application. Please try again');
            }
        }
    };

    const introText = [
        'Photo ID verification helps us combat identity theft by checking your document is valid, and that it is you providing your details and not someone pretending to be you. Your information and images are securely encrypted and uploaded for analysis by Yoti.',
        //TODO Needs link adding
        'Read more about photo ID verification',
    ];

    const body = (
        <>
            <Title2>How to complete this step</Title2>
            <Title3>You will need the following to complete this step</Title3>
            <List>
                <li>A valid passport or national identity card</li>
                <li>A device that allows you to take a photograph of your identity documents and yourself</li>
                <li>A pen and paper to write down a unique code that you will show in your photo</li>
            </List>
            <BodyCopy>
                <p>
                    <strong>
                        If you do not have these right now, you can close this window and save your progress, then log
                        in again to complete your application
                    </strong>
                </p>
            </BodyCopy>
        </>
    );

    return (
        <>
            <Intro
                text={introText}
                title="Photo ID verification"
                onCancel={() => updateApplicationStage(detailsStage)}
                cancelTitle="Back to details"
                onSubmit={() => {
                    setIdStage(IdStage.ID_PHOTO);
                }}
                submitTitle="Start"
                body={body}
            />
            <Notification borderColor={warning}>
                <Title3>No ID?</Title3>
                <BodyCopy>
                    <p>
                        If you do not any valid form of photographic ID, you may skip the document check and submit your
                        application. A member of staff will then be in touch to discuss your application.
                    </p>
                </BodyCopy>
                <Button buttonStyle="secondary" onClick={() => skipDocumentCheck()} text="Skip" disabled={loading} />
                {updateError && <ErrorMessage>{updateError}</ErrorMessage>}
            </Notification>
        </>
    );
};

export default IdVerificationIntro;
