import { IconOptions } from '../components/bits/Icons';
import { constructive, destructive, warning } from '../theme/colours';

const APPROVE = 'APPROVE';
const PASS = 'PASS';
const REJECT = 'REJECT';
const FAIL = 'FAIL';

// Yoti recommendationValues are not typed

export const getRecommendationIcon = (recommendationValue: string): IconOptions => {
    let iconValue: IconOptions;

    switch (recommendationValue) {
        case APPROVE:
        case PASS:
            iconValue = 'tick';
            break;
        case REJECT:
        case FAIL:
            iconValue = 'cross';
            break;
        default:
            iconValue = 'question';
            break;
    }

    return iconValue;
};

export const getRecommendationColor = (recommendationValue: string): string => {
    let color = warning;

    switch (recommendationValue) {
        case APPROVE:
        case PASS:
            color = constructive;
            break;
        case REJECT:
        case FAIL:
            color = destructive;
            break;
        default:
    }

    return color;
};
