import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { WithApollo } from './components/WithApollo';
import { WithAuth } from './components/WithAuth';
import { WithConfig } from './components/WithConfig';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './theme/globalStyle';

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyle />
        <BrowserRouter>
            <WithConfig>
                <WithAuth>
                    <WithApollo>
                        <App />
                    </WithApollo>
                </WithAuth>
            </WithConfig>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
