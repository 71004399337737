import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { borderSelected, red400 } from '../../../theme/colours';
import { label1, body1, cssVisuallyHidden } from '../../../theme/cssSnippets';
import { alpha, foxtrot } from '../../../theme/sizing';
import { StyledCaption } from '../Caption/Caption';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface FormFieldProps {
    children: ReactNode;
    error?: string;
    hideLabel?: boolean;
}

const cssErrorStyles = css`
    label {
        color: ${red400} !important;
    }

    input,
    select,
    textarea {
        border-color: ${red400} !important;
    }
`;

const StyledFormField = styled.div<FormFieldProps>`
    display: flex;
    flex-direction: column;
    + * {
        margin-top: ${foxtrot};
    }
    ,
    label {
        ${label1};
        color: ${borderSelected};
    }
    input,
    select,
    textarea {
        appearance: none;
        background-color: transparent;
        border: ${alpha} solid ${borderSelected};
        ${body1};
        &::placeholder {
            opacity: 0.6;
            padding-left: 0.2rem;
        }
    }
    input[type='text'],
    input[type='email'],
    input[type='password'],
    select,
    textarea {
        width: 100%;
    }
    ${StyledCaption} {
        margin-top: ${alpha};
        opacity: 0.6;
    }
    ${ErrorMessage} {
        opacity: 1;
        text-align: left;
    }
    ${(props) =>
        props.hideLabel &&
        `
        label {
            ${cssVisuallyHidden};
        }
    `}

    ${(props) => (props.error ? cssErrorStyles : '')}
`;

const FormField = ({ children, error, ...props }: FormFieldProps): ReactElement => (
    <StyledFormField error={error} {...props}>
        {children}
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledFormField>
);

export default FormField;
