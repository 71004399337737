import { Address, ProxyAddressOptionsInput } from 'proxyaddress-common/types';
import { equals } from 'ramda';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddressErrors, addressInitialState } from '../../../utils/addresses';
import { ApplicantAddressErrors } from '../../../utils/application';
import { countries } from '../../../utils/countries';
import { ProxyAddressErrors } from '../../../utils/proxyAddress';
import Button from '../Buttons/Button';
import SearchSelectInput, { InputOption } from '../FormFields/SearchSelectInput';
import TextInput from '../FormFields/TextInput';
import AddressSearch from './AddressSearch';

export interface AddressWithCountry extends Address {
    country?: string;
}

interface AddressFormProps {
    address: AddressWithCountry;
    setAddress: (address: AddressWithCountry) => void;
    proxyAddressDetails?: ProxyAddressOptionsInput;
    setProxyAddressDetails?: (args: ProxyAddressOptionsInput) => void;
    errors: ProxyAddressErrors | ApplicantAddressErrors | AddressErrors;
    disableManualInput?: boolean;
    allowCountry?: boolean;
}
const Wrapper = styled.div`
    margin-bottom: 1.5rem;
    width: 100%;

    p {
        margin: 0.5rem;
        margin-left: 0.3rem;
    }

    button {
        margin-right: 0.3rem;
    }
`;

const AddressForm = ({
    address,
    setAddress,
    proxyAddressDetails,
    setProxyAddressDetails,
    errors,
    disableManualInput,
    allowCountry,
}: AddressFormProps) => {
    const [manualEnter, setManualEnter] = useState(false);

    useEffect(() => {
        const shouldPrefill = !equals(address, addressInitialState);
        if (shouldPrefill) {
            setManualEnter(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            {manualEnter && (
                <>
                    <TextInput
                        label="House name"
                        id="houseName"
                        value={address.houseName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, houseName: e.target.value })
                        }
                    />
                    <TextInput
                        label="House number"
                        id="houseNumber"
                        value={address.houseNumber}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, houseNumber: e.target.value })
                        }
                    />
                    <TextInput
                        label="Street"
                        id="streetName"
                        value={address.streetName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, streetName: e.target.value })
                        }
                    />
                    <TextInput
                        label="Town or city"
                        id="town"
                        value={address.town}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, town: e.target.value })
                        }
                        error={errors.townError}
                    />
                    <TextInput
                        label={allowCountry ? 'County/State (2 letter state abbreviation for US addresses)' : 'County'}
                        id="county"
                        value={address.county}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, county: e.target.value })
                        }
                        error={allowCountry ? errors.stateError : undefined}
                    />
                    <TextInput
                        label={allowCountry ? 'Postcode/ZIP code' : 'Postcode'}
                        id="postcode"
                        value={address.postcode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setAddress({ ...address, postcode: e.target.value })
                        }
                        error={errors.postcodeError}
                    />
                    {allowCountry && (
                        <SearchSelectInput
                            label="Country"
                            id="country"
                            options={countries}
                            value={countries.find((country) => country.label === address.country)}
                            defaultValue={{ value: 'GBR', label: 'United Kingdom' }}
                            onChange={(input) => {
                                if (!input) {
                                    setAddress({ ...address, country: '' });
                                    return;
                                }
                                const country = input as InputOption;
                                setAddress({ ...address, country: country.value });
                            }}
                            isMulti={false}
                            isClearable={false}
                        />
                    )}
                </>
            )}

            {!manualEnter && (
                <AddressSearch
                    address={address}
                    setAddress={setAddress}
                    proxyAddressDetails={proxyAddressDetails}
                    setProxyAddressDetails={setProxyAddressDetails}
                    setManualEnter={setManualEnter}
                    disableManualInput={disableManualInput}
                />
            )}

            {!disableManualInput && manualEnter && (
                <Button buttonStyle="secondary" onClick={() => setManualEnter(false)} text={'Search by postcode'} />
            )}
        </Wrapper>
    );
};

export default AddressForm;
