import { APPROVED, CLOSED_WITHOUT_DECISION, REJECTED } from 'proxyaddress-common/constants';
import React, { ReactElement, useContext } from 'react';
import { getApplicantByPin } from '../../graphql/applicant';
import { constructive, destructive, warning } from '../../theme/colours';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import DetailPageTitle from '../bits/DetailPage/DetailPageTitle';
import ErrorMessage from '../bits/ErrorMessage/ErrorMessage';
import PageWrapper from '../bits/Layout/PageWrapper';
import Notification from '../bits/Notification/Notification';
import PrivateText from '../bits/PrivateText/PrivateText';
import { Results } from '../patterns/Check/CheckResults';
import Header from '../patterns/Header';
import { SUPPORT_EMAIL } from '../../utils/constants';
import { getOrganisation } from '../../graphql/organisation';
import PartnersList from '../patterns/Partners/PartnersList';
import Loading from '../bits/Loading';

const Applicant = (): ReactElement => {
    const { applicant } = useContext(ApplicantContext);
    const isApplicationApproved = applicant.applicationStatus === APPROVED;
    const { data: GetOrganisationData } = getOrganisation.hook({ orgUuid: applicant.orgUuid });
    const organisation = GetOrganisationData?.getOrganisation;

    const {
        data,
        error: queryError,
        loading: queryLoading,
    } = getApplicantByPin.hook(
        {
            pin: applicant.pin,
        },
        {
            skip: !applicant.pin || !isApplicationApproved,
        },
    );

    let appStatusMessage = '';
    let borderColor = '';

    switch (applicant.applicationStatus) {
        case APPROVED:
            borderColor = constructive;
            appStatusMessage = 'Your application has been approved';
            break;
        case REJECTED:
        case CLOSED_WITHOUT_DECISION:
            borderColor = destructive;
            appStatusMessage =
                'Thanks for applying for a ProxyAddress. Unfortunately your application has not been successful.';
            break;
        default:
            borderColor = warning;
            appStatusMessage =
                'Thanks for applying for a ProxyAddress. We are reviewing you application and will be in touch once we have made a decision.';
    }

    return (
        <>
            <Header negative />
            <PageWrapper>
                <DetailPageTitle title={`Hi ${applicant.firstName},`} />
                <Notification borderColor={borderColor}>
                    <BodyCopy>
                        <h3>{appStatusMessage}</h3>
                        <p>
                            If you have any questions or would like to discuss this further please get in touch,{' '}
                            <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
                        </p>
                    </BodyCopy>
                </Notification>
                {isApplicationApproved && (
                    <>
                        {queryLoading && <Loading />}
                        {!queryLoading && data?.getApplicantByPin && (
                            <>
                                <BodyCopy>
                                    <h2>Your ProxyAddress details</h2>

                                    <Results>
                                        <dt>PIN</dt>
                                        <dd>
                                            <PrivateText text={applicant.pin || ''} fullyObscure />
                                        </dd>
                                        {data?.getApplicantByPin.proxyAddress && (
                                            <>
                                                <dt>ProxyAddress</dt>
                                                <dd>
                                                    <PrivateText
                                                        text={data?.getApplicantByPin.proxyAddress}
                                                        fullyObscure
                                                    />
                                                </dd>
                                            </>
                                        )}
                                    </Results>
                                </BodyCopy>
                                <PartnersList partners={organisation?.partners} />
                            </>
                        )}
                    </>
                )}
                {queryError && <ErrorMessage>{queryError.message}</ErrorMessage>}
            </PageWrapper>
        </>
    );
};

export default Applicant;
