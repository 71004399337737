import { Authenticator } from 'aws-amplify-react';
import React, { ComponentType, ReactElement } from 'react';
import CustomAmplify from './AuthState';

const requireAuthentication =
    (Component: ComponentType): ((props: any) => ReactElement) =>
    (props: any) =>
        (
            <Authenticator hideDefault={true}>
                <CustomAmplify>
                    <Component {...props} />
                </CustomAmplify>
            </Authenticator>
        );

export default requireAuthentication;
