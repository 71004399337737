import React, { ReactElement } from 'react';
import Button from './Button';
import styled from 'styled-components';
import { body2 } from '../../../theme/cssSnippets';

const StyledButton = styled(Button)`
    ${body2}
`;

interface BackButtonProps {
    label?: string;
    onClick: () => void;
}

const BackButton = ({ label = 'Back', onClick }: BackButtonProps): ReactElement => (
    <StyledButton buttonStyle="link" onClick={onClick} leftIcon="backArrow" text={label} />
);

export default BackButton;
