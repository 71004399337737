import React, { useContext } from 'react';
import StatusBadge from '../../bits/Grid/StatusBadge';
import styled from 'styled-components';
import IconButton from '../../bits/Buttons/IconButton';
import { useHistory } from 'react-router';
import { getApplicationStatusText } from '../../../utils/application';
import { Title2 } from '../../bits/Titles/titles';
import { contentSecondary } from '../../../theme/colours';
import { getRouteOrganisationDetail, ROUTE_APPLICANTS } from '../../../utils/routes';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import AuthContext from '../../WithAuth/AuthContext';

interface ApplicantHeadingProps {
    name: string;
    status: string | undefined;
}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    h2 {
        margin-right: 0.75rem;
    }
`;

const ApplicantHeading = ({ name, status }: ApplicantHeadingProps) => {
    const history = useHistory();
    const { isAdmin } = useContext(AuthContext);
    const { currentApplicant, setCurrentApplicant } = useContext(ApplicantsContext);
    const orgUuid = currentApplicant?.orgUuid || '';

    const redirectToApplicants = () => {
        setCurrentApplicant(undefined);
        history.push(isAdmin ? getRouteOrganisationDetail(orgUuid) : ROUTE_APPLICANTS);
    };

    return (
        <Wrapper>
            <IconButton icon="backArrow" fill={contentSecondary} onClick={() => redirectToApplicants()} />
            <Title2>{name}</Title2>
            {status && <StatusBadge text={getApplicationStatusText(status)} />}
        </Wrapper>
    );
};

export default ApplicantHeading;
