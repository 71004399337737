import React, { ReactElement, useContext, useState } from 'react';
import Button from '../bits/Buttons/Button';
import Caption from '../bits/Caption/Caption';
import { AdminStateContext } from '../WithAdminState/adminState';
import { useHistory, useParams } from 'react-router-dom';
import DetailPageTitle from '../bits/DetailPage/DetailPageTitle';
import EditCouncilAreaDetails from './EditCouncilAreaDetails';
import Detail from '../bits/Details/Detail';
import DetailsWrapper from '../bits/DetailPage/DetailsWrapper';
import StatusBadge from '../bits/Grid/StatusBadge';
import ButtonsWrapper from '../bits/DetailPage/ButtonsWrapper';
import ErrorPage from '../bits/ErrorMessage/ErrorPage';
import PageWrapper from '../bits/Layout/PageWrapper';
import { ROUTE_COUNCIL_AREAS } from '../../utils/routes';

const EditCouncilArea = (): ReactElement => {
    const history = useHistory();
    const { councilAreaUuid }: { councilAreaUuid: string } = useParams();
    const [editMode, setEditMode] = useState(false);
    const { councilAreas } = useContext(AdminStateContext);
    const councilArea = councilAreas[councilAreaUuid];

    const redirectToCouncilAreaAdmin = () => {
        history.push(ROUTE_COUNCIL_AREAS);
    };

    if (!councilArea)
        return (
            <ErrorPage
                title="Council Area not found"
                message="This council area does not exist"
                buttonAction={redirectToCouncilAreaAdmin}
                buttonLabel="Back to Council Areas"
            />
        );

    return (
        <PageWrapper>
            <DetailPageTitle
                title={councilArea.name}
                backButtonLabel={editMode ? 'Cancel and discard changes' : 'Back to Council Areas'}
                onBackButtonClick={editMode ? () => setEditMode(false) : redirectToCouncilAreaAdmin}
            />

            {editMode ? (
                <EditCouncilAreaDetails councilArea={councilArea} setEditMode={setEditMode} />
            ) : (
                <>
                    <DetailsWrapper>
                        <Detail caption="Name" detail={councilArea.name} />
                        <Detail caption="Region" detail={councilArea.region} />
                        <div>
                            <Caption>Status</Caption>
                            <StatusBadge text={councilArea?.councilStatus} />
                        </div>
                    </DetailsWrapper>
                    <ButtonsWrapper>
                        <Button buttonStyle="primary" text="Edit details" onClick={() => setEditMode(true)} />
                    </ButtonsWrapper>
                </>
            )}
        </PageWrapper>
    );
};

export default EditCouncilArea;
