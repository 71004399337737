import styled from 'styled-components';
import { shade600 } from '../../../theme/colours';
import { delta, foxtrot } from '../../../theme/sizing';

const Fieldset = styled.fieldset`
    border: 1px solid ${shade600};
    display: block;
    margin-bottom: ${foxtrot};
    max-width: 25rem;
    padding: ${delta};
`;

export default Fieldset;
