import React, { ReactElement, useContext } from 'react';
import ApplicantDetails from '../patterns/ApplicantDetails/ApplicantDetails';
import CreateApplicant from '../patterns/CreateApplicant';
import Header from '../patterns/Header';
import Applicants from './Applicants';
import { Route, Switch } from 'react-router-dom';
import Tabs, { TabLink } from '../bits/Tabs/Tabs';
import ProxyAddressAdmin from '../patterns/ProxyAddressAdmin';
import ProxyAddressDetails from '../patterns/ProxyAddressDetails';
import PageWrapper from '../bits/Layout/PageWrapper';
import { WithApplicantsContext } from '../WithApplicants/WithApplicantsContext';
import {
    ROUTE_ADDRESS_DETAIL,
    ROUTE_ADDRESSES,
    ROUTE_ADDRESSES_NEW,
    ROUTE_APPLICANT_DETAIL,
    ROUTE_APPLICANTS,
    ROUTE_APPLICATION_NEW,
    ROUTE_STAFF,
    ROUTE_STAFF_NEW,
} from '../../utils/routes';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import StaffAdmin from '../patterns/StaffAdmin';
import CreateStaffUser from '../patterns/CreateStaffUser';
import CreateProxyAddress from '../patterns/CreateProxyAddress';

const Staff = (): ReactElement => {
    const { organisation } = useContext(StaffUserContext);

    const staffLinks: TabLink[] = [
        {
            link: ROUTE_APPLICANTS,
            label: 'Applicants',
            exact: true,
        },
        {
            link: ROUTE_ADDRESSES,
            label: 'Addresses',
        },
        {
            link: ROUTE_STAFF,
            label: 'Staff',
        },
    ];

    const menu = <Tabs links={staffLinks} />;

    return (
        <>
            <Header menu={menu} />
            <PageWrapper>
                <Switch>
                    <Route path={ROUTE_ADDRESSES_NEW} component={CreateProxyAddress} />
                    <Route path={ROUTE_ADDRESS_DETAIL} component={ProxyAddressDetails} />
                    <Route exact path={ROUTE_ADDRESSES} component={ProxyAddressAdmin} />

                    <Route exact path={ROUTE_STAFF} component={StaffAdmin} />
                    <Route path={ROUTE_STAFF_NEW} component={CreateStaffUser} />

                    <WithApplicantsContext orgUuid={organisation?.orgUuid}>
                        <Route path={ROUTE_APPLICATION_NEW} component={CreateApplicant} />
                        <Route path={ROUTE_APPLICANT_DETAIL} component={ApplicantDetails} />
                        <Route exact path={ROUTE_APPLICANTS} component={Applicants} />
                    </WithApplicantsContext>
                </Switch>
            </PageWrapper>
        </>
    );
};

export default Staff;
