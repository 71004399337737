import React from 'react';
import styled from 'styled-components';
import { GridApi, ColumnApi, ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { GridReadyEvent, ColDef } from '@ag-grid-community/all-modules';
import { label1, body2 } from '../../../theme/cssSnippets';
import {
    bodyBackground,
    shade100,
    shade200,
    brandA,
    brandB,
    blue600,
    shade600,
    shade800,
} from '../../../theme/colours';
import { echo, hotel } from '../../../theme/sizing';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { defaultOptions } from './gridOptions';

const GridWrapper = styled.div`
    flex-grow: 1;
    height: calc(95vh - 200px); // grid needs a height to render
`;

interface GridProps {
    columns: ColDef[];
    rowData?: any; // TODO: remove optional when all views are complete
    gridApi?: GridApi;
    setColumnApi?: (arg0: ColumnApi) => void;
    setGridApi: (arg0: GridApi) => void;
    setRowCount?: (arg0: number) => void;
    frameworkComponent?: object; // TODO: remove optional when all views are complete
}

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const UnstyledGrid = ({
    gridApi,
    frameworkComponent,
    rowData,
    columns,
    setRowCount,
    setGridApi,
    setColumnApi,
    ...props
}: GridProps) => {
    const gridReady = (e: GridReadyEvent) => {
        if (setGridApi) setGridApi(e.api);
        e.api?.sizeColumnsToFit();
        if (setColumnApi) setColumnApi(e.columnApi);
    };

    const checkNoRowsOnFilter = (e: any) => {
        if (e.api.rowModel.rowsToDisplay.length === 0) {
            gridApi?.showNoRowsOverlay();
        } else gridApi?.hideOverlay();
    };

    return (
        <GridWrapper {...props}>
            <div className="ag-theme-alpine ag-container">
                <AgGridReact
                    defaultColDef={defaultOptions}
                    columnDefs={columns}
                    suppressColumnMoveAnimation={true}
                    suppressDragLeaveHidesColumns={true}
                    rowData={rowData || []}
                    onGridReady={gridReady}
                    frameworkComponents={frameworkComponent}
                    onFilterChanged={(e) => checkNoRowsOnFilter(e)}
                ></AgGridReact>
            </div>
        </GridWrapper>
    );
};

const Grid = styled(UnstyledGrid)`
    .ag-container {
        height: 100%;
        width: 100%;
    }
    .ag-root-wrapper {
        border: 0px;
        background-color: ${bodyBackground};
        a,
        a:visited {
            color: ${brandA};
        }
        a:hover {
            color: ${blue600};
        }
    }
    .ag-header {
        border-top: 0px;
        border-bottom: 0.02rem solid ${shade200};
        background-color: ${bodyBackground};
    }
    .ag-header-cell {
        padding-left: 5px;
        padding-right: 5px;
    }
    .ag-header-cell-label {
        display: flex;
        justify-content: start;
        color: ${shade800};
        ${label1}
    }

    .ag-header-cell-resize {
        &:after {
            background-color: transparent;
            height: 100%;
            top: 0;
        }
    }
    .ag-row {
        border: 0px;
        width: 100%;
        border-bottom: 0.01rem solid ${shade200};
        background-color: ${bodyBackground};
    }
    .ag-row-hover {
        background-color: ${shade100};
    }
    .ag-cell {
        ${body2}
        padding-left: 5px;
        line-height: ${hotel};
        svg {
            padding-top: ${echo};
        }
    }
    .ag-input-field {
        input {
            background-color: transparent;
            border: none !important;
            border-bottom: 0.01rem solid ${shade200} !important;
            margin-left: 2px;
            input:focus {
                border: 0.02rem solid ${brandB};
            }
        }
    }
    .ag-icon-filter {
        color: ${shade600};
    }
    .ag-icon-menu {
        display: none;
    }
`;

export default Grid;
