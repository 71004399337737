import React, { ReactElement, useContext } from 'react';
import TabHeading from '../../bits/TabDetail/TabHeading';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import Activity from '../Activity/Activity';
import CreateActivity from '../Activity/CreateActivity';
import styled from 'styled-components';
import { linkColor } from '../../../theme/colours';

const Details = styled.details`
    background-color: white;
    margin-bottom: 1rem;
    padding: 0.5rem;

    summary {
        color: ${linkColor};
        cursor: pointer;
    }

    > form {
        margin-top: 1rem;
    }
`;

const ActivityLogTab = (): ReactElement => {
    const { currentApplicant } = useContext(ApplicantsContext);

    return (
        <>
            <TabHeading title="Account activity" />

            <Details>
                <summary>Add a note</summary>
                <CreateActivity />
            </Details>

            {currentApplicant?.activityLog.map((logEntry) => (
                <Activity key={logEntry.activityLogUuid} activity={logEntry} />
            ))}
        </>
    );
};

export default ActivityLogTab;
