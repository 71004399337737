import { ActivityLogInput, ApplicantWithStaffDetails, SubmitApplicationDecisionArgs } from 'proxyaddress-common/types';
import React, { ReactElement, useContext, useState } from 'react';
import styled from 'styled-components';
import { red400 } from '../../../theme/colours';
import { charlie, foxtrot, india } from '../../../theme/sizing';
import { getApplicationStatusText } from '../../../utils/application';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import Button, { PrimaryButton } from '../../bits/Buttons/Button';
import TextAreaInput from '../../bits/FormFields/TextAreaInput';
import StatusBadge from '../../bits/Grid/StatusBadge';
import { Title2 } from '../../bits/Titles/titles';
import Notification from '../../patterns/Notification';
import Modal from '../../bits/Modal/Modal';
import { submitApplicationDecision } from '../../../graphql/application';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import Loading from '../../bits/Loading';
import Caption from '../../bits/Caption/Caption';
import { APPLICATION_DECISION, REJECTED } from 'proxyaddress-common/constants';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import ButtonsWrapper from '../../bits/DetailPage/ButtonsWrapper';
import AuthContext from '../../WithAuth/AuthContext';
import { ADMIN_USER_NAME } from '../../../utils/constants';

interface RejectApplicationModalProps {
    open: boolean;
    setOpen: (arg: boolean) => void;
    applicant: ApplicantWithStaffDetails;
}

const ApplicationStatusWrapper = styled.div`
    margin-top: ${charlie};
`;

const InputWrapper = styled.div`
    margin: ${foxtrot};
    margin-left: -0.2rem;
`;

const RejectButton = styled(PrimaryButton)`
    background: ${red400};
`;

const RejectApplicationModal = ({ open, setOpen, applicant }: RejectApplicationModalProps): ReactElement => {
    const [rejectionReason, setRejectionReason] = useState('');
    const [submitApplicationDecisionMutation, { loading, error }] = submitApplicationDecision.hook();
    const { staffUser } = useContext(StaffUserContext);
    const { isAdmin } = useContext(AuthContext);

    const rejectApplication = async () => {
        if (applicant.application) {
            const activityLogEntry: ActivityLogInput = {
                applicantUuid: applicant.applicantUuid,
                orgUuid: applicant.orgUuid,
                createdBy: isAdmin ? ADMIN_USER_NAME : staffUser.staffUuid,
                logType: APPLICATION_DECISION,
                details: {
                    ...defaultActivityLogDetails,
                    applicationUuid: applicant.application.applicationUuid,
                    note: `Rejected: ${rejectionReason}`,
                },
            };

            const variables: SubmitApplicationDecisionArgs = {
                closingDecision: REJECTED,
                proxyAddressUuid: undefined,
                councilAreaUuid: undefined,
                activityLogEntry,
            };

            const updatedApplicant = await submitApplicationDecisionMutation({
                variables,
                refetchQueries: ['listApplicantsByOrg'],
            });
            if (updatedApplicant.data) {
                setOpen(false);
            }
        }
    };

    return (
        <Modal clickOutsideToClose open={open} setOpen={setOpen}>
            {loading && <Loading />}
            {!loading && (
                <>
                    <Title2>Reject {applicant.name}'s application?</Title2>

                    {applicant.applicationStatus && (
                        <ApplicationStatusWrapper>
                            <StatusBadge text={getApplicationStatusText(applicant.applicationStatus)} />
                        </ApplicationStatusWrapper>
                    )}

                    <InputWrapper>
                        <TextAreaInput
                            id="rejectionReason"
                            label="Reason for rejecting application (optional)"
                            helperText="This will not be shared with the user at this stage."
                            onChange={(e) => setRejectionReason(e.target.value)}
                            width="100%"
                            height={india}
                            value={rejectionReason}
                        />
                    </InputWrapper>

                    <Notification
                        heading="Important"
                        body={
                            <BodyCopy>
                                <p>
                                    An email explaining that the user's application has been rejected will be sent to{' '}
                                    <b>{applicant.email}</b> along with guidance on next steps.
                                </p>
                                <p>
                                    <b>This action cannot be reverted.</b>
                                </p>
                            </BodyCopy>
                        }
                    />
                    <ButtonsWrapper>
                        <Button
                            buttonStyle="tertiary"
                            text="Cancel"
                            onClick={() => {
                                setOpen(false);
                            }}
                        />
                        <RejectButton onClick={rejectApplication}>Reject application</RejectButton>
                    </ButtonsWrapper>
                    {error && (
                        <Caption color={red400}>
                            <p>{error.message}</p>
                        </Caption>
                    )}
                </>
            )}
        </Modal>
    );
};

export default RejectApplicationModal;
