import React from 'react';
import styled from 'styled-components';
import { Icon, IconOptions } from '../Icons';
import { golf } from '../../../theme/sizing';

interface IconButtonProps {
    icon: IconOptions;
    size?: string;
    width?: string;
    height?: string;
    fill?: string;
    offset?: boolean;
    onClick: () => void;
}

interface ButtonContainerProps {
    offsetIcon?: boolean;
}

const ButtonContainer = styled.button<ButtonContainerProps>`
    position: relative;
    right: 0.5rem;
    margin-top: auto;
    cursor: pointer;
    background: none;
    padding: 0;
    ${(props) => props.offsetIcon && `margin-left: -${golf}`};
`;

const IconButton = ({ icon, fill, size, width, height, offset, onClick }: IconButtonProps) => {
    return (
        <ButtonContainer type="button" offsetIcon={offset} onClick={onClick}>
            <Icon icon={icon} size={size} width={width} height={height} fill={fill} />
        </ButtonContainer>
    );
};

export default IconButton;
