import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { charlie, hotel } from '../../../theme/sizing';
import Caption from '../Caption/Caption';
import { InputWrapper } from './InputWrapper';
import { cssDisabledInput } from '../../../theme/cssSnippets';

interface DateInputFieldProps {
    id: string;
    type?: string;
    preText?: string;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: string;
    hasError?: boolean;
    size?: 'small' | 'large';
    min?: number;
    max?: number;
    disabled?: boolean;
}

const Input = styled.input`
    align-self: flex-start;
    border: none;
    height: ${hotel};
    width: 100%;

    ${cssDisabledInput}
`;

interface StyledInputWrapperProps {
    size?: 'small' | 'large';
}

export const DateInputWrapper = styled(InputWrapper)<StyledInputWrapperProps>`
    width: ${(props) => (props.size === 'small' ? '2.8rem' : '4.5rem')};
    margin-right: ${charlie};
`;

export const DateInputsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

const DateInputField = ({
    id,
    type,
    preText,
    onChange,
    value,
    hasError,
    size,
    min,
    max,
    disabled,
    ...props
}: DateInputFieldProps): ReactElement => {
    return (
        <div>
            <Caption>{preText}</Caption>
            <DateInputWrapper type={type} size={size} {...props}>
                <Input
                    id={id}
                    type="text"
                    value={value}
                    onChange={onChange}
                    min={min}
                    max={max}
                    disabled={disabled}
                    readOnly={!onChange}
                />
            </DateInputWrapper>
        </div>
    );
};

export default DateInputField;
