import { isEmpty } from 'ramda';
import React, { ReactElement, useContext } from 'react';
import styled from 'styled-components';
import { deleteApplicantAddress } from '../../graphql/applicant';
import { shade200 } from '../../theme/colours';
import { sortAddressByDate } from '../../utils/addresses';
import Loading from '../bits/Loading';
import BodyCopy from '../bits/BodyCopy/BodyCopy';
import { Title2, Title3 } from '../bits/Titles/titles';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import DeleteLinkButton from '../bits/Buttons/DeleteLinkButton';
import { format, isValid, parse } from 'date-fns';

const StyledAddressListItem = styled.li`
    margin-top: 1rem;

    + li {
        border-top: 0.01rem solid ${shade200};
        padding-top: 1rem;
    }
`;

const AddressList = (): ReactElement => {
    const { applicant } = useContext(ApplicantContext);
    const [deleteApplicantAddressMutation, { loading, error }] = deleteApplicantAddress.hook();

    const deleteSelectedAddress = async (applicantAddressUuid?: string) => {
        if (!applicantAddressUuid) return;
        const { applicantUuid, orgUuid } = applicant;
        await deleteApplicantAddressMutation({
            variables: {
                applicantUuid,
                orgUuid,
                applicantAddressUuid,
            },
            update(cache) {
                const normalizedId = cache.identify({ applicantAddressUuid, __typename: 'ApplicantAddress' });
                cache.evict({ id: normalizedId });
                cache.gc();
            },
        });
    };

    if (!loading && isEmpty(applicant.addressHistory)) return <></>;

    return (
        <div>
            {!isEmpty(applicant.addressHistory) && <Title2>Address history</Title2>}
            {loading && <Loading />}
            {error && <p>There was an error getting your address history please refresh the page...</p>}
            {!loading && !error && applicant.addressHistory && (
                <ul>
                    {sortAddressByDate(applicant.addressHistory).map((address, i) => {
                        const { applicantAddressUuid, displayAddress, dateMovedIn, dateMovedOut } = address;
                        const movedInDate = parse(dateMovedIn || '', 'yyyy-MM', new Date());
                        const movedOutDate = parse(dateMovedOut || '', 'yyyy-MM', new Date());

                        const movedIn = isValid(movedInDate) ? format(movedInDate, 'MMMM yyy') : 'Inavlid date';
                        const movedOut = isValid(movedOutDate) ? format(movedOutDate, 'MMMM yyy') : 'Present';

                        return (
                            <StyledAddressListItem key={applicantAddressUuid}>
                                <Title3>{`${movedIn} to ${movedOut}:`}</Title3>
                                <BodyCopy>{displayAddress}</BodyCopy>
                                <DeleteLinkButton
                                    onClick={() => deleteSelectedAddress(applicantAddressUuid)}
                                    label="Delete address"
                                />
                            </StyledAddressListItem>
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

export default AddressList;
