import React, { FormEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { label1, label2, title2 } from '../../theme/cssSnippets';
import { alpha, bravo, charlie, golf } from '../../theme/sizing';
import Button from '../bits/Buttons/Button';
import ErrorMessage from '../bits/ErrorMessage/ErrorMessage';
import { IconOptions } from '../bits/Icons';
import NarrowColumn from '../bits/Layout/NarrowColumn';

interface AmplifyBaseProps {
    body: ReactNode;
    heading?: string;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
    onCancel?: () => void;
    cancelLabel?: string;
    navButton?: ReactNode;
    submitLabel?: string;
    submitButtonRightIcon?: IconOptions;
    error?: { code?: string; name?: string; message?: string };
}

const AmplifyBaseWrapper = styled(NarrowColumn)`
    flex-direction: column;
    height: 100%;
`;

const AmplifyBaseForm = styled.form.attrs(({ onSubmit }) => ({
    onSubmit: (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (onSubmit) {
            onSubmit(e);
        }
    },
}))`
    > * + * {
    }

    div {
        padding-left: 0;
    }
    .password-hint {
        font-size: ${label2};
        margin-top: ${alpha};
    }
    h1 {
        ${title2};
        margin-bottom: ${golf};
    }
    p {
        margin: ${golf} auto;
    }
    label {
        ${label1};
        margin-top: ${bravo};
        margin-bottom: ${charlie};
        display: block;
    }
    button {
        display: block;
        margin-top: ${charlie};
    }
    #submit {
        margin-top: ${golf};
    }
`;

function AmplifyBase({
    heading,
    body,
    onSubmit,
    onCancel,
    cancelLabel,
    navButton,
    submitLabel,
    error,
    submitButtonRightIcon,
    ...props
}: AmplifyBaseProps) {
    return (
        <AmplifyBaseWrapper {...props}>
            <AmplifyBaseForm onSubmit={onSubmit}>
                <h1>{heading}</h1>
                {navButton}
                {body}
                {onCancel && <Button buttonStyle="link" text={cancelLabel} type="button" onClick={onCancel} />}
                {onSubmit && (
                    <Button
                        buttonStyle="primary"
                        text={submitLabel}
                        type="submit"
                        id="submit"
                        rightIcon={submitButtonRightIcon}
                    />
                )}
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
            </AmplifyBaseForm>
        </AmplifyBaseWrapper>
    );
}

export default AmplifyBase;
