import { ApolloClient } from '@apollo/client';
import { getSignedDownloadUrlQuery, getSignedUploadUrlQuery } from '../graphql/bucket';
import { pathOr } from 'ramda';
import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import { ERROR, ERROR_DOWNLOAD_FAILED, ERROR_UPLOAD_FAILED, SUCCESS } from './constants';

export const formatScreamingSnakeCase = (str: string, lowercase?: boolean) => {
    return str
        .split('_')
        .map((word) => {
            if (lowercase) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            }
        })
        .join(' ');
};

export const getApplicantUploadPath = (currentApplicant: ApplicantWithStaffDetails) =>
    `${currentApplicant?.orgUuid}/${currentApplicant?.applicantUuid}`;

const putFetchApi = (url: string, file: File): Promise<Response> =>
    fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': file.type },
        body: file,
    });

const getFetchApi = (url: string): Promise<Response> =>
    fetch(url, {
        method: 'GET',
    });

export const doUrlFilePut = (putUrl: string | undefined, file: File): Promise<void> => {
    if (putUrl) {
        return putFetchApi(putUrl, file).then((response) => {
            if (response.ok) return;
            else throw new Error(ERROR_UPLOAD_FAILED);
        });
    } else throw new Error(ERROR_UPLOAD_FAILED);
};

interface UploadImageArgs {
    client: ApolloClient<unknown>;
    key: string;
    file: File;
}

export const uploadFile = ({ client, key, file }: UploadImageArgs): Promise<string> => {
    return client
        .query({
            query: getSignedUploadUrlQuery,
            fetchPolicy: 'network-only',
            variables: {
                key,
                filename: file.name,
            },
        })
        .then(pathOr('', ['data', 'getSignedUploadUrl']))
        .then((url) => doUrlFilePut(url, file))
        .then(() => {
            return SUCCESS;
        })
        .catch((error) => {
            console.error('upload error', error);
            return ERROR;
        });
};

export const doUrlFileGet = (getUrl: string | undefined): Promise<string> => {
    if (getUrl) {
        return getFetchApi(getUrl).then((response) => {
            if (response.ok) return response.url;
            else throw new Error(ERROR_DOWNLOAD_FAILED);
        });
    } else throw new Error(ERROR_DOWNLOAD_FAILED);
};

interface DownloadImageArgs {
    client: ApolloClient<unknown>;
    key: string;
}

export const downloadFile = ({ client, key }: DownloadImageArgs): Promise<string> => {
    return client
        .query({
            query: getSignedDownloadUrlQuery,
            fetchPolicy: 'network-only',
            variables: {
                key,
            },
        })
        .then(pathOr('', ['data', 'getSignedDownloadUrl']))
        .then((url) => doUrlFileGet(url))
        .then((url) => {
            return url;
        })
        .catch((error) => {
            console.error('download error', error);
            return ERROR;
        });
};
