import { isRight } from 'fp-ts/lib/Either';
import { PERSONAL_DETAILS } from 'proxyaddress-common/constants';
import { Applicant, ApplicantTab, ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import { reduce } from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { listApplicantsByOrg } from '../../graphql/applicant';
import { formatOrgApplicant, getApplicantUuid } from '../../utils/staffDashboard';
import { ApplicantsContext } from './ApplicantsContext';
import Loading from '../bits/Loading';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import { AdminStateContext } from '../WithAdminState/adminState';
import AuthContext from '../WithAuth/AuthContext';
import { getRouteApplicantDetail, getRouteApplicationNew, ROUTE_APPLICANTS } from '../../utils/routes';
import ErrorPage from '../bits/ErrorMessage/ErrorPage';

export const WithApplicantsContext: FC<{ orgUuid?: string; children?: React.ReactNode }> = ({
    orgUuid,
    children,
}: {
    orgUuid?: string;
    children?: React.ReactNode;
}) => {
    const { staffUsers: orgStaffUsers } = useContext(StaffUserContext);
    const { allStaffUsers } = useContext(AdminStateContext);
    const { isAdmin } = useContext(AuthContext);
    const staffUsers = isAdmin ? allStaffUsers : orgStaffUsers;
    const { orgUuid: orgUuidParam }: { orgUuid: string; applicantUuid: string } = useParams();
    const history = useHistory();
    const location = useLocation();
    const applicantUuid = getApplicantUuid(location.pathname);
    const [newApplication, setNewApplication] = useState(false);
    const [applicants, setApplicants] = useState<Record<string, ApplicantWithStaffDetails>>();
    const [currentApplicant, setCurrentApplicant] = useState<ApplicantWithStaffDetails | undefined>();
    const [currentApplicantTab, setCurrentApplicantTab] = useState<ApplicantTab>(PERSONAL_DETAILS);
    const {
        data: ListApplicantsByOrgData,
        loading,
        error: listApplicantsError,
    } = listApplicantsByOrg.hook({
        orgUuid: orgUuid ?? '',
    });

    useEffect(() => {
        if (newApplication) {
            history.push(getRouteApplicationNew(orgUuid ?? orgUuidParam));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newApplication]);

    useEffect(() => {
        const applicant = applicants?.[applicantUuid];

        if (applicant && location.pathname.startsWith(ROUTE_APPLICANTS)) {
            setCurrentApplicant(applicant);
        }
    }, [applicantUuid, applicants, location]);

    useEffect(() => {
        if (currentApplicant) {
            history.push(getRouteApplicantDetail(applicantUuid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentApplicant]);

    useEffect(() => {
        if (ListApplicantsByOrgData?.listApplicantsByOrg) {
            const ApplicantsMap = reduce<Applicant, Record<string, ApplicantWithStaffDetails>>(
                (acc, app) => {
                    const maybeApplicant = Applicant.decode(app);
                    if (isRight(maybeApplicant)) {
                        const applicantUuid = maybeApplicant.right.applicantUuid;
                        return {
                            ...acc,
                            [applicantUuid]: formatOrgApplicant({ applicant: maybeApplicant.right, staffUsers }),
                        };
                    }
                    throw new Error('Applicant was not the right shape');
                },
                {},
                ListApplicantsByOrgData?.listApplicantsByOrg,
            );
            if (currentApplicant) {
                setCurrentApplicant(ApplicantsMap[currentApplicant.applicantUuid]);
            }
            setApplicants(ApplicantsMap);
        } else {
            setApplicants(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListApplicantsByOrgData, staffUsers]);

    if (!applicants || loading) {
        return <Loading />;
    }

    if (listApplicantsError) {
        return <ErrorPage message={listApplicantsError.message} />;
    }

    return (
        <ApplicantsContext.Provider
            value={{
                newApplication,
                setNewApplication,
                applicants,
                currentApplicant,
                setCurrentApplicant,
                currentApplicantTab,
                setCurrentApplicantTab,
            }}
        >
            {children}
        </ApplicantsContext.Provider>
    );
};
