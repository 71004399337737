import { useEffect, useState } from 'react';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { isRight } from 'fp-ts/lib/Either';
import { Org, ProxyAddress, StaffUser } from 'proxyaddress-common/types';

interface UseDataFormatterProps {
    data?: any[];
    Type: t.TypeC<any> | t.IntersectionC<any>;
    targetUuid: string;
}

export const useDataFormatter = ({ data, Type, targetUuid }: UseDataFormatterProps) => {
    //State type needs updating when used for different types of data
    const [formattedData, setFormattedData] = useState<Record<string, StaffUser | Org | ProxyAddress>>();

    useEffect(() => {
        if (data) {
            const formattedItems = data.reduce((acc: Record<string, typeof Type>, curr: any) => {
                const maybeInfo = Type.decode(curr);
                if (isRight(maybeInfo)) {
                    const uuid = maybeInfo.right[targetUuid];
                    return { ...acc, [uuid]: maybeInfo.right };
                }
                console.log(PathReporter.report(maybeInfo).join('\n \n'));
                const { __typename } = data[0];
                throw new Error(`${__typename} was not the right shape`);
            }, {});

            setFormattedData(formattedItems);
        } else {
            setFormattedData(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return formattedData;
};
