import React, { ReactNode } from 'react';
import Tabs, { TabLink } from '../bits/Tabs/Tabs';
import Header from './Header';
import PageWrapper from '../bits/Layout/PageWrapper';
import { ROUTE_COUNCIL_AREAS, ROUTE_ORGANISATIONS } from '../../utils/routes';

interface AccountsNavigationProps {
    children: ReactNode;
}

const AdminNavigation = ({ children, ...props }: AccountsNavigationProps) => {
    const adminLinks: TabLink[] = [
        {
            link: ROUTE_ORGANISATIONS,
            label: 'Organisations',
        },
        {
            link: ROUTE_COUNCIL_AREAS,
            label: 'Council Areas',
        },
    ];

    const menu = <Tabs links={adminLinks} />;

    return (
        <div {...props}>
            <Header menu={menu} />
            <PageWrapper>{children}</PageWrapper>
        </div>
    );
};

export default AdminNavigation;
