import React from 'react';
import { YotiDocumentText, YotiIdDocumentsResourceResponse } from 'proxyaddress-common/types';
import Detail from '../../bits/Details/Detail';
import { formatScreamingSnakeCase } from '../../../utils/helpers';
import { DetailSection } from './YotiCheck';
import YotiImages from './YotiImages';

interface YotiDocumentDetailProps {
    sessionId: string;
    isOpen: boolean;
    textExtractions: YotiDocumentText[];
    documents: YotiIdDocumentsResourceResponse[];
}

const displayFields = ['full_name', 'date_of_birth', 'place_of_birth', 'nationality', 'gender'];

const YotiDocumentDetail = ({ sessionId, textExtractions, documents, isOpen }: YotiDocumentDetailProps) => (
    <>
        {documents.map((document) => {
            const documentTextExtractions = textExtractions.filter((te) => te.documentId === document.id);

            return (
                <div key={document.id}>
                    <DetailSection>
                        <Detail
                            caption="Document type:"
                            detail={formatScreamingSnakeCase(document.documentType || '-')}
                            inline
                        />
                        <Detail caption="Issuing country:" detail={document.issuingCountry} inline />
                        {textExtractions && (
                            <>
                                {documentTextExtractions.map((textExtraction) => {
                                    if (!textExtraction.documentText) {
                                        return null;
                                    }

                                    const parsedText = JSON.parse(textExtraction.documentText);

                                    const filteredText = Object.keys(parsedText)
                                        .filter((key) => displayFields.includes(key))
                                        .reduce((obj: { [key: string]: any }, key) => {
                                            obj[key] = parsedText[key];
                                            return obj;
                                        }, {});

                                    return Object.entries(filteredText).map(([key, value]) => (
                                        <Detail
                                            key={key}
                                            caption={`${formatScreamingSnakeCase(key)}:`}
                                            detail={value}
                                            inline
                                        />
                                    ));
                                })}
                            </>
                        )}
                    </DetailSection>
                    {document.id && <YotiImages sessionId={sessionId} documentId={document.id} isOpen={isOpen} />}
                </div>
            );
        })}
    </>
);

export default YotiDocumentDetail;
