import { css } from 'styled-components/macro';
import { shade500 } from './colours';

export const jumbo1 = css`
    font-family: Raleway;
    font-weight: bold;
    font-size: 6rem;
    line-height: 7rem;
    letter-spacing: -0.015rem;
`;

export const jumbo2 = css`
    font-family: Raleway;
    font-weight: 600;
    font-size: 3.75rem;
    line-height: 52px;
    letter-spacing: -0.008rem;
`;

export const jumbo3 = css`
    font-family: Raleway;
    font-size: 3rem;
    font-weight: bold;
    line-height: 3.5rem;
    letter-spacing: -0.015rem;
`;

export const jumbo4 = css`
    font-family: Raleway;
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 2.75rem;
    letter-spacing: 0rem;
`;

// Titles
export const title1 = css`
    font-size: 2.125rem;
    line-height: 2.25rem;
    letter-spacing: 0rem;
`;

export const title2 = css`
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.0075rem;
    font-weight: 600;
`;

export const title3 = css`
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.0075rem;
    font-weight: 600;
`;

export const title4 = css`
    font-size: 0.875rem;
    line-height: 1rem;
    letter-spacing: 0.089rem;
    font-weight: 600;
`;

// Labels
export const label1 = css`
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.009rem;
    font-weight: 600;
`;

export const label2 = css`
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.007rem;
`;

// Body
export const body1 = css`
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.031rem;
`;

export const body2 = css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.018rem;
`;

// Overline
export const caption = css`
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.33rem;
`;

export const overline = css`
    font-size: 0.625rem;
    line-height: 1rem;
    letter-spacing: 0.15rem;
`;

// Non-typography
export const cssVisuallyHidden = css`
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    margin: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

export const inlineTextSvg = css`
    svg {
        top: 0.2rem;
        position: relative;
        height: 1rem;
    }
`;

export const cssResetButton = css`
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    outline: none;
    text-decoration: none;
    color: inherit;
    font: inherit;
    line-height: normal;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
`;

export const cssDisabledInput = css`
    &:disabled {
        border: 0.125rem solid ${shade500};
        color: ${shade500};
    }
`;
