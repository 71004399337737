import { ProxyAddress } from 'proxyaddress-common/types';
import React from 'react';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import ProxyAddressGrid from './ProxyAddressGrid';
import ProxyAddressCard from './ProxyAddressCard';

interface ProxyAddressesProps {
    addresses: ProxyAddress[];
    assignedProxyAddressUuid?: string;
    setAssignedProxyAddressUuid?: (arg: string) => void;
}

const ProxyAddresses = ({ addresses, assignedProxyAddressUuid, setAssignedProxyAddressUuid }: ProxyAddressesProps) => {
    addresses.sort((a, b) => {
        if (a.isActive && !b.isActive) {
            return -1;
        }
        if (!a.isActive && b.isActive) {
            return 1;
        }
        if (a.expiryDate !== b.expiryDate) {
            if (a.expiryDate === null) return 1;
            if (b.expiryDate === null) return -1;
            return b.expiryDate.localeCompare(a.expiryDate);
        }
        if (a.activationDate && b.activationDate) {
            return new Date(b.activationDate).getTime() - new Date(a.activationDate).getTime();
        }
        if (a.displayAddress && b.displayAddress) {
            return a.displayAddress.localeCompare(b.displayAddress);
        }
        return 0;
    });

    if (!addresses || addresses.length === 0) {
        return (
            <BodyCopy>
                <p>No ProxyAddresses found.</p>
            </BodyCopy>
        );
    }

    return (
        <ProxyAddressGrid>
            <>
                {addresses.map((address) => {
                    const isAssigned = address.proxyAddressUuid === assignedProxyAddressUuid;
                    return (
                        <ProxyAddressCard
                            key={address.proxyAddressUuid}
                            address={address}
                            isAssigned={isAssigned}
                            setAssigned={setAssignedProxyAddressUuid}
                        />
                    );
                })}
            </>
        </ProxyAddressGrid>
    );
};

export default ProxyAddresses;
