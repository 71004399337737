import React, { ReactElement } from 'react';
import Select from 'react-select';
import { contentSecondary } from '../../../theme/colours';
import { alpha } from '../../../theme/sizing';
import Caption from '../Caption/Caption';
import Label from '../Form/Label';
import FormField from './FormField';
import { InputWrapper } from './InputWrapper';

export interface InputOption {
    value: string;
    label: string;
}

export interface SearchSelectInputProps {
    id: string;
    helperText?: string;
    label: string;
    placeholder?: string;
    options: InputOption[];
    onChange: (options: readonly InputOption[] | InputOption | null) => void;
    onBlur?: () => void;
    value?: InputOption;
    error?: string;
    defaultValue?: InputOption;
    isMulti?: boolean;
    width?: string;
    isClearable: boolean;
}

const customStyles = {
    container: (provided: any) => ({
        ...provided,
        width: '100%',
        zIndex: 1000,
    }),
    control: () => ({
        display: 'flex',
        minHeight: '2.5rem',
        border: `${alpha} solid ${contentSecondary}`,
        margin: '0.1rem',
    }),
};

const SearchSelectInput = ({
    id,
    helperText,
    label,
    options,
    onChange,
    onBlur,
    value,
    error,
    placeholder,
    defaultValue,
    isMulti,
    width,
    isClearable,
    ...props
}: SearchSelectInputProps): ReactElement => (
    <FormField error={error} {...props}>
        <Label htmlFor={id}>{label}</Label>
        <InputWrapper width={width} {...props}>
            <Select
                options={options}
                isSearchable={true}
                styles={customStyles}
                isClearable={isClearable}
                onBlur={onBlur}
                onChange={(selected) => onChange(selected)}
                isMulti={isMulti}
                value={value}
                defaultValue={defaultValue}
            />
            {helperText && <Caption>{helperText}</Caption>}
        </InputWrapper>
    </FormField>
);

export default SearchSelectInput;
