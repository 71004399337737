import { isEmpty } from 'ramda';
import {
    ERROR_CODE_LENGTH,
    ERROR_INVALID_PASSWORD,
    ERROR_INVALID_EMAIL,
    ERROR_MATCH_PASSWORDS,
    ERROR_NO_CODE,
    ERROR_NO_NEW_PASSWORD,
    ERROR_NO_RETYPE_NEW_PASSWORD,
    ERROR_NO_EMAIL,
    ERROR_PASSWORD_CHARACTERS_REQUIRED,
    usernameRegex,
} from './constants';

interface ResetPasswordParams {
    input: {
        username: string;
        code: string;
        newPassword: string;
        retypeNewPassword: string;
    };
}

export interface ResetPasswordErrors {
    usernameError: string;
    codeError: string;
    newPasswordError: string;
    retypeNewPasswordError: string;
}

export const ResetPasswordInitialErrors = {
    usernameError: '',
    codeError: '',
    newPasswordError: '',
    retypeNewPasswordError: '',
};

export const checkValidEmail = (username: string) => {
    return usernameRegex.test(username);
};

export const checkEmail = (username: string) => {
    if (isEmpty(username)) return ERROR_NO_EMAIL;
    if (!checkValidEmail(username)) return ERROR_INVALID_EMAIL;
    return '';
};

const checkCode = (code: string) => {
    if (isEmpty(code)) return ERROR_NO_CODE;
    if (code.length < 6 || code.length > 6) return ERROR_CODE_LENGTH;
    return '';
};

const checkNewPassword = (newPassword: string) => {
    let count = 0;

    if (isEmpty(newPassword)) return ERROR_NO_NEW_PASSWORD;
    if (newPassword.length < 8) return ERROR_INVALID_PASSWORD;
    if (/\d/.test(newPassword)) count++;
    if (/[A-Z]|[a-z]/.test(newPassword)) count++;
    if (count < 2) return ERROR_PASSWORD_CHARACTERS_REQUIRED;

    return '';
};

const checkRetypeNewPassword = (newPassword: string, retypeNewPassword: string) => {
    if (isEmpty(retypeNewPassword)) return ERROR_NO_RETYPE_NEW_PASSWORD;
    if (newPassword !== retypeNewPassword) return ERROR_MATCH_PASSWORDS;
    return '';
};

export const validateResetPasswordInput = ({ input }: ResetPasswordParams): ResetPasswordErrors => {
    const { username, code, newPassword, retypeNewPassword } = input;
    return {
        usernameError: checkEmail(username),
        codeError: checkCode(code),
        newPasswordError: checkNewPassword(newPassword),
        retypeNewPasswordError: checkRetypeNewPassword(newPassword, retypeNewPassword),
    };
};
