import React, { ReactElement, useContext } from 'react';
import ChecksTab from './ChecksTab';
import PersonalDetailsTab from './PersonalDetailsTab';
import { useHistory } from 'react-router-dom';
import ActivityLogTab from './ActivityLogTab';
import { ACTIVITY, CHECKS, PERSONAL_DETAILS } from 'proxyaddress-common/constants';
import { ROUTE_APPLICANTS } from '../../../utils/routes';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import ContentTabs from '../../bits/ContentTabs/ContentTabs';
import { ApplicantTab } from 'proxyaddress-common/types';

const ApplicantDetailsTabPages = (): ReactElement => {
    const history = useHistory();
    const { currentApplicantTab, setCurrentApplicantTab, currentApplicant } = useContext(ApplicantsContext);

    const applicantTabs: ApplicantTab[] = [PERSONAL_DETAILS, CHECKS, ACTIVITY];

    const getTabPage = () => {
        if (currentApplicant) {
            switch (currentApplicantTab) {
                case PERSONAL_DETAILS:
                    return <PersonalDetailsTab applicant={currentApplicant} />;
                case CHECKS:
                    return <ChecksTab />;
                case ACTIVITY:
                    return <ActivityLogTab />;
                default:
                    return <PersonalDetailsTab applicant={currentApplicant} />;
            }
        } else {
            history.push(ROUTE_APPLICANTS);
            return;
        }
    };

    return (
        <ContentTabs
            activeTab={currentApplicantTab}
            setActiveTab={setCurrentApplicantTab as (arg: string) => void}
            tabs={applicantTabs}
        >
            {getTabPage()}
        </ContentTabs>
    );
};

export default ApplicantDetailsTabPages;
