import styled from 'styled-components';
import { contentTertiary } from '../../../theme/colours';
import { cssVisuallyHidden } from '../../../theme/cssSnippets';
import { echo } from '../../../theme/sizing';

interface LabelProps {
    hidden?: boolean;
}

const Label = styled.label<LabelProps>`
    color: ${contentTertiary};
    font-size: ${echo};
    ${(props) => props.hidden && cssVisuallyHidden};
    margin: 0;
`;

export default Label;
