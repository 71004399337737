import { ACTIVE, CHARITY, COUNCIL, INACTIVE, OTHER } from 'proxyaddress-common/constants';
import { isEmpty } from 'ramda';
import { Org } from 'proxyaddress-common/types/organisation';
import { CreateOrgParams } from '../graphql/organisation';
import { ERROR_NO_NAME, ERROR_COUNCIL_AREA, ERROR_STATUS, ERROR_ORG_TYPE, ERROR_INVALID_EMAIL } from './constants';
import { checkValidEmail } from './forgotPassword';

export const defaultOrganisation: Org = {
    name: '',
    orgUuid: '',
    councilAreaUuids: [''],
    createdBy: '',
    createdAt: '',
    status: ACTIVE,
    orgType: COUNCIL,
    applicationCredits: 0,
    serviceCredits: 0,
    subscriptionEmail: '',
    subscriptionExpiry: '',
    modifiedAt: undefined,
    partners: [],
};
export interface OrganisationErrors {
    nameError: string;
    councilError: string;
    statusError: string;
    orgTypeError: string;
    subscriptionEmailError: string;
}

export const OrganisationInitialErrors = {
    nameError: '',
    councilError: '',
    statusError: '',
    orgTypeError: '',
    subscriptionEmailError: '',
};

export const validateOrganisationInput = ({ organisation }: CreateOrgParams): OrganisationErrors => {
    const { name, councilAreaUuids, status, orgType, subscriptionEmail } = organisation;

    return {
        nameError: isEmpty(name) ? ERROR_NO_NAME : '',
        councilError: isEmpty(councilAreaUuids[0]) ? ERROR_COUNCIL_AREA : '',
        statusError: isEmpty(status) ? ERROR_STATUS : '',
        orgTypeError: isEmpty(orgType) ? ERROR_ORG_TYPE : '',
        subscriptionEmailError:
            !isEmpty(subscriptionEmail) && !checkValidEmail(subscriptionEmail) ? ERROR_INVALID_EMAIL : '',
    };
};

export const statusOptions = [
    {
        label: 'Active',
        value: ACTIVE,
    },
    {
        label: 'Inactive',
        value: INACTIVE,
    },
];

export const orgTypeOptions = [
    {
        label: 'Council',
        value: COUNCIL,
    },
    {
        label: 'Charity',
        value: CHARITY,
    },
    {
        label: 'Other',
        value: OTHER,
    },
];
