import gql from 'graphql-tag';
import { CreateStaffUserArgs, StaffUser } from 'proxyaddress-common/types/staffUser';
import wrapMutation from './wrapMutation';
import wrapQuery from './wrapQuery';

export interface GetStaffUserParams {
    orgUuid: string;
    staffUuid: string;
}

export interface GetStaffUserData {
    getStaffUser: StaffUser;
}

export const StaffUserType = `{
    staffUuid
    orgUuid
    name
    createdAt
    createdBy
    isAdmin
    isActive
    email
}`;

export const getStaffUserQuery = gql`
    query getStaffUser($staffUuid: String!, $orgUuid: String!) {
        getStaffUser(staffUuid: $staffUuid, orgUuid: $orgUuid) ${StaffUserType}
    }
`;

export const getStaffUser = wrapQuery<GetStaffUserParams, GetStaffUserData>(getStaffUserQuery);

export interface CreateStaffUserParams {
    staffUser: CreateStaffUserArgs;
}
interface CreateStaffUserData {
    staffUser: StaffUser;
}

const mutation = gql`
    mutation createStaffUser($staffUser: NewStaffUserInput!) {
        createStaffUser(staffUser: $staffUser) ${StaffUserType}
    }
`;

export const createStaffUser = wrapMutation<CreateStaffUserParams, CreateStaffUserData>(mutation);

export interface ListStaffUsersByOrgParams {
    orgUuid: string;
}

export interface ListStaffUsersByOrgData {
    listStaffUsersByOrg: StaffUser[];
}

export const listStaffUsersByOrgQuery = gql`
    query listStaffUsersByOrg($orgUuid: String!) {
        listStaffUsersByOrg(orgUuid: $orgUuid) ${StaffUserType}
    }
`;

export const listStaffUsersByOrg = wrapQuery<ListStaffUsersByOrgParams, ListStaffUsersByOrgData>(
    listStaffUsersByOrgQuery,
);

export interface ListAllStaffUsersData {
    listAllStaffUsers: StaffUser[];
}

export const listAllStaffUsersQuery = gql`
    query listAllStaffUsers {
        listAllStaffUsers ${StaffUserType}
    }
`;

export const listAllStaffUsers = wrapQuery<void, ListAllStaffUsersData>(listAllStaffUsersQuery);
