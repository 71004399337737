import React from 'react';
import styled from 'styled-components';
import TextInput from './TextInput';
import { Icon } from '../Icons';

interface SearchBarProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled: boolean;
    placeholder?: string;
}

const Wrapper = styled.div`
    margin-left: 0.3rem;
    display: inline-block;
    div {
        padding-left: 0;
        margin-top: 0;
        display: flex;
        width: 13.8rem;
    }
    input {
        padding-inline-start: 1.7rem;
        padding-right: 0;
        width: 100%;
    }
    position: relative;
    svg {
        position: absolute;
        z-index: 1;
        padding-top: 0.7rem;
        padding-left: 0.3rem;
    }
`;

const SearchBar = ({ onChange, disabled, placeholder }: SearchBarProps) => {
    return (
        <Wrapper>
            <Icon icon="search" />
            <TextInput
                id="search"
                placeholder={placeholder}
                label="Search"
                hideLabel={true}
                onChange={onChange}
                disabled={disabled}
            />
        </Wrapper>
    );
};

export default SearchBar;
