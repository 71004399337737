/* eslint-disable react-hooks/rules-of-hooks */
import { Client } from './client';
import { DocumentNode } from 'graphql';
import {
    FetchPolicy,
    useQuery,
    useLazyQuery,
    QueryHookOptions,
    LazyQueryHookOptions,
    QueryTuple,
    QueryResult,
    FetchResult,
    QueryOptions,
} from '@apollo/client';

type WrappedQueryOptions<P> = Omit<QueryOptions<P>, 'query'>;

const globalDefaultOptions = {
    // Fix for dev Apollo client bug causing
    // rerendering only in un-built environment
    fetchPolicy:
        process.env.NODE_ENV === 'development' ? ('cache-first' as FetchPolicy) : ('cache-and-network' as FetchPolicy),
};

export interface WrappedQuery<P, D> {
    promise: (client: Client, params: P, options?: WrappedQueryOptions<P>) => Promise<FetchResult<D>>;
    hook: (params: P, options?: QueryHookOptions<D, P>) => QueryResult<D, P>;
    lazyHook: (params: P, options?: LazyQueryHookOptions<D, P>) => QueryTuple<D, P>;
}

const wrapQuery = <P, D>(query: DocumentNode): WrappedQuery<P, D> => {
    return {
        promise: async (client: Client, params: P, options: WrappedQueryOptions<P> = {}): Promise<FetchResult<D>> => {
            return client.query({
                ...globalDefaultOptions,
                ...options,
                query: query,
                variables: params,
            });
        },
        hook: (params: P, options: QueryHookOptions<D, P> = {}): QueryResult<D, P> => {
            return useQuery(query, {
                ...globalDefaultOptions,
                ...options,
                variables: params,
            });
        },
        lazyHook: (params: P, options: LazyQueryHookOptions<D, P> = {}): QueryTuple<D, P> => {
            return useLazyQuery(query, {
                ...globalDefaultOptions,
                ...options,
                variables: params,
            });
        },
    };
};

export default wrapQuery;
