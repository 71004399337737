import React, { ReactNode } from 'react';
import { brandA } from '../../theme/colours';
import { Icon } from './Icons';
import styled from 'styled-components';
import { title4 } from '../../theme/cssSnippets';

interface ObscureProps {
    button: ReactNode;
}

const Wrapper = styled.div`
    display: flex;
    span {
        ${title4}
        color: ${brandA};
        padding-left: 0.3rem;
        padding-top: 1rem;
        padding-right: 0.4rem;
    }
    padding-bottom: 2rem;
    > svg {
        padding-top: 1.1rem;
    }
    button {
        padding-top: 0.1rem;
    }
`;

const Obscure = ({ button }: ObscureProps) => {
    return (
        <Wrapper>
            <Icon icon="hide" size="0.9rem" fill={brandA} />
            <span>Obscure names</span>
            {button}
        </Wrapper>
    );
};

export default Obscure;
