import { always, cond, equals } from 'ramda';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { backgroundPrimary, brandB, green400, shade500 } from '../../../theme/colours';
import BodyCopy from '../BodyCopy/BodyCopy';
import { Icon } from '../Icons';
import { Title3 } from '../Titles/titles';

export interface StepProps {
    stepStatus: 'notStarted' | 'started' | 'complete';
    stepNumber: number;
    title: React.ReactNode;
    hideLine?: boolean;
}

const StyledStepWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: centre;
`;

const StyledStepIndicator = styled.div`
    display: flex;
    align-items: start;
`;

export interface StyledStepProps {
    stepStatus: 'notStarted' | 'started' | 'complete';
}

//Basic responsiveness for MVP
export const StyledStepTitle = styled.div<StyledStepProps>`
    display: flex;
    flex-direction: column;
    height: 5rem;
    align-items: center;
    width: 6.8rem;
    margin-left: -2.5rem;
    h2 {
        text-align: center;
    }
    @media (max-width: 650px) {
        width: 3.3rem;
        margin-left: -0.6rem;
        h2 {
            visibility: ${({ stepStatus }) => (stepStatus === 'started' ? 'visible' : 'hidden')};
        }
    }
`;

export const StepLine = styled.div<StyledStepProps>`
    background-color: ${({ stepStatus }) => (stepStatus === 'complete' ? brandB : shade500)};
    width: 5rem;
    height: 0.2rem;
    margin-top: 1rem;
    @media (max-width: 650px) {
        width: 1.5rem;
    }
`;

const stepColour = cond<string, string>([
    [equals('complete'), always(brandB)],
    [equals('started'), always(green400)],
    [equals('notStarted'), always(shade500)],
]);

export const StyledStep = styled.div<StyledStepProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    border: 0.2rem solid ${({ stepStatus }) => stepColour(stepStatus)};
    background-color: ${({ stepStatus }) => (stepStatus === 'complete' ? brandB : backgroundPrimary)};
    width: 1.8rem;
    height: 1.8rem;
    font-weight: 600;
    ${({ stepStatus }) => (stepStatus === 'started' ? `color: ${green400}` : '')};
`;

export const StepIndicator = ({ stepStatus, stepNumber, title, hideLine }: StepProps): ReactElement => {
    return (
        <StyledStepWrapper>
            <StyledStepIndicator>
                <StyledStep stepStatus={stepStatus}>
                    {stepStatus === 'complete' ? (
                        <Icon icon="tick" fill={backgroundPrimary} />
                    ) : (
                        <BodyCopy>{stepNumber}</BodyCopy>
                    )}
                </StyledStep>
                {!hideLine && <StepLine stepStatus={stepStatus}></StepLine>}
            </StyledStepIndicator>
            <StyledStepTitle stepStatus={stepStatus}>
                <Title3>{title}</Title3>
            </StyledStepTitle>
        </StyledStepWrapper>
    );
};

export default StepIndicator;
