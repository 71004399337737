import { useApolloClient } from '@apollo/client';
import { ActivityLogEntryType, ActivityLogInput, ApplicantWithStaffDetails, LogType } from 'proxyaddress-common/types';
import { defaultActivityLogDetails } from 'proxyaddress-common/types/utils';
import { isEmpty, values } from 'ramda';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import { createActivityLogEntry } from '../../../graphql/activityLog';
import {
    checkActivityLogNoteToCreate,
    initialCreateActivityLogEntryErrors,
    noteOptions,
} from '../../../utils/activityLog';
import { ADMIN_USER_NAME, SUCCESS } from '../../../utils/constants';
import { getApplicantUploadPath, uploadFile } from '../../../utils/helpers';
import Button from '../../bits/Buttons/Button';
import Form from '../../bits/Form/Form';
import SelectInput from '../../bits/FormFields/SelectInput';
import TextAreaInput from '../../bits/FormFields/TextAreaInput';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import AuthContext from '../../WithAuth/AuthContext';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import FileInput from '../../bits/FormFields/FileInput';

const FormFields = styled.div`
    > * + * {
        margin-top: 1rem;
    }
`;

const CreateActivity = () => {
    const client = useApolloClient();
    const { staffUser } = useContext(StaffUserContext);
    const { isAdmin } = useContext(AuthContext);
    const { currentApplicant, setCurrentApplicant } = useContext(ApplicantsContext);
    const [noteType, setNoteType] = useState<string>();
    const [note, setNote] = useState('');
    const [files, setFiles] = useState<FileList>();
    const [errors, setErrors] = useState(initialCreateActivityLogEntryErrors);
    const [createActivityLogEntryMutation, { loading, error }] = createActivityLogEntry.hook();

    const handleUploadFile = (currentApplicant: ApplicantWithStaffDetails, file: File, fileUuid: string) => {
        const keyPrefix = getApplicantUploadPath(currentApplicant);
        const key = `${keyPrefix}/${fileUuid}`;

        return uploadFile({ client, key, file });
    };

    const updateState = (currentApplicant: ApplicantWithStaffDetails, logEntry: ActivityLogEntryType) => {
        setNote('');
        setFiles(undefined);
        setCurrentApplicant({
            ...currentApplicant,
            activityLog: [logEntry, ...currentApplicant.activityLog],
        });
        setErrors(initialCreateActivityLogEntryErrors);
    };

    const submitNote = async () => {
        const hasFiles = !!files && files?.length > 0;
        const validationResult = checkActivityLogNoteToCreate(note, noteType, hasFiles);

        if (!currentApplicant) {
            return;
        }

        if (!values(validationResult).every(isEmpty)) {
            return setErrors(validationResult);
        }

        const fileUuid = v4();
        let uploadSuccess = false;

        if (files && files?.length > 0) {
            const uploadedStatus = await handleUploadFile(currentApplicant, files[0], fileUuid);

            if (uploadedStatus === SUCCESS) {
                uploadSuccess = true;
            } else {
                return setErrors({ ...errors, fileError: 'Upload failed, please try again' });
            }
        }

        if ((hasFiles && uploadSuccess) || !hasFiles) {
            const activityLogEntry: ActivityLogInput = {
                applicantUuid: currentApplicant?.applicantUuid,
                orgUuid: currentApplicant?.orgUuid,
                createdBy: isAdmin ? ADMIN_USER_NAME : staffUser?.staffUuid,
                logType: noteType as LogType,
                details: {
                    ...defaultActivityLogDetails,
                    note,
                    fileUuid: uploadSuccess ? fileUuid : undefined,
                },
            };

            const logEntryResult = await createActivityLogEntryMutation({
                variables: { activityLogEntry },
                refetchQueries: ['listApplicantsByOrg'],
            });

            if (logEntryResult.data) {
                updateState(currentApplicant, logEntryResult.data.createActivityLogEntry);
            }
        }
    };

    const formBody = (
        <FormFields>
            <SelectInput
                label="Note type"
                id="address"
                options={noteOptions}
                placeholder="Please select"
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setNoteType(e.target.value)}
                error={errors.noteTypeError ? errors.noteTypeError : ''}
            />

            <TextAreaInput
                id="note"
                label="Note"
                width="100%"
                height="4rem"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                error={errors.noteError ? errors.noteError : ''}
            />

            <FileInput
                id="file"
                onChange={(e) => {
                    if (e.target.files) {
                        setFiles(e.target.files);
                    }
                }}
                error={errors.fileError}
            />

            <Button buttonStyle="primary" text="Add" type="submit" />
        </FormFields>
    );

    return <Form body={formBody} onSubmit={submitNote} error={!!error} loading={loading} />;
};

export default CreateActivity;
