import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import ClickOutsideContainer from './ClickOutsideContainer';
import Overlay from '../Overlay';
import { golf, maxWidth } from '../../../theme/sizing';

export interface ModalProps {
    open: boolean;
    setOpen: (arg: boolean) => void;
    children: ReactNode;
    clickOutsideToClose?: boolean;
    fullScreen?: boolean;
}

//  The background
const ModalOverlay = styled(Overlay)`
    align-items: center;
    display: flex;
    justify-content: center;
`;

interface ModalContentWrapperProps {
    fullScreen?: boolean;
}

//  The box with the stuff in it
const ModalContentWrapper = styled.div<ModalContentWrapperProps>`
    align-self: center;
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
    z-index: 16;
    background-color: white;
    max-width: ${(props) => (props.fullScreen ? maxWidth : '25rem')};
    width: 100%;
    padding: ${golf};
`;

const Modal = ({ open, setOpen, children, clickOutsideToClose, fullScreen, ...props }: ModalProps): ReactElement => {
    const modalBody = <>{children}</>;

    const modalContent = clickOutsideToClose ? (
        <>
            <ClickOutsideContainer open={open} setOpen={setOpen}>
                {modalBody}
            </ClickOutsideContainer>
        </>
    ) : (
        modalBody
    );

    return (
        <ModalOverlay>
            <ModalContentWrapper fullScreen={fullScreen} {...props}>
                {modalContent}
            </ModalContentWrapper>
        </ModalOverlay>
    );
};

export default Modal;
