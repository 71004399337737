import styled from 'styled-components';
import { red400 } from '../../../theme/colours';
import Caption from '../Caption/Caption';

const ErrorMessage = styled(Caption)`
    & {
        color: ${red400};
    }
`;

export default ErrorMessage;
