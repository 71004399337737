import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../../bits/Buttons/Button';
import { ApplicantByPin } from 'proxyaddress-common/types';
import { CheckResult, ERROR, SUCCESS } from '../../../utils/constants';
import DetailPageTitle from '../../bits/DetailPage/DetailPageTitle';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import PrivateText from '../../bits/PrivateText/PrivateText';
import { green600, shade100 } from '../../../theme/colours';
import { formatApplicantName } from '../../../utils/applicant';

interface CheckResultsProps {
    setShowResults: (showResults: boolean) => void;
    applicant?: ApplicantByPin;
    setApplicant: (applicant: ApplicantByPin | undefined) => void;
    result?: CheckResult;
    setResult: (result: CheckResult | undefined) => void;
    dateOfBirth?: string;
}

export const Results = styled.dl`
    margin-bottom: 1rem;
    margin-top: 1rem;

    dd + dt {
        margin-top: 2rem;
    }

    dt {
        font-size: 0.75rem;
        font-weight: bold;
        opacity: 0.9;
        text-transform: uppercase;
        &:after {
            content: ':';
        }
    }

    dd {
        background-color: ${shade100};
        border: solid 1px #109648;
        border-radius: 5px;
        padding: 0.5rem;

        button {
            color: ${green600};
            width: 100%;

            svg {
                fill: ${green600};
            }
        }
    }
`;

const CheckResults = ({ setShowResults, applicant, setResult, result, dateOfBirth }: CheckResultsProps) => {
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    useEffect(() => {
        if (applicant) {
            if (applicant.dateOfBirth !== dateOfBirth) {
                setResult(ERROR);
                setErrorMessage('The date of birth entered does not match our records.');
                return;
            } else if (!applicant.proxyAddressUuid) {
                setResult(ERROR);
                setErrorMessage('Applicant does not have an assigned ProxyAddress.');
                return;
            } else {
                setResult(SUCCESS);
            }
        } else {
            setResult(ERROR);
            setErrorMessage('PIN does not match any applicants. Please check you have entered the PIN correctly.');
        }
    }, [applicant, setShowResults, setResult, dateOfBirth]);

    const resetForm = () => {
        setShowResults(false);
        setErrorMessage(undefined);
        setResult(undefined);
    };

    if (!result) return null;

    const applicantName = formatApplicantName(applicant);
    const proxyAddress = applicant?.proxyAddress || '';
    const isError = result === ERROR;

    return (
        <div>
            <DetailPageTitle icon={isError ? 'cross' : 'tick'} title={isError ? 'Error' : 'Success'} />

            {isError ? (
                <BodyCopy>
                    <p>{errorMessage}</p>
                </BodyCopy>
            ) : (
                <BodyCopy>
                    <p>
                        An applicant has been found that matches the details you have entered. Please use the details
                        below to verify their identity (click to reveal).
                    </p>
                    <Results>
                        <dt>Name</dt>
                        <dd>
                            <PrivateText text={applicantName} fullyObscure />
                        </dd>
                        <dt>ProxyAddress</dt>
                        <dd>
                            <PrivateText text={proxyAddress} fullyObscure />
                        </dd>
                    </Results>
                </BodyCopy>
            )}

            <br />
            <Button buttonStyle="secondary" onClick={() => resetForm()} text={isError ? 'Try again' : 'Restart'} />
        </div>
    );
};

export default CheckResults;
