import { isEmpty, values } from 'ramda';
import React, { FormEvent, ReactElement, useContext, useState } from 'react';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import { createApplicant } from '../../graphql/applicant';
import {
    getCurrentUserId,
    ApplicantErrors,
    ApplicantInitialErrors,
    validateApplicantInput,
    staffUserDropdownValues,
} from '../../utils/users';
import Button from '../bits/Buttons/Button';
import Form from '../bits/Form/Form';
import SelectInput from '../bits/FormFields/SelectInput';
import TextInput from '../bits/FormFields/TextInput';
import AuthContext from '../WithAuth/AuthContext';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import Confirmation from './Confirmation';
import Intro from '../bits/Intro/Intro';
import { useHistory, useParams } from 'react-router-dom';
import MidColumn from '../bits/Layout/MidColumn';
import { ApplicantsContext } from '../WithApplicants/ApplicantsContext';
import { AdminStateContext } from '../WithAdminState/adminState';
import { getRouteOrganisationDetail, ROUTE_APPLICANTS } from '../../utils/routes';

const CreateApplicant = (): ReactElement => {
    const history = useHistory();
    const { orgUuid }: { orgUuid: string } = useParams();
    const { currentAuthenticatedUser: user, isAdmin } = useContext(AuthContext);
    const { staffUsers: orgStaffUsers } = useContext(StaffUserContext);
    const { allStaffUsers } = useContext(AdminStateContext);
    const filteredAllStaffUsers = values(allStaffUsers).filter((staffUser: StaffUser) => staffUser.orgUuid === orgUuid);
    const staffUsers = isAdmin ? filteredAllStaffUsers : values(orgStaffUsers);
    const { setNewApplication } = useContext(ApplicantsContext);
    const [errors, setErrors] = useState<ApplicantErrors>(ApplicantInitialErrors);
    const [createApplicantMutation, { loading, error: createApplicantError }] = createApplicant.hook();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [staffMember, setStaffMember] = useState(isAdmin ? '-' : getCurrentUserId(user));
    const [success, setSuccess] = useState(false);
    const [showIntro, setShowIntro] = useState(true);

    const formIntro = (
        <>
            <p>
                Enter some details about the applicant below, they will have the option to change these details on
                application
            </p>
            <p>
                Ensure that the user’s email address is correct as this is the way that they will be asked to complete
                their application
            </p>
        </>
    );

    const formBody = (
        <>
            <TextInput
                label="First name (optional)"
                id="firstName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)}
            />
            <TextInput
                label="Last name (optional)"
                id="lastName"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
            />
            <TextInput
                label="Email address"
                id="staffEmail"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value.trim())}
                error={errors?.emailError}
            />
            <SelectInput
                options={staffUserDropdownValues(staffUsers)}
                label="Staff member"
                id="staffMember"
                placeholder="Select a staff member"
                defaultValue={isAdmin ? '-' : getCurrentUserId(user)}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStaffMember(e.target.value)}
                error={errors?.userError}
            />
            <Button buttonStyle="primary" type="submit" text="Send Application" rightIcon="rightArrow" />
        </>
    );

    const validateAndCreateApplicant = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();

        const variables = {
            applicantUser: {
                staffMember,
                createdBy: getCurrentUserId(user),
                orgUuid,
                email,
                firstName,
                lastName,
            },
        };
        const validationResult = validateApplicantInput(variables);

        if (values(validationResult).every(isEmpty)) {
            const createdApplicant = await createApplicantMutation({
                variables,
                refetchQueries: ['listApplicantsByOrg'],
            });

            if (createdApplicant.data) {
                setSuccess(true);
            }
        } else {
            setErrors(validationResult);
        }
    };

    const confirmationText = [
        'The ProxyAddress application form has been sent to the user, they should follow the link sent to them via email to complete the sign up process',
        'If the user does not receive an email, tell them to check their junk or spam folders',
    ];

    const introText = [
        'This process will start the application for a ProxyAddress for a new user',
        'This service will send them an emailed link to fill in some details and pass an ID check so that they can be verified and added to the system',
        'The user must have an email address to use the service, if they do not, you must do xyz',
        'If a user needs to re-apply for a ProxyAddress, you must do xyz',
    ];

    const title = 'Create a new proxyaddress applicant';

    const backToApplicants = () => {
        setNewApplication(false);
        history.push(isAdmin ? getRouteOrganisationDetail(orgUuid) : ROUTE_APPLICANTS);
    };

    return (
        <MidColumn>
            {showIntro && (
                <Intro
                    text={introText}
                    title={title}
                    onSubmit={() => setShowIntro(false)}
                    onCancel={backToApplicants}
                    submitTitle="Start"
                    cancelTitle="Back"
                />
            )}
            {!showIntro && success && (
                <Confirmation
                    title="ProxyAddress application sent"
                    text={confirmationText}
                    buttonAction={backToApplicants}
                    buttonTitle="Return to applicants"
                />
            )}
            {!showIntro && !success && (
                <Form
                    title={title}
                    description={formIntro}
                    body={formBody}
                    onCancel={backToApplicants}
                    onSubmit={validateAndCreateApplicant}
                    cancelTitle="Cancel and discard changes"
                    loading={loading}
                    error={!!createApplicantError}
                    errorMessage={createApplicantError?.message}
                />
            )}
        </MidColumn>
    );
};

export default CreateApplicant;
