import React, { ReactElement, useContext } from 'react';
import { ApplicantContext } from '../WithApplicant/applicantContext';
import Applicant from './Applicant';
import Application from './Application';

const ApplicantPage = (): ReactElement => {
    const { hasCurrentApplication } = useContext(ApplicantContext);

    return hasCurrentApplication ? <Application /> : <Applicant />;
};

export default ApplicantPage;
