import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import Auth from '@aws-amplify/auth';
import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
// eslint-disable-next-line max-len
import { createSubscriptionHandshakeLink } from 'isotoma-aws-appsync-subscription-link/packages/aws-appsync-subscription-link';

/* eslint-disable max-len */
/*
    Should be ApolloClient<NormalizedCacheObject>. Old apollo react hooks allowed passing of types:
    https://github.com/trojanowski/react-apollo-hooks/blob/0ba17aa8ccb70f858c05600bc023869ec4c332a7/src/ApolloContext.tsx#L22
    This library was absorbed into the main library which does not allow passing of type
    https://github.com/apollographql/apollo-client/blob/7993a778ef5a9e61bfee72b956f4c179fce691f4/src/react/hooks/useApolloClient.ts#L7
    hopefully this will be fixed at some point
*/
/* eslint-enable max-len */
// eslint-disable-next-line @typescript-eslint/ban-types
export type Client = ApolloClient<object>;

const typePolicies = {
    ApplicantUser: {
        keyFields: ['applicantUuid'],
        fields: {
            addressHistory: {
                // Short for always preferring incoming over existing data.
                merge: false,
            },
        },
    },
    StaffUser: {
        keyFields: ['staffUuid'],
    },
    Application: {
        keyFields: ['applicationUuid'],
    },
    Organisation: {
        keyFields: ['orgUuid'],
    },
    ActivityLog: {
        keyFields: ['activityLogUuid'],
    },
    ApplicantAddress: {
        keyFields: ['applicantAddressUuid'],
    },
    CouncilArea: {
        keyFields: ['councilAreaUuid'],
    },
    ProxyAddress: {
        keyFields: ['proxyAddressUuid'],
    },
};

const createClientWithAuthLink = (graphQlEndpoint: string, authOptions: AuthOptions): Client => {
    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            graphQLErrors.map(({ message, locations, path }) =>
                console.error(
                    `[GraphQL error ${operation.operationName}]: Message: ${message}, Location: ${JSON.stringify(
                        locations,
                        null,
                        4,
                    )}, Path: ${path}`,
                ),
            );
        }
        if (networkError) {
            console.error(`[Network error ${operation.operationName}]: ${networkError}`, networkError);
        }
    });
    const httpLink = new HttpLink({
        uri: graphQlEndpoint,
    });

    const authLink = createAuthLink({
        url: graphQlEndpoint,
        region: 'eu-west-2',
        auth: authOptions,
    });
    const subscriptionLink = createSubscriptionHandshakeLink(
        {
            url: graphQlEndpoint,
            region: 'eu-west-2',
            auth: authOptions,
        },
        httpLink,
    );

    const link = ApolloLink.from([errorLink, authLink, subscriptionLink]);

    return new ApolloClient({
        link: link,
        cache: new InMemoryCache({
            typePolicies,
        }),
    });
};

export const createClientFromCredentials = (graphQlEndpoint: string): Client => {
    return createClientWithAuthLink(graphQlEndpoint, {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
    });
};

export const createClientFromCognitoUser = (graphQlEndpoint: string): Client => {
    return createClientWithAuthLink(graphQlEndpoint, {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const session = cognitoUser.getSignInUserSession();
            if (!session) {
                throw new Error('No session found for cognito user');
            }
            return session.getIdToken().getJwtToken();
        },
    });
};
