import React, { ReactElement } from 'react';
import styled from 'styled-components';

const StyledGrid = styled.ul`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    margin-bottom: 2rem;

    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: 1fr;
    }
`;

const ProxyAddressGrid = ({ children }: { children: ReactElement }) => <StyledGrid>{children}</StyledGrid>;

export default ProxyAddressGrid;
