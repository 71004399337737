import { ApplicantWithStaffDetails } from 'proxyaddress-common/types';
import React, { ReactElement, useContext, useState } from 'react';
import { updateApplicant } from '../../../graphql/applicant';
import {
    checkEditApplicantValidationResult,
    EditApplicantDetailsForm,
    EditDetailsErrors,
    editDetailsInitialErrors,
    formatApplicantDetailsToUpdate,
    getApplicantDetailsToUpdateFields,
    getEditApplicantDetails,
    validateEditApplicantDetailsInput,
} from '../../../utils/applicant';
import { formatDetailsDate, formatOrgApplicant } from '../../../utils/staffDashboard';
import Button from '../../bits/Buttons/Button';
import DetailsWrapper from '../../bits/DetailPage/DetailsWrapper';
import AddressDetail from '../../bits/Details/AddressDetail';
import Detail from '../../bits/Details/Detail';
import Loading from '../../bits/Loading';
import TabHeading from '../../bits/TabDetail/TabHeading';
import GetProxyAddressByUuid from '../../hooks/GetProxyAddressByUuid';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import AuthContext from '../../WithAuth/AuthContext';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import ApplicantProxyAddressDetails from './ApplicantProxyAddressDetails';
import EditApplicantPersonalDetails from './EditApplicantPersonalDetails';

interface PersonalDetailsTabProps {
    applicant: ApplicantWithStaffDetails;
}

const PersonalDetailsTab = ({ applicant }: PersonalDetailsTabProps): ReactElement => {
    const { allStaffUsers } = useContext(AdminStateContext);
    const { staffUsers: orgStaffUsers } = useContext(StaffUserContext);
    const { isAdmin } = useContext(AuthContext);
    const staffUsers = isAdmin ? allStaffUsers : orgStaffUsers;
    const { setCurrentApplicant } = useContext(ApplicantsContext);
    const [updateApplicantMutation, { loading, error: updateError }] = updateApplicant.hook();
    const [editDetails, setEditDetails] = useState(false);
    const [details, setDetails] = useState<EditApplicantDetailsForm>(getEditApplicantDetails(applicant));
    const [errors, setErrors] = useState<EditDetailsErrors>(editDetailsInitialErrors);
    const proxyAddress = GetProxyAddressByUuid(applicant?.proxyAddressUuid);

    const submitDetails = async () => {
        const validationResult = validateEditApplicantDetailsInput(details);
        const isEditApplicantInputValid = checkEditApplicantValidationResult(validationResult);

        if (isEditApplicantInputValid) {
            const applicantWithNewInfo = formatApplicantDetailsToUpdate(applicant, details);
            const variables = getApplicantDetailsToUpdateFields(applicantWithNewInfo);
            const updatedApplicant = await updateApplicantMutation({ variables });
            if (updatedApplicant.data) {
                const formattedEditedApplicant = formatOrgApplicant({
                    applicant: updatedApplicant.data.editApplicant,
                    staffUsers,
                });
                setCurrentApplicant(formattedEditedApplicant);
                setEditDetails(false);
            }
        } else {
            setErrors(validationResult);
        }
    };

    const resetApplicatntDetails = () => {
        setEditDetails(false);
        setDetails(getEditApplicantDetails(applicant));
    };

    return (
        <>
            <TabHeading title={editDetails ? 'Edit personal details' : 'Personal Details'}>
                {!editDetails && (
                    <Button
                        buttonStyle="secondary"
                        text="Edit"
                        disabled={!applicant?.isActive || loading}
                        onClick={() => setEditDetails(true)}
                    />
                )}
            </TabHeading>
            {loading && <Loading />}
            {!loading && (
                <>
                    {!editDetails && (
                        <>
                            <DetailsWrapper>
                                <Detail detail={applicant?.title} caption="Title" />
                                <Detail detail={applicant?.firstName} caption="First name" />
                                <Detail detail={applicant?.middleNames} caption="Middle names" />
                                <Detail detail={applicant?.lastName} caption="Last name" />
                                <Detail detail={formatDetailsDate(applicant?.dateOfBirth)} caption="Date of birth" />
                                <Detail detail={applicant?.email} caption="Email" />
                                <Detail detail={applicant?.correspondenceEmail} caption="Correspondence email" />
                                <Detail detail={applicant?.phoneNumber} caption="Phone number" />
                                <AddressDetail address={applicant?.postalAddress} caption="Postal address" />
                                <AddressDetail address={applicant?.currentLocation} caption="Current location" />
                                <AddressDetail address={applicant?.addressHistory} caption="Previous address(es)" />
                            </DetailsWrapper>

                            <ApplicantProxyAddressDetails address={proxyAddress} pin={applicant?.pin} />
                        </>
                    )}
                    {editDetails && (
                        <EditApplicantPersonalDetails
                            details={details}
                            setDetails={setDetails}
                            updateError={!!updateError}
                            errors={errors}
                            onSubmit={submitDetails}
                            onCancel={resetApplicatntDetails}
                            canEditPersonalDetails={applicant?.application?.yotiSessions?.length === 0}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default PersonalDetailsTab;
