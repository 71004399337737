import gql from 'graphql-tag';
import {
    CreateApplicantArgs,
    Applicant,
    UpdateApplicantInput,
    ActivityLogInput,
    ApplicantByPin,
} from 'proxyaddress-common/types/applicant';
import wrapMutation from './wrapMutation';
import wrapQuery from './wrapQuery';
import { Address } from './proxyAddress';
import { YotiSessionType } from './apis';

export interface GetApplicantParams {
    orgUuid: string;
    applicantUuid: string;
}

export interface GetApplicantData {
    getApplicant: Applicant;
}

export interface GetApplicantByPinData {
    getApplicantByPin: ApplicantByPin;
}

const ProgressType = `{
    intro
    details
    idVerification
    survey
    serviceChoice
    complete
}`;

export const Application = `{
    orgUuid
    applicantUuid
    applicationUuid
    applicationStatus
    progress ${ProgressType}
    hasParticipantSurvey
    createdAt
    createdBy
    modifiedAt
    staffClosedByUuid
    applicationClosedDate
    closingDecisionReason
    yotiSessions ${YotiSessionType}
    skippedIdCheck
}`;

const AddressType = `{
    ${Address}
}`;

const ApplicantAddress = `{
    ${Address}
    applicantAddressUuid
    applicantUuid
    orgUuid
    addressType
    dateMovedIn
    dateMovedOut
    modifiedAt
    createdAt
}`;

const ActivityLogEntryDetails = `{
    applicationUuid
    checkId
    checkType
    newStaffAssignedUuid
    note
    fileUuid
}`;

export const ActivityLogEntry = `{
    applicantUuid
    orgUuid
    activityLogUuid
    createdAt
    createdBy
    logType
    details ${ActivityLogEntryDetails}
}`;

export const ApplicantType = `{
    applicantUuid
    orgUuid
    email
    createdAt
    createdBy
    staffMember
    firstName
    lastName
    middleNames
    modifiedAt
    applicationStatus
    application ${Application}
    closedApplications ${Application}
    isActive
    activityLog ${ActivityLogEntry}
    dateOfBirth
    addressHistory ${ApplicantAddress}
    pin
    title
    phoneNumber
    serviceChoices
    correspondenceEmail
    currentLocation ${AddressType}
    postalAddress ${AddressType} 
    proxyAddressUuid
}`;

export const ApplicantTypeByPin = `{
    applicantUuid
    dateOfBirth
    firstName
    middleNames
    lastName
    isActive
    orgUuid
    pin
    proxyAddressUuid
    title
    proxyAddress
}`;

export const getApplicantQuery = gql`
    query getApplicant($applicantUuid: String!, $orgUuid: String!) {
        getApplicant(applicantUuid: $applicantUuid, orgUuid: $orgUuid) ${ApplicantType}
    }
`;

export const getApplicant = wrapQuery<GetApplicantParams, GetApplicantData>(getApplicantQuery);

export const getApplicantByPinQuery = gql`
    query getApplicantByPin($pin: String!) {
        getApplicantByPin(pin: $pin) ${ApplicantTypeByPin}
    }
`;

export const getApplicantByPin = wrapQuery<{ pin?: string }, GetApplicantByPinData>(getApplicantByPinQuery);

export interface ListApplicantsByOrgParams {
    orgUuid: string;
}

export interface ListApplicantsByOrgData {
    listApplicantsByOrg: Applicant[];
}

export const listApplicantsByOrgQuery = gql`
    query listApplicantsByOrg($orgUuid: String!) {
        listApplicantsByOrg(orgUuid: $orgUuid) ${ApplicantType}
    }
`;

export const listApplicantsByOrg = wrapQuery<ListApplicantsByOrgParams, ListApplicantsByOrgData>(
    listApplicantsByOrgQuery,
);

export interface CreateApplicantParams {
    applicantUser: CreateApplicantArgs;
}
interface CreateApplicantData {
    applicantUser: Applicant;
}

const mutation = gql`
    mutation createApplicant($applicantUser: NewApplicantUserInput!) {
        createApplicant(applicantUser: $applicantUser) ${ApplicantType}
    }
`;

export const createApplicant = wrapMutation<CreateApplicantParams, CreateApplicantData>(mutation);

export interface UpdateApplicantParams {
    applicant: UpdateApplicantInput;
    activityLogEntry?: ActivityLogInput;
}
export interface UpdateApplicantData {
    editApplicant: Applicant;
}

const editApplicantMutation = gql`
    mutation editApplicant($applicant: UpdateApplicantInput!, $activityLogEntry: ActivityLogInput) {
        editApplicant(applicant: $applicant, activityLogEntry: $activityLogEntry) ${ApplicantType}
    }
`;

export const updateApplicant = wrapMutation<UpdateApplicantParams, UpdateApplicantData>(editApplicantMutation);

export interface DeleteAddressParams {
    applicantUuid: string;
    orgUuid: string;
    applicantAddressUuid: string;
}

const deleteApplicantAddressMutation = gql`
    mutation deleteApplicantAddress($applicantUuid: String!, $orgUuid: String!, $applicantAddressUuid: String!) {
        deleteApplicantAddress(
            applicantUuid: $applicantUuid
            orgUuid: $orgUuid
            applicantAddressUuid: $applicantAddressUuid
        )
    }
`;

export const deleteApplicantAddress = wrapMutation<DeleteAddressParams, string>(deleteApplicantAddressMutation);

export interface UpdateApplicantProxyAddressParams {
    councilAreaUuid: string;
    applicantUuid: string;
    orgUuid: string;
    proxyAddressUuid?: string;
}

const updateEditApplicantProxyAddressMutation = gql`
    mutation editApplicantProxyAddress($councilAreaUuid: String!, $applicantUuid: String!, $orgUuid: String!, $proxyAddressUuid: String) {
        editApplicantProxyAddress(councilAreaUuid: $councilAreaUuid, applicantUuid: $applicantUuid, orgUuid: $orgUuid, proxyAddressUuid: $proxyAddressUuid) ${ApplicantType}
    }
`;

export const editApplicantProxyAddress = wrapMutation<UpdateApplicantProxyAddressParams, UpdateApplicantData>(
    updateEditApplicantProxyAddressMutation,
);
