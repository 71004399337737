import styled from 'styled-components';
import { charlie } from '../../../theme/sizing';

const ButtonsWrapper = styled.div`
    align-items: center;
    display: flex;

    > * + * {
        margin-left: ${charlie};
    }
`;

export default ButtonsWrapper;
