import React, { useState, ReactElement, useContext } from 'react';
import DashboardButtonSearch from './DashboardButtonSearch';
import { getCouncilAreasCellRenderers, GridWrapper } from '../../utils/grid';
import Grid from '../bits/Grid/Grid';
import { GridApi } from '@ag-grid-community/core';
import { councilAreasColumns } from '../bits/Grid/gridOptions';
import { values } from 'ramda';
import { AdminStateContext } from '../WithAdminState/adminState';
import Button from '../bits/Buttons/Button';
import { useHistory } from 'react-router';
import { ROUTE_COUNCIL_AREAS_UPLOAD } from '../../utils/routes';

const CouncilAreas = (): ReactElement => {
    const history = useHistory();
    const { councilAreas } = useContext(AdminStateContext);
    const [gridApi, setGridApi] = useState<GridApi | undefined>();

    const councilAreaValues = values(councilAreas);

    const body = (
        <Button
            buttonStyle="primary"
            text="Upload Council Areas"
            onClick={() => history.push(ROUTE_COUNCIL_AREAS_UPLOAD)}
        />
    );

    return (
        <GridWrapper>
            <DashboardButtonSearch title="Council Areas" body={councilAreaValues.length === 0 ? body : undefined} />
            {councilAreas && (
                <Grid
                    gridApi={gridApi}
                    rowData={councilAreaValues}
                    setGridApi={setGridApi}
                    columns={councilAreasColumns}
                    frameworkComponent={{ ...getCouncilAreasCellRenderers() }}
                />
            )}
        </GridWrapper>
    );
};

export default CouncilAreas;
