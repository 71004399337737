import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { contentPrimary } from '../../../theme/colours';
import { label1 } from '../../../theme/cssSnippets';
import { delta } from '../../../theme/sizing';

export interface CaptionProps {
    color?: string;
    children: ReactNode;
}

interface StyledCaptionProps {
    color?: string;
}

export const StyledCaption = styled.div<StyledCaptionProps>`
    color: ${(props) => props.color || contentPrimary}};
    ${label1};
    font-size: ${delta};
`;

const Caption = ({ color, children, ...props }: CaptionProps): ReactElement => (
    <StyledCaption color={color} {...props}>
        {children}
    </StyledCaption>
);

export default Caption;
