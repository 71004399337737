import React, { ReactElement, useContext, useState } from 'react';
import BodyCopy from '../../bits/BodyCopy/BodyCopy';
import { Title2 } from '../../bits/Titles/titles';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import YotiSession from '../Yoti/YotiSession';
import { SmallNotification } from '../../bits/Notification/Notification';
import { warning } from '../../../theme/colours';
import Button from '../../bits/Buttons/Button';
import { updateApplication } from '../../../graphql/application';
import { EditApplicationArgs, idStage } from 'proxyaddress-common/types';
import { getApplicationUpdateFields } from '../../../utils/users';

const ChecksTab = (): ReactElement => {
    const { currentApplicant } = useContext(ApplicantsContext);
    const application = currentApplicant?.application || currentApplicant?.closedApplications?.[0];
    const sessions = application?.yotiSessions || [];
    const skippedIdCheck = application?.skippedIdCheck;
    const [updateApplicationMutation, { loading, error: resendError }] = updateApplication.hook();
    const [resendComplete, setResendComplete] = useState(false);

    const resendIdCheck = async () => {
        if (currentApplicant?.application) {
            const variables: EditApplicationArgs = {
                application: {
                    ...getApplicationUpdateFields(currentApplicant.application),
                    progress: idStage,
                },
                activityLogEntry: undefined,
                sendRetryEmail: true,
            };

            const application = await updateApplicationMutation({
                variables,
            });

            if (application) {
                setResendComplete(true);
            }
        }
    };

    if ((!sessions || sessions.length === 0) && !skippedIdCheck) {
        return (
            <BodyCopy>
                <Title2>Checks</Title2>

                <p>The applicant has not yet started any checks.</p>
            </BodyCopy>
        );
    }

    return (
        <>
            {skippedIdCheck && (
                <BodyCopy>
                    <Title2>Checks</Title2>
                    <p>
                        The applicant has skipped the ID check. Please contact them to arrange an alternative method of
                        identification.
                    </p>
                </BodyCopy>
            )}
            {sessions?.map((session) => (
                <YotiSession key={session.sessionId} sessionId={session.sessionId} />
            ))}

            {currentApplicant?.application && (
                <SmallNotification borderColor={warning}>
                    {resendComplete ? (
                        <BodyCopy>
                            <p>Email sent.</p>
                        </BodyCopy>
                    ) : (
                        <BodyCopy>
                            <p>
                                If a check has failed, or you require a different form of ID, you can use the button
                                below to send the applicant a new link to complete their ID check.{' '}
                            </p>
                            <p>
                                You should contact them beforehand to let them know to expect the email and why you need
                                them to resubmit their ID e.g. Photo was unclear etc.
                            </p>
                            <Button
                                type="button"
                                onClick={() => resendIdCheck()}
                                buttonStyle="secondary"
                                text="Resend ID check link"
                                disabled={loading}
                            />
                            {resendError && (
                                <p>There was an error resending the ID check link. Please try again later.</p>
                            )}
                        </BodyCopy>
                    )}
                </SmallNotification>
            )}
        </>
    );
};

export default ChecksTab;
