import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { echo } from '../../../theme/sizing';
import { updateApplicant, UpdateApplicantParams } from '../../../graphql/applicant';
import { ApplicantsContext } from '../../WithApplicants/ApplicantsContext';
import { getApplicantDetailsToUpdateFields } from '../../../utils/applicant';
import { ApolloError } from '@apollo/client';
import Caption from '../../bits/Caption/Caption';
import { shade600 } from '../../../theme/colours';
import Button from '../../bits/Buttons/Button';
import SelectInput from '../../bits/FormFields/SelectInput';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import { values } from 'ramda';
import AuthContext from '../../WithAuth/AuthContext';

const StaffDetailsWrapper = styled.div`
    margin-top: ${echo};
    p {
        display: inline;
    }
    div {
        padding-left: 0;
    }
`;

const AssignStaffButtonsWrapper = styled.div`
    display: inline-block;
    button {
        margin-right: 0.3rem;
    }
`;

const CurrentStaffMemberWrapper = styled.div`
    display: flex;
    button {
        margin-left: ${echo};
        font-size: 0.7rem;
    }
`;

const AssignStaff = () => {
    const { currentApplicant } = useContext(ApplicantsContext);
    const { isAdmin } = useContext(AuthContext);
    const { allStaffUsers } = useContext(AdminStateContext);
    const { staffUsers: orgStaffUsers } = useContext(StaffUserContext);
    const filteredAdminUsers = values(allStaffUsers)?.filter(
        (staffUser) => staffUser.orgUuid === currentApplicant?.orgUuid,
    );
    const staffUsers = isAdmin ? filteredAdminUsers : values(orgStaffUsers);
    const staffOptions = staffUsers.map((staffUser) => ({
        value: staffUser.staffUuid,
        label: staffUser.name,
    }));

    const [updateApplicantMutation, { loading, error }] = updateApplicant.hook();
    const [newStaffAssignedUuid, setNewStaffAssignedUuid] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const changeAssignedStaffUser = async () => {
        if (currentApplicant && newStaffAssignedUuid) {
            const applicantWithStaffUserUpdates = getApplicantDetailsToUpdateFields(
                currentApplicant,
                newStaffAssignedUuid,
            );
            const variables: UpdateApplicantParams = applicantWithStaffUserUpdates;
            try {
                await updateApplicantMutation({ variables });
                setShowDropdown(false);
            } catch (updateError) {
                const err = updateError as ApolloError;
                setErrorMessage(err.message);
            }
        }
    };

    if (!currentApplicant) return null;

    return (
        <StaffDetailsWrapper>
            <Caption color={shade600}>Assigned staff</Caption>

            {!showDropdown && (
                <CurrentStaffMemberWrapper>
                    <p>{currentApplicant.staffMember?.name}</p>
                    <Button buttonStyle="link" text="Assign" onClick={() => setShowDropdown(true)} />
                </CurrentStaffMemberWrapper>
            )}
            {showDropdown && (
                <>
                    <SelectInput
                        id="assignStaff"
                        label="Assign staff"
                        hideLabel
                        width={'80%'}
                        options={staffOptions}
                        defaultValue={currentApplicant.staffMember.staffUuid}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setNewStaffAssignedUuid(e.target.value)}
                        error={error && errorMessage}
                    />
                    <AssignStaffButtonsWrapper>
                        <Button
                            buttonStyle="primary"
                            text="Assign"
                            disabled={loading}
                            onClick={changeAssignedStaffUser}
                        />
                        <Button buttonStyle="secondary" text="Cancel" onClick={() => setShowDropdown(false)} />
                    </AssignStaffButtonsWrapper>
                </>
            )}
        </StaffDetailsWrapper>
    );
};

export default AssignStaff;
