import { GridApi } from '@ag-grid-community/core';
import { values } from 'ramda';
import React, { useContext, useState } from 'react';
import { getOrgDetailsRenderers, GridWrapper } from '../../utils/grid';
import SearchBar from '../bits/FormFields/SearchBar';
import Grid from '../bits/Grid/Grid';
import { staffUsersColumns } from '../bits/Grid/gridOptions';
import { AdminStateContext } from '../WithAdminState/adminState';
import DashboardButtonSearch from './DashboardButtonSearch';
import { StaffUserContext } from '../WithStaffUser/staffContext';
import AuthContext from '../WithAuth/AuthContext';
import { getRouteStaffNew } from '../../utils/routes';
import { useHistory, useParams } from 'react-router-dom';
import Button from '../bits/Buttons/Button';

const StaffAdmin = () => {
    const history = useHistory();
    const { orgUuid }: { orgUuid: string } = useParams();
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const { staffUsers: orgStaffUsers, organisation } = useContext(StaffUserContext);
    const { allStaffUsers: adminStaffUsers } = useContext(AdminStateContext);
    const { isAdmin } = useContext(AuthContext);
    const staffUsersRecord = isAdmin ? adminStaffUsers : orgStaffUsers;
    const staffUsers = values(staffUsersRecord);
    const filteredStaffUsers = orgUuid ? staffUsers.filter((staffUser) => staffUser.orgUuid === orgUuid) : staffUsers;

    const body = (
        <>
            {!isAdmin || !!orgUuid ? (
                <Button
                    buttonStyle="primary"
                    text="+ New Staff"
                    onClick={() => history.push(getRouteStaffNew(isAdmin ? orgUuid : organisation.orgUuid))}
                />
            ) : (
                <SearchBar
                    placeholder="Name or email"
                    onChange={({ target }) => gridApi?.setQuickFilter(target.value)}
                    disabled={!gridApi}
                />
            )}
        </>
    );

    return (
        <>
            <GridWrapper>
                <DashboardButtonSearch title="Staff" body={body} />
                <Grid
                    columns={staffUsersColumns}
                    rowData={filteredStaffUsers}
                    gridApi={gridApi}
                    setGridApi={setGridApi}
                    frameworkComponent={getOrgDetailsRenderers()}
                />
            </GridWrapper>
        </>
    );
};

export default StaffAdmin;
