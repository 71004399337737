import React, { useEffect } from 'react';
import Caption from '../../bits/Caption/Caption';
import styled from 'styled-components';
import { getYotiImages } from '../../../graphql/apis';
import { DetailSection } from './YotiCheck';
import ErrorMessage from '../../bits/ErrorMessage/ErrorMessage';
import Loading from '../../bits/Loading';

interface YotiImagesProps {
    sessionId: string;
    documentId: string;
    isOpen: boolean;
}

const PhotoWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    > img {
        flex-basis: 40%;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 40%;
    }
`;

const YotiImages = ({ sessionId, documentId, isOpen }: YotiImagesProps) => {
    const [getYotiImagesQuery, { data, error, loading }] = getYotiImages.lazyHook({ sessionId, documentId });
    const yotiImages = data?.getYotiImages;

    useEffect(() => {
        if (isOpen && sessionId && documentId) {
            getYotiImagesQuery({
                variables: { sessionId, documentId },
            });
        }
    }, [isOpen, sessionId, documentId, getYotiImagesQuery]);

    if (loading) return <Loading />;
    if (error)
        return <ErrorMessage>Unable to retrieve images. Please contact Yoti support ({error.message})</ErrorMessage>;
    if (!yotiImages) return null;

    return (
        <>
            {yotiImages && yotiImages.length > 0 && (
                <DetailSection>
                    <Caption>Photos:</Caption>
                    <PhotoWrapper>
                        {yotiImages.map((photo, index) => (
                            <img key={`${documentId}_${index}`} src={photo.content} alt={`${documentId}_${index}`} />
                        ))}
                    </PhotoWrapper>
                </DetailSection>
            )}
        </>
    );
};

export default YotiImages;
