import React, { useContext } from 'react';
import { isPast, parseISO } from 'date-fns';
import styled, { css } from 'styled-components';
import { ProxyAddress } from 'proxyaddress-common/types';
import { constructive, destructive, shade100, shade200, shade600, shade700, warning } from '../../../theme/colours';
import { Icon, IconOptions } from '../../bits/Icons';
import StatusBadge from '../../bits/Grid/StatusBadge';
import { Title3 } from '../../bits/Titles/titles';
import { ACTIVE, EXPIRED, INACTIVE } from 'proxyaddress-common/constants';
import { cssResetButton } from '../../../theme/cssSnippets';
import { AdminStateContext } from '../../WithAdminState/adminState';
import { StaffUserContext } from '../../WithStaffUser/staffContext';
import AuthContext from '../../WithAuth/AuthContext';
import { formatDetailsDate } from '../../../utils/staffDashboard';

interface ProxyAddressCardProps {
    address: ProxyAddress;
    isAssigned?: boolean;
    setAssigned?: (arg: string) => void;
}

interface StyledCardProps {
    inactive: boolean;
    expired: boolean;
    assigned: boolean;
}

const cssInactiveCard = css`
    background-color: ${shade100};
    border-color: ${destructive};
`;

const cssExpiredCard = css`
    background-color: ${shade100};
    border-color: ${warning};
`;

const cssAssignedCard = css`
    background-color: ${shade100};
    border-color: ${constructive};
    border-width: 4px;
`;

const StyledCard = styled.li<StyledCardProps>`
    background-color: white;
    border: solid 1px ${shade200};
    border-radius: 5px;
    padding: 0.5rem;

    ${({ expired }) => expired && cssExpiredCard}
    ${({ inactive }) => inactive && cssInactiveCard}
    ${({ assigned }) => assigned && cssAssignedCard}
`;

const Button = styled.button`
    ${cssResetButton}
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    > * {
        width: 100%;
    }
`;

const Header = styled.div`
    display: flex;
    gap: 0.25rem;

    svg {
        flex-shrink: 0;
        flex-grow: 0;
        margin-left: auto;
    }
`;
const Codes = styled.ul`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0.5rem;
`;
const CodeItem = styled.li`
    font-size: 0.8rem;
`;
const CodeLabel = styled.span`
    font-weight: bold;
    margin-right: 1px;
`;
const CodeDetail = styled.span``;
const Status = styled.div`
    border-top: solid 1px ${shade600};
    display: flex;
    margin-top: auto;
    padding-top: 0.5rem;
`;
const ExpiryDate = styled.p`
    color: ${shade600};
    font-size: 0.8rem;
    margin-left: auto;
`;

const ProxyAddressCard = ({ address, isAssigned = false, setAssigned }: ProxyAddressCardProps) => {
    const { isAdmin } = useContext(AuthContext);
    const { redirectToProxyAddress: adminRedirect } = useContext(AdminStateContext);
    const { redirectToProxyAddress: staffRedirect } = useContext(StaffUserContext);
    const redirectToProxyAddress = isAdmin ? adminRedirect : staffRedirect;
    const isExpired = isPast(parseISO(address.expiryDate));
    const inactiveStatus = !address.isActive ? INACTIVE : EXPIRED;

    const handleClicked = () => {
        if (setAssigned) {
            setAssigned(address.proxyAddressUuid);
        } else {
            redirectToProxyAddress(address, address.proxyAddressUuid);
        }
    };

    return (
        <StyledCard inactive={!address.isActive} expired={isExpired} assigned={isAssigned}>
            <Button onClick={() => handleClicked()} type="button">
                <Header>
                    <Title3>{address.displayAddress}</Title3>
                    <Icon icon={address.source.toLowerCase() as IconOptions} fill={shade700} />
                </Header>
                <Codes>
                    <CodeItem>
                        <CodeLabel>Status:</CodeLabel>
                        <CodeDetail>{address.blpuStateCode || '-'}</CodeDetail>
                    </CodeItem>

                    <CodeItem>
                        <CodeLabel>Type:</CodeLabel>
                        <CodeDetail>{address.classificationCode || '-'}</CodeDetail>
                    </CodeItem>

                    <CodeItem>
                        <CodeLabel>Postal Address:</CodeLabel>
                        <CodeDetail>{address.postalAddressCode || '-'}</CodeDetail>
                    </CodeItem>

                    <CodeItem>
                        <CodeLabel>Verified:</CodeLabel>
                        <CodeDetail>{address.logicalStatusCode || '-'}</CodeDetail>
                    </CodeItem>

                    <CodeItem>
                        <CodeLabel>Protected Access:</CodeLabel>
                        <CodeDetail>{address.isMultipleResidence ? 'Yes' : 'No'}</CodeDetail>
                    </CodeItem>

                    <CodeItem>
                        <CodeLabel>Last Updated:</CodeLabel>
                        <CodeDetail>{address.lastUpdateDate || ''}</CodeDetail>
                    </CodeItem>
                </Codes>
                <Status>
                    <StatusBadge text={address.isActive && !isExpired ? ACTIVE : inactiveStatus} />
                    <ExpiryDate>Expiry date: {formatDetailsDate(address.expiryDate)}</ExpiryDate>
                </Status>
            </Button>
        </StyledCard>
    );
};

export default ProxyAddressCard;
