import { isRight } from 'fp-ts/lib/Either';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { STAFF_USER_GROUP } from 'proxyaddress-common/constants/users';
import { ProxyAddress } from 'proxyaddress-common/types';
import { Org } from 'proxyaddress-common/types/organisation';
import { StaffUser } from 'proxyaddress-common/types/staffUser';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getOrganisation } from '../../graphql/organisation';
import { listProxyAddressesByOrg } from '../../graphql/proxyAddress';
import { getStaffUser, listStaffUsersByOrg } from '../../graphql/staffUser';
import { defaultOrganisation } from '../../utils/organisation';
import { getRouteProxyAddressDetail } from '../../utils/routes';
import { getCurrentUserUuids } from '../../utils/users';
import { InputOptions } from '../bits/FormFields/SelectInput';
import { PageLoading } from '../bits/Loading';
import { useDataFormatter } from '../hooks/hooks';
import AuthContext from '../WithAuth/AuthContext';
import { StaffUserContext } from './staffContext';

export const WithStaffContext: FC<{ children?: React.ReactNode }> = ({ children }: { children?: React.ReactNode }) => {
    const { currentAuthenticatedUser: user, userType } = useContext(AuthContext);

    if (!user || userType !== STAFF_USER_GROUP) {
        throw new Error('User not permitted');
    }

    const history = useHistory();
    const [staffUser, setStaffUser] = useState<StaffUser>();
    const [organisation, setOrganisation] = useState<Org>(defaultOrganisation);
    const [staffOptions, setStaffOptions] = useState<InputOptions[]>();
    const [currentProxyAddress, setCurrentProxyAddress] = useState<ProxyAddress | undefined>();

    const { orgUuid, userUuid } = getCurrentUserUuids(user);
    const { data: GetStaffUserData } = getStaffUser.hook({ orgUuid, staffUuid: userUuid });
    const { data: ListStaffUsersByOrgData } = listStaffUsersByOrg.hook({ orgUuid });
    const { data: getOrganisationData } = getOrganisation.hook({ orgUuid });
    const [listProxyAddressesByOrgQuery, { data: listProxyAddressByOrgData }] = listProxyAddressesByOrg.lazyHook({
        councilAreaUuid: organisation.councilAreaUuids[0],
        orgUuid,
    });

    const staffUsers = useDataFormatter({
        data: ListStaffUsersByOrgData?.listStaffUsersByOrg,
        Type: StaffUser,
        targetUuid: 'staffUuid',
    }) as Record<string, StaffUser>;

    useEffect(() => {
        if (ListStaffUsersByOrgData?.listStaffUsersByOrg) {
            setStaffOptions(
                ListStaffUsersByOrgData?.listStaffUsersByOrg.map((staffMember) => {
                    return { value: staffMember.staffUuid, label: staffMember.name };
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ListStaffUsersByOrgData?.listStaffUsersByOrg]);

    useEffect(() => {
        if (getOrganisationData?.getOrganisation) {
            const maybeOrganisation = Org.decode(getOrganisationData?.getOrganisation);
            if (!isRight(maybeOrganisation)) {
                console.log(PathReporter.report(maybeOrganisation).join('\n'));
                throw new Error('Organisation was not the right shape');
            }
            setOrganisation(maybeOrganisation.right);
        } else {
            setOrganisation(defaultOrganisation);
        }
    }, [getOrganisationData]);

    useEffect(() => {
        if (GetStaffUserData?.getStaffUser) {
            const maybeStaffUser = StaffUser.decode(GetStaffUserData?.getStaffUser);
            if (!isRight(maybeStaffUser)) {
                throw new Error('StaffUser was not the right shape');
            }
            setStaffUser(maybeStaffUser.right);
        } else {
            setStaffUser(undefined);
        }
    }, [GetStaffUserData]);

    useEffect(() => {
        if (organisation) {
            listProxyAddressesByOrgQuery();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisation]);

    const proxyAddresses = useDataFormatter({
        data: listProxyAddressByOrgData?.listProxyAddressesByOrg,
        Type: ProxyAddress,
        targetUuid: 'proxyAddressUuid',
    }) as Record<string, ProxyAddress>;

    const redirectToProxyAddress = (proxyAddress: ProxyAddress | undefined, proxyAddressUuid: string) => {
        if (proxyAddress && proxyAddressUuid) {
            setCurrentProxyAddress(proxyAddress);
            history.push(getRouteProxyAddressDetail(proxyAddressUuid), {
                state: { referrer: history.location.pathname },
            });
        }
    };

    if (!staffUser || !organisation || !staffUsers || !staffOptions || !proxyAddresses) {
        return <PageLoading />;
    }

    return (
        <StaffUserContext.Provider
            value={{
                staffUser,
                setStaffUser,
                staffUsers,
                staffOptions,
                organisation,
                proxyAddresses,
                currentProxyAddress,
                setCurrentProxyAddress,
                redirectToProxyAddress,
            }}
        >
            {children}
        </StaffUserContext.Provider>
    );
};
