import React, { FormEvent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { foxtrot } from '../../../theme/sizing';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import DetailPageTitle from '../DetailPage/DetailPageTitle';
import BodyCopy from '../BodyCopy/BodyCopy';
import Loading from '../Loading';

interface FormProps {
    body: ReactNode;
    title?: string;
    cancelTitle?: string;
    description?: string | ReactNode;
    onCancel?: () => void;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
    loading: boolean;
    error: boolean;
    errorMessage?: string;
}

const StyledForm = styled.form.attrs(({ onSubmit }) => ({
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (onSubmit) {
            onSubmit(e);
        }
    },
}))`
    > * + * {
        margin-top: ${foxtrot};
    }
`;

const Form = ({
    body,
    title,
    description,
    onCancel,
    onSubmit,
    cancelTitle = 'Cancel and discard changes',
    loading,
    error,
    errorMessage,
    ...props
}: FormProps): ReactElement => (
    <StyledForm onSubmit={(e) => onSubmit(e)} {...props}>
        {title && <DetailPageTitle title={title} onBackButtonClick={onCancel} backButtonLabel={cancelTitle} />}
        {loading && <Loading />}
        {error && (
            <ErrorMessage>Something went wrong. {errorMessage} Please try again or contact an admin user.</ErrorMessage>
        )}
        {!loading && description && <BodyCopy>{description}</BodyCopy>}
        {!loading && <div>{body}</div>}
    </StyledForm>
);

export default Form;
