import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { shade100, white } from '../../../theme/colours';
import { echo } from '../../../theme/sizing';
import { Icon, IconOptions } from '../../bits/Icons';

interface DropdownOptionButtonProps {
    icon: IconOptions;
    color: string;
    text: string;
    onClick: () => void;
}

interface StyledButtonProps {
    color: string;
}

const StyledButton = styled.button<StyledButtonProps>`
    display: flex;
    padding: ${echo};
    background: ${shade100};
    color: ${(props) => props.color};
    p {
        margin-left: 1rem;
        display: inline;
    }
    :hover {
        background: ${white};
    }
    width: 100%;
`;

const DropdownOptionButton = ({ icon, color, text, onClick }: DropdownOptionButtonProps): ReactElement => {
    return (
        <StyledButton color={color} onClick={onClick}>
            <Icon icon={icon} fill={color} />
            <p>{text}</p>
        </StyledButton>
    );
};

export default DropdownOptionButton;
