import { createContext } from 'react';
import { CouncilAreaOutput, Org, StaffUser, ProxyAddress } from 'proxyaddress-common/types';

export interface AdminState {
    organisations: Record<string, Org>;
    currentOrganisation: Org | undefined;
    setCurrentOrganisation: (organisation: Org | undefined) => void;
    allStaffUsers: Record<string, StaffUser>;
    refetchData: () => void;
    councilAreas: Record<string, CouncilAreaOutput>;
    refetchCouncilAreas: () => void;
    redirectToOrg: (org: Org | undefined, orgUuid: string) => void;
    proxyAddresses: Record<string, ProxyAddress>;
    currentProxyAddress: ProxyAddress | undefined;
    setCurrentProxyAddress: (proxyAddress: ProxyAddress | undefined) => void;
    redirectToProxyAddress: (proxyAddress: ProxyAddress, proxyAddressUuid: string) => void;
}

// No one should ever be accessing AdminState before it's been initialised anyways so we shouldn't
// provide any default values
export const AdminStateContext = createContext<AdminState>({} as AdminState);
