import { gql } from '@apollo/client';
import wrapQuery from './wrapQuery';

interface GetSignedUploadUrlData {
    getSignedUploadUrl: string;
}

export interface GetSignedUploadUrlParams {
    key: string;
    filename: string;
}

export const getSignedUploadUrlQuery = gql`
    query getSignedUploadUrl($key: String!, $filename: String!) {
        getSignedUploadUrl(key: $key, filename: $filename)
    }
`;

export const getSignedUploadUrl = wrapQuery<GetSignedUploadUrlParams, GetSignedUploadUrlData>(getSignedUploadUrlQuery);

interface GetSignedDownloadUrlData {
    getSignedDownloadUrl: string;
}

export interface GetSignedDownloadUrlParams {
    key: string;
}

export const getSignedDownloadUrlQuery = gql`
    query getSignedDownloadUrl($key: String!) {
        getSignedDownloadUrl(key: $key)
    }
`;

export const getSignedDownloadUrl = wrapQuery<GetSignedDownloadUrlParams, GetSignedDownloadUrlData>(
    getSignedDownloadUrlQuery,
);
