import React, { ReactElement } from 'react';
import List from '../../bits/List/List';
import { Title3 } from '../../bits/Titles/titles';

interface PartnersListProps {
    partners?: string[];
    isFutureTense?: boolean;
}

const PartnersList = ({ partners, isFutureTense }: PartnersListProps): ReactElement => (
    <>
        {partners?.length && (
            <>
                <Title3>
                    {isFutureTense ? 'Once approved, you will be able to' : 'You can'} use your ProxyAddress with our
                    partners, including:
                </Title3>
                <List>
                    {partners.map((partner) => (
                        <li key={partner}>{partner}</li>
                    ))}
                </List>
            </>
        )}
    </>
);

export default PartnersList;
