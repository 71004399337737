import { applicationStageDetails } from 'proxyaddress-common/constants';
import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { getStageStatus } from '../../utils/application';
import StepIndicator from '../bits/ProgressIndicator';

interface ApplicationProgressIndicatorProps {
    currentStage: string;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const ApplicationProgressIndicator = ({ currentStage }: ApplicationProgressIndicatorProps): ReactElement => {
    return (
        <Wrapper>
            {applicationStageDetails.map((detail, index) => (
                <StepIndicator
                    key={detail.title}
                    title={detail.title}
                    stepNumber={index + 1}
                    stepStatus={getStageStatus(currentStage, index)}
                    hideLine={applicationStageDetails.length <= index + 1}
                />
            ))}
        </Wrapper>
    );
};

export default ApplicationProgressIndicator;
