import styled from 'styled-components';
import { bravo, hotel } from '../../../theme/sizing';
import { brandB } from '../../../theme/colours';

interface InputWrapperProps {
    type?: string;
    width?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
    align-items: center;
    display: flex;
    transition: border-color 0.1s ease-in-out;
    &:focus-within {
        border-color: ${brandB};
    }
    max-width: 20rem;
    width: ${(props) => props.width || '100%'};
    margin-top: ${bravo};
    input {
        padding-right: ${(props) => (props.type === 'password' ? hotel : bravo)};
        padding-left: ${bravo};
    }
    button {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 0;
    }
`;
