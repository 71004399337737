import { createContext } from 'react';
import { Applicant, ApplicationStatus, Progress } from 'proxyaddress-common/types/applicant';

export interface ApplicantState {
    applicant: Applicant;
    setApplicant: (applicant: Applicant) => void;
    hasCurrentApplication: boolean;
    currentStage: string;
    updateApplicationStage: (stage: Progress, started?: ApplicationStatus) => void;
}

export const ApplicantContext = createContext<ApplicantState>({} as ApplicantState);
